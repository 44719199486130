import { useCallback, useState } from "react";

interface IUseMenuResult {
  isOpen: boolean;
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: VoidFunction;
  menuAnchor: HTMLElement | null;
}

/**
 * Custom Hook qui simplifie l'utilisation d'un menu contextuel qui s'attache à un élément
 * au clic de l'utilisateur sur cet élément.
 * @returns true ou false
 */
export function useMenu(): IUseMenuResult {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      setMenuAnchor(event.currentTarget);
      setIsOpen(true);
    },
    [setMenuAnchor, setIsOpen]
  );

  const handleClose = useCallback((): void => {
    setIsOpen(false);
  }, [setIsOpen]);

  return { isOpen, handleOpen, handleClose, menuAnchor };
}
