import type { User } from "@sdeapps/react-core";
import { apiConfig } from "config/app-config";
import { graphApiClient } from "./apiClients";
import type { AxiosResponse } from "axios";
import type { Technicien } from "models";

type UserWithTechnicienInfo = User & {
  mobilePhone?: string;
  city?: string;
};

/**
 * Récupère la liste des techniciens du groupe `GF-OPALE-TECHNICIENS(-DEV)?` depuis l'API Graph.
 * @returns
 */
async function getAllTechniciens(): Promise<Array<Technicien> | undefined> {
  const url = `/groups/${apiConfig.azureAd.groupeTechniciens}/members?$select=employeeId,displayName,mail,id,jobTitle,mobilePhone,city`;

  const {
    data: { value },
  } = await graphApiClient.get<
    { data: { value: Array<UserWithTechnicienInfo> } },
    { data: { value: Array<UserWithTechnicienInfo> } }
  >(url);

  value.sort((agentA, agentB) => {
    return agentA.displayName.localeCompare(agentB.displayName);
  });

  const techniciens: Array<Technicien> = value.map<Technicien>(
    ({ city: centre, mobilePhone: telephone, ...user }) => ({
      ...user,
      centre,
      telephone,
    })
  );

  return techniciens;
}

/**
 * Récupère l'image de profil d'un utilisateur précis, ou de l'utilisateur identifié
 * @params `string` optionnel - l'id de l'utilisateur
 * @returns `string` contenant l'image ou undefined
 */
async function getUserPhoto(userId?: string): Promise<string> {
  const url = `/users/${userId ?? "me"}/photo/$value`;
  const res = await graphApiClient.get<AxiosResponse<Blob>, AxiosResponse<Blob>>(url, {
    responseType: "blob",
  });

  const base64data = await readFileAsDataURL(res.data);
  if (base64data == null) {
    return "";
  }
  if (typeof base64data === "string") {
    return base64data.toString();
  } else {
    return new TextDecoder().decode(base64data);
  }
}

async function readFileAsDataURL(file: Blob): Promise<string | ArrayBuffer | null> {
  const resultBase64 = await new Promise<string | ArrayBuffer | null>((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  });

  return resultBase64;
}

export const graphService = {
  getAllTechniciens,
  getUserPhoto,
};
