import type { SxProps } from "@mui/material";
import type { ReactNode } from "react";
import { useController } from "react-hook-form";
import type { FieldPath, FieldValues } from "react-hook-form";
import type { ControlledInput } from "utils/rules";
import { NumberField } from ".";

interface ControlledNumberFieldProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  defaultHelperText?: string;
  variant?: "outlined" | "standard" | "filled";
  type: "entier" | "decimal";
  fullWidth?: boolean;
  sx?: SxProps;
  allowNegative?: boolean;
  withEndEuro?: boolean;
  textAlign?: "left" | "center" | "right";
  size?: "small" | "medium";
}

export function ControlledNumberField<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  control,
  label,
  defaultValue,
  placeholder = label,
  readOnly = false,
  rules,
  defaultHelperText,
  variant = "outlined",
  type,
  fullWidth = true,
  sx,
  allowNegative = false,
  withEndEuro = false,
  textAlign = "left",
  size,
}: Readonly<ControlledNumberFieldProps<T, Name>>): ReactNode {
  const {
    field: { value, onChange, ref },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  return (
    <NumberField
      sx={sx}
      value={value ?? ""}
      refCallback={ref}
      onChange={onChange}
      allowNegative={allowNegative}
      label={label}
      placeholder={placeholder}
      error={error}
      defaultHelperText={defaultHelperText}
      withEndEuro={withEndEuro}
      type={type}
      variant={variant}
      fullWidth={fullWidth}
      readOnly={readOnly}
      textAlign={textAlign}
      size={size}
    />
  );
}
