import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import type { FieldValues, FieldPath } from "react-hook-form";
import { useController } from "react-hook-form";
import type { ReactNode } from "react";
import type { SxProps } from "@mui/material";
import type { ControlledInput } from "utils/rules";

interface ControlledDateTimeProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  label: string;
  readOnly?: boolean;
  maxDate?: Date;
  defaultHelperText?: string;
  variant?: "outlined" | "standard" | "filled";
  size?: "small" | "medium";
  fullWidth?: boolean;
  sx?: SxProps;
}

/**
 * Un DatePicker de mui controllé par react-hook-forms. Permet à
 * l'utilisateur de sélectionner une date.
 * @param name obligatoire - Nom unique de l'input
 * @param control obligatoire - objet Control du formulaire react-hook-forms
 * https://react-hook-form.com/api/useform/control
 * @param label obligatoire - label de l'input
 * @param defaultValue valeur par défaut de l'input
 * @param readOnly booléen déterminant si l'input en lecture seule
 * @param rules règles de validation de l'input au même format que
 * les options de la méthode register() de react-hook-forms
 * https://react-hook-form.com/api/useform/register#options
 * @param maxDate - date maximum de l'input
 * @param defaultHelperText - texte d'aide à afficher lorsque le champ n'est
 * pas en erreur
 * @param variant - variante graphique de l'input "outlined" | "standard" |
 * "filled", par défaut "outlined"
 * @param fullWidth - booléen déterminant si l'input prend toute la place
 * horizontale disponible
 * @returns Un DatePicker de mui controllé par react-hook-forms
 */
export function ControlledDateTime<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  control,
  label,
  defaultValue,
  readOnly = false,
  rules,
  maxDate,
  defaultHelperText,
  variant = "outlined",
  size = "medium",
  fullWidth = true,
  sx,
}: Readonly<ControlledDateTimeProps<T, Name>>): ReactNode {
  const {
    field: { value, onChange, ref },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  return (
    <DatePicker
      value={value ?? null}
      onChange={onChange}
      format="dd/MM/yyyy"
      maxDate={maxDate}
      inputRef={ref}
      label={label}
      readOnly={readOnly}
      sx={sx}
      slotProps={{
        textField: {
          variant,
          fullWidth,
          size,
          error: error != null,
          helperText: error?.message ?? defaultHelperText,
        },
      }}
    />
  );
}
