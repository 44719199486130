import { useMemo, type ReactNode } from "react";
import { Typography } from "@mui/material";
import { withPageErrorBoundary } from "utils/errorHandling";
import { LoadingScreen } from "components/Loading";
import { isDossierAss, isDossierCipa, isDossierEau, isDossierPac } from "utils/dossierUtil";
import { useGetDossier, useRequiredParams } from "hooks";
import { DossierProvider } from "providers";
import {
  dossierAssSections,
  dossierCipaSections,
  dossierEauSections,
  dossierPacSections,
} from "./routes/dossiersSectionsDefinitions";
import type { DossierSectionDefinition } from "./routes/dossiersSectionsDefinitions";
import { DossierFormWithErrorBoundary } from "./DossierFormWithErrorBoundary";
import type { Dossier } from "models";

function getDossierSections(dossier?: Dossier): Array<DossierSectionDefinition> {
  if (dossier == null) {
    return [];
  }
  if (isDossierEau(dossier)) {
    return dossierEauSections;
  }
  if (isDossierAss(dossier)) {
    return dossierAssSections;
  }
  if (isDossierCipa(dossier)) {
    return dossierCipaSections;
  }
  if (isDossierPac(dossier)) {
    return dossierPacSections;
  }
  return [
    {
      path: "*",
      getParameterPath: () => "*",
      title: "Erreur",
      component: () => (
        <Typography variant="h2">🚧 Type de dossier non implémenté pour l'instant 🚧</Typography>
      ),
    },
  ];
}

function DossierPage(): ReactNode {
  const { idChantier, idDossier } = useRequiredParams<{ idChantier: string; idDossier: string }>();
  const { dossier, isLoading, updateDossier } = useGetDossier(idChantier, idDossier);

  const dossierSections = useMemo(() => getDossierSections(dossier), [dossier]);

  if (dossier == null) {
    return <LoadingScreen />;
  }

  return (
    <DossierProvider dossier={dossier} isLoading={isLoading} updateDossier={updateDossier}>
      <DossierFormWithErrorBoundary sections={dossierSections} />
    </DossierProvider>
  );
}

export const DossierWithErrorBoundary = withPageErrorBoundary(DossierPage);
