import { apiConfig, config } from "config/app-config";
import { authenticationService } from "@sdeapps/react-core";
import type { AxiosHeaderValue } from "axios";

async function createHeaders(scopes: Array<string>): Promise<{
  [x: string]: AxiosHeaderValue | undefined;
  Accept: AxiosHeaderValue;
  Authorization: AxiosHeaderValue;
  "Content-Type"?: AxiosHeaderValue;
}> {
  const accessToken = await authenticationService.getAccessToken(scopes);

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
    "Ocp-Apim-Subscription-Key": `${apiConfig.apimSubscriptionKey}`,
    "Sdeapps-Version": config.appVersion,
    "Sdeapps-Application": config.appName,
    // "User-Oid": "***",
    // "User-Name": "***",
    // "User-Email": "***",
    // "User-Roles": "***",
  };
}

const networkService = {
  createHeaders,
};

export default networkService;
