import { FormHelperText, Table, TableBody, TableHead, TableRow } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TypeDossier } from "models";
import type { ChantierCreateData, DossiersAvis } from "models";
import { useState } from "react";
import type { ReactNode } from "react";
import { useController } from "react-hook-form";
import type { RegisterOptions } from "react-hook-form";
import AvisTableRow from "./AvisTableRow";
import TypoTableCell from "./TypoTableCell";

/** Vérifie qu'au moins un avis soit renseigné. */
function validateUnAvisMinimumRenseigne(
  values: Array<string | undefined | null>
): boolean | string {
  return (
    // `JS for noobies`:
    values
      // 1. L'utilisation de la méthode `.filter(Boolean)` permet d'éliminer
      //    toutes valeurs _falsy_, et donc les `undefined`, `null` et `""` (chaine vide).
      .filter(Boolean)
      // 2. Suivi par l'appel à `.join("")` permet de concaténer toutes les valeurs du tableaux
      //    (sans séparateur étant donné l'argument `""`).
      .join("")
      // 3. Finalement, l'utilisation de la méthode `.trim()` permet de s'assurer d'éliminer toutes
      //    valeurs résultantes de la concaténation de chaines d'espaces uniquement.
      .trim() !== "" ||
    // 4. Finalement, si la comparaison avec une chaine vide n'est pas vérifiée, retour d'un texte
    //    pouvant être utilisé au niveau de l'input pour indiquer la validation en erreur.
    "Au moins un avis est requis"
  );
}

function generateNormalizedStringForIdentity(str?: string): string {
  return (str ?? "")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toUpperCase();
}

function AvisForm(): ReactNode {
  const [communeError, setCommuneError] = useState(false);
  const [demandeur1Error, setDemandeur1Error] = useState(false);
  const [demandeur2Error, setDemandeur2Error] = useState(false);

  const avisValidationRule: Omit<
    RegisterOptions<ChantierCreateData, "avis">,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  > = {
    validate: {
      unAvisMinimumRenseigne: (_avis: DossiersAvis) =>
        validateUnAvisMinimumRenseigne([
          _avis.avisAss?.idAvis,
          _avis.avisCipa?.idAvis,
          _avis.avisEau?.idAvis,
        ]),
      memeCommune: (_avis: DossiersAvis) => {
        const codesInsee = [_avis.avisAss, _avis.avisCipa, _avis.avisEau]
          .map((avisDossier) => avisDossier?.codeInseeCommune) // les codes INSEE
          .filter(Boolean); // filtre des valeurs _falsy_ comme nulles, undefined ou vides
        // le Set _dédoublonne_ naturellement
        const uniqueCommuneIds = new Set(codesInsee);
        // Erreur si des avis concernent des communes différentes
        const communeError = uniqueCommuneIds.size > 1;
        setCommuneError(communeError);

        return !communeError || "Les avis sélectionnés n'ont pas tous la même commune.";
      },
      memesDemandeurs: (_avis: DossiersAvis) => {
        const demandeur1Ids = new Set<string>();
        const demandeur2Ids = new Set<string>();
        [_avis.avisAss, _avis.avisCipa, _avis.avisEau].forEach((avisDossier) => {
          if (avisDossier?.demandeur1 != null) {
            const { codeSap = "" } = avisDossier.demandeur1;
            const identite = generateNormalizedStringForIdentity(codeSap);
            demandeur1Ids.add(identite);
          }
          if (avisDossier?.demandeur2 != null) {
            const { codeSap = "" } = avisDossier.demandeur2;
            const identite = generateNormalizedStringForIdentity(codeSap);
            demandeur2Ids.add(identite);
          }
        });

        const demandeur1Error = demandeur1Ids.size > 1;
        setDemandeur1Error(demandeur1Error);

        const demandeur2Error = demandeur2Ids.size > 1;
        setDemandeur2Error(demandeur2Error);

        return (
          !(demandeur1Error || demandeur2Error) ||
          "Les avis sélectionnés n'ont pas tous le même demandeur."
        );
      },
    },
  };

  const {
    fieldState: { error },
  } = useController({ name: "avis", rules: avisValidationRule });

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TypoTableCell fontWeight={500}>N° d'avis</TypoTableCell>
              <TypoTableCell fontWeight={500}>Type</TypoTableCell>
              <TypoTableCell fontWeight={500}>Date</TypoTableCell>
              <TypoTableCell fontWeight={500}>Commune</TypoTableCell>
              <TypoTableCell fontWeight={500}>Demandeur 1</TypoTableCell>
              <TypoTableCell fontWeight={500}>Demandeur 2</TypoTableCell>
              <TypoTableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            <AvisTableRow
              name="avis.avisEau"
              label="Avis Eau Potable"
              typeDemande={TypeDossier.EAU}
              communeError={communeError}
              demandeur1Error={demandeur1Error}
              demandeur2Error={demandeur2Error}
            />
            <AvisTableRow
              name="avis.avisAss"
              label="Avis Assainissement"
              typeDemande={TypeDossier.ASS}
              communeError={communeError}
              demandeur1Error={demandeur1Error}
              demandeur2Error={demandeur2Error}
            />
            <AvisTableRow
              name="avis.avisCipa"
              label="Avis CIPA"
              typeDemande={TypeDossier.CIPA}
              communeError={communeError}
              demandeur1Error={demandeur1Error}
              demandeur2Error={demandeur2Error}
            />
          </TableBody>
        </Table>
      </Grid>
      {error != null && (
        <Grid size={12}>
          <FormHelperText error>{error?.message}</FormHelperText>
        </Grid>
      )}
    </Grid>
  );
}

export default AvisForm;
