import { useEffect, useState } from "react";
import type { Key, ReactNode } from "react";
import { CircularProgress, Tab, Tabs, tabClasses, tabsClasses } from "@mui/material";
import type { Dossier } from "models";
import { routesConfig } from "config/app-config";
import { dossierService } from "services";
import { Link, useNavigate } from "react-router-dom";
import { useDossierPerimetre, useRequiredParams } from "hooks";
import { useSnackbarErrorHandler } from "utils/errorHandling";
import { getDossiers } from "utils/dossierUtil";
import EuroIcon from "@mui/icons-material/Euro";
import DossierTabLabel from "./DossierTabLabel";
import { useChantier } from "providers/ChantierProvider";
import { TypeDossierIcon } from "components/TypeDossierIcon";

const tabStyle = {
  border: "2px solid",
  borderColor: "primary.main",
  borderBottomWidth: 0,
  minHeight: "unset",
  opacity: 1,
  backgroundColor: "#FFFFFF",
  transition: "background-color 0.25s, color 0.25s",
  color: "text.secondary",
  textAlign: "left",
  "&:hover": {
    backgroundColor: "primary.light",
    color: "primary.main",
  },
  [`&.${tabClasses.selected}`]: {
    backgroundColor: "primary.main",
    color: "#FFFFFF",
  },
  "&:not(:last-child)": {
    borderRightWidth: 0,
  },
  [`&.${tabClasses.selected} + *`]: {
    borderLeftWidth: 0,
  },
};

export type TabItemDefinition = {
  key: Key;
  icon?: ReactNode;
  libelle: string;
  disabled?: boolean;
  action: VoidFunction;
};

// inspiration: https://mui-treasury.com/?path=/story/tabs-plain--plain
export function ChantierTabsPlain(): ReactNode {
  const { chantier, updateChantier } = useChantier();
  const { idDossier } = useRequiredParams<{ idDossier: string }>();
  const { getPerimetresByCommune } = useDossierPerimetre();
  const [tabIndex, setTabIndex] = useState<number | false>(false);
  const [dossiers, setDossiers] = useState<Array<Dossier>>([]);

  const { catchErrors: catchCreatePacErrors, isLoading: isCreatePacLoading } =
    useSnackbarErrorHandler();
  const navigate = useNavigate();

  // C'est l'url de la page qui détermine l'onglet sélectionné
  useEffect(() => {
    let _tabIndex = dossiers.findIndex((dossier) => dossier.id === idDossier);
    if (_tabIndex < 0) _tabIndex = 0;
    setTabIndex(_tabIndex);
  }, [dossiers, idDossier]);

  useEffect(() => {
    if (chantier != null) {
      setDossiers(getDossiers(chantier));
    }
  }, [chantier]);

  if (chantier == null) {
    return undefined;
  }

  return (
    <Tabs
      textColor="inherit"
      sx={{
        [`& .${tabsClasses.indicator}`]: { display: "none" },
        "&.MuiTabs-root": { minHeight: "unset" },
      }}
      value={tabIndex}>
      {dossiers.map((dossier) => (
        <Tab
          key={dossier.id}
          sx={{
            ...tabStyle,
            borderColor: `${dossier.type.toLowerCase()}.main`,
            color: `${dossier.type.toLowerCase()}.dark`,
            "&:hover": {
              backgroundColor: `${dossier.type.toLowerCase()}.light`,
            },
            [`&.${tabClasses.selected}`]: {
              backgroundColor: `${dossier.type.toLowerCase()}.main`,
              color: "#FFFFFF",
            },
          }}
          component={Link}
          to={routesConfig.chantierDossier.getParameterPath(chantier.id, dossier.id)}
          disableRipple
          label={<DossierTabLabel dossier={dossier} />}
          iconPosition="start"
          icon={<TypeDossierIcon type={dossier.type} />}
        />
      ))}

      {chantier.dossierPac == null && (
        <Tab
          key="Créer PAC"
          sx={{
            ...tabStyle,
            borderColor: "pac.main",
            color: "pac.dark",
            "&:hover": {
              backgroundColor: "pac.light",
            },
          }}
          onClick={() => {
            void catchCreatePacErrors(async () => {
              const { perimetreAss } = await getPerimetresByCommune(chantier.adresse.codeInsee);
              const idNewDossierPac = await dossierService.createDossierPac(
                chantier.id,
                perimetreAss
              );
              navigate(routesConfig.chantierDossier.getParameterPath(chantier.id, idNewDossierPac));
              updateChantier();
            });
          }}
          disableRipple
          label="Créer PAC"
          iconPosition="start"
          icon={
            isCreatePacLoading ? <CircularProgress size="20px" /> : <EuroIcon fontSize="medium" />
          }
        />
      )}
    </Tabs>
  );
}
