import { InputAdornment, TextField } from "@mui/material";
import type { SxProps } from "@mui/material";
import type { ReactNode } from "react";
import type { FieldError, RefCallBack } from "react-hook-form";
import { NumericFormat } from "react-number-format";

interface NumberFieldProps {
  value?: string | number | null;
  onChange: (n: number | undefined) => void;
  refCallback?: RefCallBack;
  error?: FieldError;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  defaultHelperText?: string;
  variant?: "outlined" | "standard" | "filled";
  type: "entier" | "decimal";
  fullWidth?: boolean;
  sx?: SxProps;
  allowNegative?: boolean;
  withEndEuro?: boolean;
  textAlign?: "left" | "right" | "center";
  size?: "small" | "medium";
}

export function NumberField({
  value,
  onChange,
  refCallback,
  error,
  label,
  placeholder = label,
  readOnly = false,
  defaultHelperText,
  variant = "outlined",
  type,
  fullWidth = true,
  sx,
  allowNegative = false,
  withEndEuro = false,
  textAlign = "left",
  size,
}: Readonly<NumberFieldProps>): ReactNode {
  return (
    <NumericFormat
      sx={sx}
      value={value}
      getInputRef={refCallback}
      onValueChange={({ value }) => {
        const parsedValue: number | undefined =
          type === "entier" ? parseInt(value) : parseFloat(value);
        onChange(isNaN(parsedValue) ? undefined : parsedValue);
      }}
      thousandSeparator=" "
      decimalSeparator=","
      fixedDecimalScale
      decimalScale={type === "entier" ? 0 : 2}
      allowNegative={allowNegative}
      inputRef={refCallback}
      label={label}
      placeholder={placeholder}
      size={size}
      customInput={TextField}
      helperText={error?.message ?? defaultHelperText}
      inputProps={{ style: { textAlign } }}
      InputProps={{
        readOnly,
        ...(withEndEuro && { endAdornment: <InputAdornment position="end">€</InputAdornment> }),
      }}
      variant={variant}
      fullWidth={fullWidth}
    />
  );
}
