import type { ReactNode } from "react";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import useChantiersSearch from "../hooks/useChantiersSearch";
import { LoadingScreen } from "components/Loading";
import { routesConfig } from "config/app-config";
import AdressePrincipale from "pages/Chantier/components/AdressePrincipale";
import ComplementsDAdresse from "pages/Chantier/components/ComplementsDAdresse";
import { getDossiers } from "utils/dossierUtil";
import { getStatutDossierColor } from "utils/statutUtil";
import DossierChip from "./DossierChip";
import StatutChip from "./StatutChip";
import TechnicienAvatar from "./TechnicienAvatar";

const TABLECELL_HOVER_CLASS = "tablecellhover";
const ADRESSE_CELL_MAX_WIDTH = "25%";

function ChantiersTable(): ReactNode {
  const navigate = useNavigate();
  const { chantiers, isLoading } = useChantiersSearch();

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ border: `1px solid ${grey[300]}`, position: "relative" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingY: 2, width: ADRESSE_CELL_MAX_WIDTH }}>
                <Typography fontWeight={600}>Demandeur</Typography>
              </TableCell>
              <TableCell sx={{ paddingY: 2, width: ADRESSE_CELL_MAX_WIDTH }}>
                <Typography fontWeight={600}>Adresse</Typography>
              </TableCell>
              <TableCell sx={{ paddingY: 2 }}>
                <Typography fontWeight={600}>Dossiers</Typography>
              </TableCell>
              <TableCell sx={{ paddingY: 2 }}>
                <Typography fontWeight={600}>Statut</Typography>
              </TableCell>
              <TableCell sx={{ paddingY: 2 }}>
                <Typography fontWeight={600}>Technicien</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chantiers?.map((chantier) =>
              getDossiers(chantier).map((dossier, i, dossiers) => {
                function goToDossier(): void {
                  navigate(routesConfig.chantierDossier.getParameterPath(chantier.id, dossier.id));
                }
                const lightGreyOnAllButLastBorder =
                  i < dossiers.length - 1 ? { borderColor: grey[200] } : {};
                return (
                  <TableRow
                    key={dossier.id}
                    sx={{
                      [`.${TABLECELL_HOVER_CLASS}`]: {
                        paddingY: 0.5,
                        transition: "background-color 0.25s ease",
                      },
                      [`&:has(.${TABLECELL_HOVER_CLASS}:hover) > .${TABLECELL_HOVER_CLASS}`]: {
                        backgroundColor: `${getStatutDossierColor(dossier.statutDemande)}.light`,
                      },
                    }}>
                    {i === 0 && (
                      <>
                        <TableCell
                          rowSpan={dossiers.length}
                          sx={{ backgroundColor: "white", width: ADRESSE_CELL_MAX_WIDTH }}>
                          <Typography>
                            {chantier?.demandeur1?.nom?.toLocaleUpperCase()}{" "}
                            {chantier?.demandeur1?.prenom}
                          </Typography>
                          <Typography>
                            {chantier?.demandeur2?.nom?.toLocaleUpperCase()}{" "}
                            {chantier?.demandeur2?.prenom}
                          </Typography>
                        </TableCell>
                        <TableCell
                          rowSpan={dossiers.length}
                          sx={{ backgroundColor: "white", width: ADRESSE_CELL_MAX_WIDTH }}>
                          <AdressePrincipale adresse={chantier.adresse} />
                          <ComplementsDAdresse adresse={chantier.adresse} />
                        </TableCell>
                      </>
                    )}
                    <TableCell
                      className={TABLECELL_HOVER_CLASS}
                      sx={{
                        cursor: "pointer",
                        ...lightGreyOnAllButLastBorder,
                      }}
                      onClick={goToDossier}>
                      <DossierChip type={dossier.type} reference={dossier.reference} />
                    </TableCell>
                    <TableCell
                      className={TABLECELL_HOVER_CLASS}
                      sx={{
                        cursor: "pointer",
                        ...lightGreyOnAllButLastBorder,
                      }}
                      onClick={goToDossier}>
                      <Typography>
                        <StatutChip statut={dossier.statutDemande} sx={{ marginY: 0.25 }} />
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={TABLECELL_HOVER_CLASS}
                      sx={{
                        cursor: "pointer",
                        ...lightGreyOnAllButLastBorder,
                      }}
                      onClick={goToDossier}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <TechnicienAvatar technicien={dossier.technicien} size={30} />
                        <Typography sx={{ marginY: 2 }}>{dossier.technicien.name}</Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
            {chantiers.length === 0 && !isLoading && (
              <TableRow>
                <TableCell colSpan={5} sx={{ borderBottom: "none" }}>
                  <Typography textAlign="center">
                    Aucun Chantier ou Dossier ne correspond à vos critères de recherche
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && (
        <Box sx={{ position: "absolute", left: 0, right: 0, top: 0, pointerEvents: "none" }}>
          <LoadingScreen />
        </Box>
      )}
    </Box>
  );
}

export default ChantiersTable;
