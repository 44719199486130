import { TableCell, Typography } from "@mui/material";
import type { TypographyProps } from "@mui/material";
import type { PropsWithChildren, ReactNode } from "react";

interface TypoTableCellProps extends PropsWithChildren<TypographyProps> {
  width?: string | number;
}

function TypoTableCell({
  children,
  width = "",
  ...typographyProps
}: Readonly<TypoTableCellProps>): ReactNode {
  return (
    <TableCell width={width}>
      <Typography {...typographyProps}>{children}</Typography>
    </TableCell>
  );
}

export default TypoTableCell;
