import { useCallback, useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { ToastMessages } from "enums";
import type { SdeappsErrorNames, SdeappsError } from ".";
import errorsUtil from "./errorsUtil";

type ErrorHandlingFunction = (error: SdeappsError) => void;

export type ErrorHandlingOptions = Partial<
  Record<"override" | "default" | SdeappsErrorNames, ErrorHandlingFunction>
>;

export interface ErrorHandlingConfig extends ErrorHandlingOptions {
  dontThrow?: boolean;
  defaultIsLoading?: boolean;
}

interface ErrorHandlerReturn {
  /** Undefined si il n'y a pas d'erreur, autrement, la SdeappsErrror correspondante. */
  error?: SdeappsError;
  /** Permet de manuellement set l'erreur de useErrorHandler. */
  setOriginalError: (error: unknown) => void;
  /** Permet de catcher les erreurs et de les transformer en SdeappsError.
   * @param f une fonction asynchrone dont on veut catcher les erreurs
   * @param onError fonction optionnelle à exécuter quand une erreur est levée. ATTENTION,
   * elle est indépendante de la fonction `onError` et de l'objet `errorOptions` de `useErrorHandler`.
   */
  catchErrors: (
    f: () => Promise<unknown>,
    onError?: (error: SdeappsError) => void
  ) => Promise<void>;
  /** Permet de manuellement réinitialiser les erreurs. */
  resetError: VoidFunction;
  isLoading: boolean;
  setIsLoading: (b: boolean) => void;
}

/**
 * **Hook de gestion des erreurs**
 *
 * Permet de catcher et d'analyser les erreurs, et éventuellement de les rethrow ou d'éxécuter une
 * fonction de callback.
 *
 * Sert principalement à gérer les **erreurs asynchrones**, en catchant, analysant les erreurs, et en
 * throwant une **SdeappsError** correspondante à l'erreur, pour qu'elle soit à son tour catchée par
 * une **Error Boundary** qui mettra le composant en erreur.
 *
 * Lire la [Documentation et Exemples](https://dev.azure.com/SDEA/SDEApps/_wiki/wikis/Ing%C3%A9nieur-DevOps/1891/Gestion-des-erreurs-en-React#).
 */
export function useErrorHandler(): ErrorHandlerReturn;

/**
 * **Hook de gestion des erreurs**
 *
 * Permet de catcher et d'analyser les erreurs, et éventuellement de les rethrow ou d'éxécuter une
 * fonction de callback.
 *
 * Sert principalement à gérer les **erreurs asynchrones**, en catchant, analysant les erreurs, et en
 * throwant une **SdeappsError** correspondante à l'erreur, pour qu'elle soit à son tour catchée par
 * une **Error Boundary** qui mettra le composant en erreur.
 *
 * Lire la [Documentation et Exemples](https://dev.azure.com/SDEA/SDEApps/_wiki/wikis/Ing%C3%A9nieur-DevOps/1891/Gestion-des-erreurs-en-React#).
 *
 * @param dontThrow permet de préciser qu'il ne faut pas rethrow l'erreur, pratique
 * lorsqu'on ne veut pas utiliser d'ErrorBoundary, par exemple pour uniquement afficher une Snackbar.
 */
export function useErrorHandler(dontThrow?: boolean): ErrorHandlerReturn;

/**
 * **Hook de gestion des erreurs**
 *
 * Permet de catcher et d'analyser les erreurs, et éventuellement de les rethrow ou d'éxécuter une
 * fonction de callback.
 *
 * Sert principalement à gérer les **erreurs asynchrones**, en catchant, analysant les erreurs, et en
 * throwant une **SdeappsError** correspondante à l'erreur, pour qu'elle soit à son tour catchée par
 * une **Error Boundary** qui mettra le composant en erreur.
 *
 * Lire la [Documentation et Exemples](https://dev.azure.com/SDEA/SDEApps/_wiki/wikis/Ing%C3%A9nieur-DevOps/1891/Gestion-des-erreurs-en-React#).
 *
 * @param config permet de spécifier un comportement spécifique pour chaque type de
 * SdeappsError possible, ainsi qu'un comportement qui les override, et un comportement par défaut.
 */
export function useErrorHandler(config?: ErrorHandlingConfig): ErrorHandlerReturn;

export function useErrorHandler(config?: ErrorHandlingConfig | boolean): ErrorHandlerReturn {
  const [error, setError] = useState<SdeappsError>();
  const [originalError, setOriginalError] = useState<unknown>();
  const [isLoading, setIsLoading] = useState(
    (typeof config !== "boolean" && config?.defaultIsLoading) ?? true
  );
  const [errorHandlingConfig, setErrorHandlingConfig] = useState<ErrorHandlingConfig>({});

  useEffect(() => {
    if (config != null) {
      if (typeof config !== "boolean") {
        setErrorHandlingConfig(config);
      } else {
        setErrorHandlingConfig({ dontThrow: config });
      }
    }
    // **NE PAS** ajouter `config` à ce tableau de dépendances !!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function catchErrors(
    f: () => Promise<unknown>,
    onError?: (error: SdeappsError) => void
  ): Promise<void> {
    try {
      setIsLoading(true);
      await f();
    } catch (_error) {
      setOriginalError(_error);
      const sdeappsError_ = errorsUtil.getSdeappsErrorFromUnknown(_error);
      setError(sdeappsError_);
      if (onError != null) {
        onError(sdeappsError_);
      }
    }
    setIsLoading(false);
  }
  const memoizedCatchErrors = useCallback(catchErrors, []);

  function setErrors(e: unknown): void {
    setOriginalError(e);
    setError(errorsUtil.getSdeappsErrorFromUnknown(originalError));
  }
  const memoizedSetErrors = useCallback(setErrors, [originalError]);

  function resetError(): void {
    setOriginalError(undefined);
    setError(undefined);
  }
  const memoizedResetError = useCallback(resetError, []);

  useEffect(() => {
    if (error != null) {
      if (errorHandlingConfig?.override != null) {
        // eslint-disable-next-line no-console
        console.log("ERROR HANDLER HOOK override found", error.originalError ?? error);
        errorHandlingConfig.override?.(error);
      } else if (errorHandlingConfig?.[error.name as keyof ErrorHandlingOptions] != null) {
        // eslint-disable-next-line no-console
        console.log("ERROR HANDLER HOOK corresponding error found", error.originalError ?? error);
        errorHandlingConfig?.[error.name as keyof ErrorHandlingOptions]?.(error);
      } else if (errorHandlingConfig?.default != null) {
        // eslint-disable-next-line no-console
        console.log("ERROR HANDLER HOOK default found", error.originalError ?? error);
        errorHandlingConfig.default?.(error);
      }

      if (errorHandlingConfig.dontThrow == null || !errorHandlingConfig.dontThrow) {
        throw error;
      }
    }
  }, [error, errorHandlingConfig]);

  return {
    error,
    setOriginalError: memoizedSetErrors,
    catchErrors: memoizedCatchErrors,
    resetError: memoizedResetError,
    isLoading,
    setIsLoading,
  };
}

/**
 * **Hook de gestion des erreurs version spécifique Snackbars**
 *
 * Permet de catcher et d'analyser les erreurs, **SANS** les rethrow et en affichant une snackbar d'erreur générique.
 *
 * Lire la [Documentation et Exemples](https://dev.azure.com/SDEA/SDEApps/_wiki/wikis/Ing%C3%A9nieur-DevOps/1891/Gestion-des-erreurs-en-React#).
 *
 * @param config permet de spécifier un comportement spécifique pour chaque type de
 * SdeappsError possible, ainsi qu'un comportement qui les override, et un comportement par défaut.
 */
export function useSnackbarErrorHandler(config?: ErrorHandlingConfig): ErrorHandlerReturn {
  return useErrorHandler({
    defaultIsLoading: false,
    dontThrow: true,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
    ...config,
  });
}
