import type { ReactNode } from "react";
import { ToastMessages } from "enums";
import { ErrorPage } from ".";

interface ServerErrorPageProps {
  message?: string;
  error?: Error;
}

export function ServerErrorPage({ message, error }: Readonly<ServerErrorPageProps>): ReactNode {
  return (
    <ErrorPage
      title="500"
      subtitle={ToastMessages.ERROR_INTERNAL_SERVER_ERROR}
      message={message}
      error={error}
    />
  );
}
