import type { ReactNode } from "react";
import { CircularProgress } from "@mui/material";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import type { LoadingButtonProps } from "@mui/lab";

function determineLoadingPosition(
  endIcon: ReactNode,
  startIcon: ReactNode
): "start" | "end" | undefined {
  if (endIcon != null) return "end";
  if (startIcon != null) return "start";
}

/**
 * Un Bouton avec un cercle de progression permettant de communiquer à l'utilisateur
 * que quelquechose est en train de se passer en réponse à son action sur le bouton,
 * possiblement une requête réseau.
 * @param loading obligatoire - Booléen déterminant si le bouton doit s'afficher en
 * train de charger ou non
 * @param children les enfants sont transmis au bouton (ici probablement le texte du
 * bouton)
 * @param variant la variante graphique du bouton; "outlined" | "contained" | "text",
 * par défaut "contained"
 * @param disabled Booléen
 * @param endIcon Icône à afficher à la fin du bouton
 * @param type type de bouton; "button" | "submit" | "reset", par défaut "submit"
 * @returns Un TextField ou Select de mui controllé par react-hook-forms
 */
function LoadingButton({
  children,
  variant = "contained",
  endIcon,
  startIcon,
  type = "submit",
  ...props
}: Readonly<LoadingButtonProps>): ReactNode {
  return (
    <MuiLoadingButton
      endIcon={endIcon}
      startIcon={startIcon}
      loadingPosition={determineLoadingPosition(endIcon, startIcon)}
      loadingIndicator={<CircularProgress role="progressbar" color="success" size={16} />}
      type={type}
      variant={variant}
      {...props}>
      {children}
    </MuiLoadingButton>
  );
}

export default LoadingButton;
