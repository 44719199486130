import { dateUtil } from "@sdeapps/react-core";
import type { PatchData } from "models";

const dateFormat = "yyyy-MM-dd";

function toPatchDataValueString(data: unknown): string | undefined {
  if (data instanceof Date) {
    return dateUtil.format(data, dateFormat);
  }
  if (typeof data === "boolean") {
    return data.toString();
  }
  if (data === null) {
    return undefined;
  }
  return data as string;
}

function toPatchData<T extends object>(
  dossierData: T,
  excludes: Array<keyof T> = []
): Array<PatchData> {
  const patches: Array<PatchData> = [];

  Object.entries(dossierData).forEach(([key, value]) => {
    if (excludes.length === 0 || !excludes.includes(key as keyof T)) {
      patches.push({
        op: "replace",
        path: `/${key}`,
        value: patchUtils.toPatchDataValueString(value),
      });
    }
  });

  return patches;
}

const patchUtils = {
  toPatchDataValueString,
  toPatchData,
};

export default patchUtils;
