import type { CalculAspectFinancierEau, DossierBase, TypeDossier } from "models";

export enum StatutDossierEau {
  EN_COURS_TRAITEMENT = "En cours de traitement",
  ATTENTE_REALISATION_TRAVAUX = "En attente de réalisation des travaux",
  ATTENTE_DOCUMENTS = "En attente de documents",
  TRAVAUX_REALISES_ATTENTE_FACTURE_FOURNISSEUR = "Travaux réalisés attente facture fournisseur",
  CLOS = "Clos (travaux exécutés)",
  ANNULE = "Annulé",
  ERREUR_DEMANDE = "Erreur demande",
  AUTRE = "Autres (voir observations)",
}

export enum TypeInterventionEau {
  COMPTEUR_EAU_POSE = "Compteur d'eau : pose",
  COMPTEUR_EAU_REMPLACEMENT = "Compteur d'eau : remplacement suite gel",
  PARTICULIER_REALISATION_COMPLETE = "Branchement particulier : réalisation complète",
  PARTICULIER_AMORCE = "Branchement particulier : amorce",
  PARTICULIER_ACHEVEMENT = "Branchement particulier : achèvement",
  PARTICULIER_FERMETURE_TEMPORAIRE = "Branchement particulier : fermeture temporaire",
  PARTICULIER_FERMETURE_RESILIATION = "Branchement particulier : fermeture suite résiliation d'abonnement",
  PARTICULIER_REMPLACEMENT = "Branchement particulier : remplacement",
  PARTICULIER_MODIFICATION = "Branchement particulier : modification",
  PARTICULIER_REOUVERTURE = "Branchement particulier : réouverture suite fermeture temporaire",
  AUTRE = "Autre (voir observations)",
}

export interface BranchementParticulierEau {
  travauxDeTerrassement?: boolean | null;
  percementDuMurCave?: boolean | null;
  natureConduite?: NatureConduiteEau;
  diametre?: DiametreBranchementEau;
  longueur?: number;
  nombreDeRegards?: number;
  remarque?: string;
}

export enum EmplacementCompteur {
  REGARD_LEGER = "UnRegardLeger",
  REGARD_BETON = "UnRegardBeton",
  CAVE = "Cave",
  AUTRE = "Autre",
}

export enum DiametreBranchementEau {
  d0 = "_0",
  d32 = "_32",
  d40 = "_40",
  d50 = "_50",
  d63 = "_63",
  d90 = "_90",
  d100 = "_100",
  d125 = "_125",
  d150 = "_150",
  d160 = "_160",
  d200 = "_200",
  d250 = "_250",
  d300 = "_300",
}

export enum NatureConduiteEau {
  PVC = "PVC",
  GRES = "GRES",
  FONTE = "FONTE",
  BETON = "BETON",
  PVC_PRESSION = "PVC_PRESSION",
  PEHD = "PEHD",
  AUTRE = "AUTRE",
}

export interface Compteur {
  id: string;
  nombre: number;
  calibre: number;
  emplacement: EmplacementCompteur;
}

export interface DossierEau extends DossierBase {
  statutDemande: StatutDossierEau;
  type: TypeDossier.EAU;
  dateDemande: string;

  // #region Données administratives
  dateDossierComplet?: string;
  dateEnvoiDevisClient?: string;
  dateAcceptationTravaux?: string;
  dateDemandePiecesDossier?: string;
  dateReceptionAcompte?: string;
  dateDebutTravaux?: string;
  dateRealisationTravaux?: string;
  dateSouscription?: string;

  typeIntervention?: TypeInterventionEau;
  observations?: string;

  permissionVoirie?: boolean;
  dateDemandeVoirie?: string;
  dateAutorisationVoirie?: string;

  travauxRegie?: boolean;
  idFournisseur?: string;
  libelleFournisseur?: string;
  dateDemandeDevis?: string;
  dateRetourDevis?: string;
  dateBonCommande?: string;
  // #endregion

  // #region Données techniques
  servitudeDePose?: boolean | null;
  descriptionTravaux?: string;

  compteurs: Array<Compteur>;
  emplacementCompteur?: EmplacementCompteur;

  ria?: boolean | null;
  calibreRia?: string;

  branchementParticulier: BranchementParticulierEau;

  aspectFinancier?: CalculAspectFinancierEau;
  // #endregion

  destinataireCopie?: string;
}

export interface DossierEauAdministratifModifyDTO {
  idTechnicien?: string;
  nameTechnicien?: string;
  mailTechnicien?: string;
  centreTechnicien?: string;
  telephoneTechnicien?: string;

  statutDemande: StatutDossierEau;
  dateDemande: Date;

  dateDossierComplet?: Date;
  dateEnvoiDevisClient?: Date;
  dateAcceptationTravaux?: Date;
  dateDemandePiecesDossier?: Date;
  dateReceptionAcompte?: Date;
  dateDebutTravaux?: Date;
  dateRealisationTravaux?: Date;
  dateSouscription?: Date;

  typeIntervention?: TypeInterventionEau;
  observations?: string;

  permissionVoirie?: boolean | null;
  dateDemandeVoirie?: Date;
  dateAutorisationVoirie?: Date;

  travauxRegie?: boolean | null;
  idFournisseur?: string;
  libelleFournisseur?: string;
  dateDemandeDevis?: Date;
  dateRetourDevis?: Date;
  dateBonCommande?: Date;
}

export interface DossierEauTechniqueModifyDTO {
  servitudeDePose?: boolean | null;
  descriptionTravaux?: string;

  compteurs: Array<Compteur>;

  ria?: boolean | null;
  calibreRia?: string;

  branchementParticulier: BranchementParticulierEau;

  aspectFinancier?: CalculAspectFinancierEau;
}
