import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import type { DossierCipa } from "models";
import { LoadingScreen } from "components/Loading";
import { useCurrentPathRoute } from "hooks";
import { useDossier } from "providers";
import { useNavigate } from "react-router-dom";
import { withPageErrorBoundary } from "utils/errorHandling";
import DossierSections from "./DossierSections";
import type { DossierSectionDefinition } from "./routes/dossiersSectionsDefinitions";

interface DossierFomProps {
  sections: Array<DossierSectionDefinition>;
}

export function DossierForm({ sections }: Readonly<DossierFomProps>): ReactNode {
  const currentPath = useCurrentPathRoute();
  const navigate = useNavigate();

  const { dossier, isLoading } = useDossier<DossierCipa>();
  const [currentRouteDefinition, setCurrentRouteDefinition] = useState<DossierSectionDefinition>();

  useEffect(() => {
    if (
      currentPath != null &&
      !sections.map((rd) => rd.path).includes(currentPath) &&
      dossier != null
    ) {
      navigate(sections[0].getParameterPath(dossier.idChantier, dossier.id), {
        replace: true,
      });
    }

    setCurrentRouteDefinition(sections.find((rd) => rd.path === currentPath));
  }, [currentPath, dossier, sections, navigate]);

  if (dossier == null || isLoading) {
    return (
      <>
        <DossierSections isLoading sections={sections} />
        <LoadingScreen />
      </>
    );
  }

  if (currentRouteDefinition != null) {
    return <currentRouteDefinition.component />;
  }

  return null;
}

export const DossierFormWithErrorBoundary = withPageErrorBoundary(DossierForm);
