import type { CalculAspectFinancierAss, DossierBase, TypeDossier } from "models";

export enum StatutDossierAss {
  EN_COURS_TRAITEMENT = "En cours de traitement",
  ATTENTE_REALISATION_TRAVAUX = "En attente de réalisation des travaux",
  ATTENTE_DOCUMENTS = "En attente de documents",
  TRAVAUX_REALISES_ATTENTE_FACTURE_FOURNISSEUR = "Travaux réalisés attente facture fournisseur",
  CLOS = "Clos (travaux exécutés)",
  ANNULE = "Annulé",
  ERREUR_DEMANDE = "Erreur demande",
  AUTRE = "Autres (voir observations)",
}

export enum NatureConduiteAss {
  PVC = "PVC",
  GRES = "GRES",
  FONTE = "FONTE",
  BETON = "BETON",
  PVC_PRESSION = "PVC_PRESSION",
  PEHD = "PEHD",
  AUTRE = "AUTRE",
}

export enum DiametreBranchementAss {
  d0 = "_0",
  d32 = "_32",
  d40 = "_40",
  d50 = "_50",
  d63 = "_63",
  d90 = "_90",
  d100 = "_100",
  d125 = "_125",
  d150 = "_150",
  d160 = "_160",
  d200 = "_200",
  d250 = "_250",
  d300 = "_300",
}

interface BranchementParticulierAss {
  natureConduite?: NatureConduiteAss;
  longueur?: number;
  diametre?: DiametreBranchementAss;
  nombreDeRegards?: number;
}

export interface BranchementParticulierDossierAss extends BranchementParticulierAss {}

export interface EauxPluvialesAss extends BranchementParticulierAss {}

export enum TypeInterventionAss {
  RESEAU_UNITAIRE = "Branchement sur réseau unitaire",
  RESEAU_SEPARATIF = "Branchement sur réseau séparatif",
  AUTRE = "Autre (voir observations)",
}

export interface DossierAss extends DossierBase {
  statutDemande: StatutDossierAss;
  type: TypeDossier.ASS;
  dateDemande: string;

  // #region Données administratives
  dateDossierComplet?: string;
  dateEnvoiDevisClient?: string;
  dateAcceptationTravaux?: string;
  dateDemandePiecesDossier?: string;
  dateReceptionAcompte?: string;
  dateDebutTravaux?: string;
  dateRealisationTravaux?: string;

  typeIntervention?: TypeInterventionAss;
  observations?: string;

  permissionVoirie?: boolean | null;
  dateDemandeVoirie?: string;
  dateAutorisationVoirie?: string;

  travauxRegie?: boolean | null;
  idFournisseur?: string;
  libelleFournisseur?: string;
  dateDemandeDevis?: string;
  dateRetourDevis?: string;
  dateBonCommande?: string;
  // #endregion

  // #region Données techniques
  servitudeDePose?: boolean | null;
  descriptionTravaux?: string;

  branchementParticulier: BranchementParticulierDossierAss;
  eauxPluviales: EauxPluvialesAss;

  aspectFinancier?: CalculAspectFinancierAss;
  // #endregion

  destinataireCopie?: string;
}

export interface DossierAssAdministratifModifyDTO {
  idTechnicien?: string;
  nameTechnicien?: string;
  mailTechnicien?: string;
  centreTechnicien?: string;
  telephoneTechnicien?: string;

  statutDemande: StatutDossierAss;
  dateDemande: Date;

  dateDossierComplet?: Date;
  dateEnvoiDevisClient?: Date;
  dateAcceptationTravaux?: Date;
  dateDemandePiecesDossier?: Date;
  dateReceptionAcompte?: Date;
  dateDebutTravaux?: Date;
  dateRealisationTravaux?: Date;

  typeIntervention?: TypeInterventionAss;
  observations?: string;

  permissionVoirie?: boolean | null;
  dateDemandeVoirie?: Date;
  dateAutorisationVoirie?: Date;

  travauxRegie?: boolean | null;
  idFournisseur?: string;
  libelleFournisseur?: string;
  dateDemandeDevis?: Date;
  dateRetourDevis?: Date;
  dateBonCommande?: Date;
}

export interface DossierAssTechniqueModifyDTO {
  servitudeDePose?: boolean | null;
  descriptionTravaux?: string;

  branchementParticulier: BranchementParticulierDossierAss;
  eauxPluviales: EauxPluvialesAss;

  aspectFinancier?: CalculAspectFinancierAss;
}
