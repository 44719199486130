import type { Perimetre, PerimetreCompetence, Transfert } from "models";
import { useCallback } from "react";
import { communesService, perimetreService } from "services";

/**
 * Retourne l'identifiant de périmètre du premier transfert correspondant au code.
 * @param transferts List des transferts utilisés pour retrouver l'identifiant.
 * @param code  Code du transfert identifiant la compétence/sous-compétence/portée.
 * @returns Le premier identifiant du périmètre si trouvé dans la liste des transferts.
 */
function getBestPerimetreIdFromTransferts(
  transferts: Array<Transfert>,
  code: "EAU-EX-3" | "ASS-EX-2"
): string | undefined {
  const filtered = transferts.filter((t) => t.code === code);
  if (filtered.length > 1) {
    filtered.sort((t1, t2) => t1.idPerimetre.localeCompare(t2.idPerimetre));
  }
  return filtered.length > 0 ? filtered[0].idPerimetre : undefined;
}

/**
 * Identifie dans une liste de périmètres ceux qui correspondent à la compétence passée en argument,
 * et retourne le périmètre si c'est le seul et unique de ce type dans la liste.
 * @param perimetres La liste des périmètres où chercher.
 * @param type Type de la compétence du périmètre à retourner lorsque c'est le seul résultat.
 * @returns Le seul périmètre ayant cette compétence ou `undefined` lorsque non trouvé, ou qu'il y a plusieurs résultats possibles.
 */
function getPerimetreByType(
  perimetres: Array<Perimetre>,
  type: PerimetreCompetence
): Perimetre | undefined {
  const perimetresByType = perimetres.filter((p) => p.competence === type);
  return perimetresByType.length === 1 ? perimetresByType[0] : undefined;
}

/**
 * Retourne un objet Perimetre par son identifiant dans une liste pré-chargée, et en cas d'absence tente de le charger
 * depuis l'API Collectivité en utilisant son identifiant.
 * @param idPerimetre Identifiant du périmère à retourner.
 * @param perimetres La liste des périmètres où chercher.
 * @returns Un Perimètre lorsque trouvé dans la liste ou depuis l'API, le cas échéant une valeur `undefined`.
 */
async function findOrFetchPerimetre(
  idPerimetre: string | undefined,
  perimetres: Array<Perimetre>
): Promise<Perimetre | undefined> {
  let perimetre = perimetres.find((p) => p.id === idPerimetre);
  if (idPerimetre != null && perimetre == null) {
    perimetre = await perimetreService.getById(idPerimetre);
  }
  return perimetre;
}

interface PerimetresEauAss {
  perimetreEau?: Perimetre;
  perimetreAss?: Perimetre;
}

interface UseDossierPerimetreResult {
  getPerimetresByCommune: (communeCodeInsee?: string) => Promise<PerimetresEauAss>;
}
export function useDossierPerimetre(): UseDossierPerimetreResult {
  const getPerimetresByCommune = useCallback(
    async (communeCodeInsee?: string): Promise<PerimetresEauAss> => {
      if (communeCodeInsee == null) {
        return {};
      }
      const [transferts, perimetres] = await Promise.all([
        communesService.getAllTransfertsByCommune(communeCodeInsee),
        communesService.getAllPerimetresByCommune(communeCodeInsee),
      ]);

      let perimetreEau = getPerimetreByType(perimetres, "EAU");
      let perimetreAss = getPerimetreByType(perimetres, "ASS");

      if (perimetreEau == null) {
        const idPerimetreEau = getBestPerimetreIdFromTransferts(transferts, "EAU-EX-3");
        perimetreEau = await findOrFetchPerimetre(idPerimetreEau, perimetres);
      }

      if (perimetreAss == null) {
        const idPerimetreAss = getBestPerimetreIdFromTransferts(transferts, "ASS-EX-2");
        perimetreAss = await findOrFetchPerimetre(idPerimetreAss, perimetres);
      }

      return { perimetreEau, perimetreAss };
    },
    []
  );

  return { getPerimetresByCommune };
}
