import { useContext } from "react";
import { ChantiersSearchContext } from "../contexts/ChantiersSearchContext";
import type { ChantiersSearchData } from "../contexts/ChantiersSearchContext";

export default function useChantiersSearch(): ChantiersSearchData {
  const context = useContext(ChantiersSearchContext);

  if (context == null) {
    throw new Error("useChantiersSearch must be used within a ChantiersSearchProvider");
  }

  return context;
}
