import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import type { Commune } from "models";
import { communesService } from "services";
import { stringUtil } from "@sdeapps/react-core";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import { SkeletonInput } from "components/Loading";

type CommuneFiltrable = Commune & {
  libelleNormalise: string;
};
interface CommuneAutoCompleteProps {
  onChange: (commune?: Commune) => void;
  communeId?: string;
  size?: "small" | "medium";
}

function CommuneAutoComplete({
  onChange,
  communeId,
  size = "medium",
}: Readonly<CommuneAutoCompleteProps>): ReactNode {
  const [communes, setCommunes] = useState<Array<CommuneFiltrable>>([]);
  const [selectedCommune, setSelectedCommune] = useState<CommuneFiltrable | undefined>(undefined);
  const { catchErrors, isLoading } = useErrorHandler();

  useEffect(() => {
    async function load(): Promise<void> {
      const availableCommunes = await communesService.getAll();
      const communesNormalisees = (availableCommunes ?? [])
        .map<CommuneFiltrable>(({ id, libelle }) => ({
          id,
          libelle,
          libelleNormalise: stringUtil.normalize(libelle).toUpperCase(),
        }))
        .sort((a, b) => {
          return a.libelleNormalise.localeCompare(b.libelleNormalise);
        });
      setCommunes(communesNormalisees);
    }

    void catchErrors(load);
  }, [catchErrors]);

  useEffect(() => {
    setSelectedCommune(communes.find((com) => com.id === communeId));
  }, [communes, communeId]);

  if (isLoading) {
    return <SkeletonInput height={size === "small" ? 40 : undefined} />;
  }

  return (
    <FormControl fullWidth>
      <Autocomplete
        disablePortal
        size={size}
        renderInput={(params) => <TextField {...params} label="Commune" placeholder="Commune" />}
        value={selectedCommune ?? null}
        onChange={(_, newValue) => {
          const selectionCommune = newValue ?? undefined;
          setSelectedCommune(selectionCommune);

          if (selectionCommune != null) {
            const { libelleNormalise, ...commune } = selectionCommune;
            onChange(commune);
          } else {
            onChange(undefined);
          }
        }}
        options={communes}
        renderOption={(props, option: CommuneFiltrable) => (
          <Box component="li" {...props} key={option.id}>
            {option.libelle}
          </Box>
        )}
        getOptionLabel={(option: CommuneFiltrable) => option.libelle}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        filterOptions={(options, { inputValue }) => {
          return options.filter((opt) => opt.libelleNormalise.includes(inputValue.toUpperCase()));
        }}
      />
    </FormControl>
  );
}

const CommuneAutoCompleteWithErrorBoundary = withComponentErrorBoundary(CommuneAutoComplete);

export { CommuneAutoCompleteWithErrorBoundary };
