import type { Chantier } from "models";
import { createContext, useContext, useMemo } from "react";
import type { PropsWithChildren, ReactNode } from "react";

export interface ChantierContextData {
  chantier: Chantier | undefined;
  isLoading: boolean;
  updateChantier: VoidFunction;
}

const data: ChantierContextData = {
  chantier: undefined,
  isLoading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateChantier: () => {},
};

const ChantierContext = createContext(data);

type ChantierProviderProps = ChantierContextData & PropsWithChildren;

export function ChantierProvider({
  chantier,
  isLoading,
  updateChantier,
  children,
}: Readonly<ChantierProviderProps>): ReactNode {
  const data: ChantierContextData = useMemo(() => {
    return {
      chantier,
      isLoading,
      updateChantier,
    };
  }, [chantier, isLoading, updateChantier]);

  return <ChantierContext.Provider value={data}>{children}</ChantierContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useChantier(): ChantierContextData {
  const context = useContext(ChantierContext);

  if (context == null) {
    throw new Error("useChantier must be used within a ChantierProvider");
  }

  return context;
}
