import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { MenuItem } from "@mui/material";
import {
  StatutDossierAss,
  StatutDossierCipa,
  StatutDossierEau,
  StatutDossierPac,
  TypeDossier,
} from "models";
import type { StatutDossier } from "models";
import type { ControlledInput } from "utils/rules";
import type { FieldValues, FieldPath } from "react-hook-form";
import { ControlledTextField } from "./ControlledTextField";

interface ControlledStatutDossierProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  label: string;
  placeholder?: string;
  fullWidth?: boolean;
  type: TypeDossier;
}

export function ControlledStatutDossier<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  control,
  label,
  defaultValue,
  placeholder = label,
  rules,
  fullWidth = true,
  type,
}: Readonly<ControlledStatutDossierProps<T, Name>>): ReactNode {
  const [statutsList, setStatutsList] = useState<Array<StatutDossier>>([]);

  useEffect(() => {
    switch (type) {
      case TypeDossier.EAU:
        setStatutsList(Object.values(StatutDossierEau));
        break;
      case TypeDossier.ASS:
        setStatutsList(Object.values(StatutDossierAss));
        break;
      case TypeDossier.CIPA:
        setStatutsList(Object.values(StatutDossierCipa));
        break;
      case TypeDossier.PAC:
        setStatutsList(Object.values(StatutDossierPac));
        break;
      default:
        break;
    }
  }, [type]);

  return (
    <ControlledTextField
      label={label}
      placeholder={placeholder}
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      fullWidth={fullWidth}
      select>
      {statutsList?.map((statut) => (
        <MenuItem key={statut} value={statut}>
          {statut}
        </MenuItem>
      ))}
    </ControlledTextField>
  );
}
