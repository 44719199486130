import { TypeDossier } from "models";
import type { Chantier, Dossier, DossierAss, DossierCipa, DossierEau, DossierPac } from "models";

export function isDossierEau(dossier: Dossier): dossier is DossierEau {
  return dossier.type === TypeDossier.EAU;
}

export function isDossierAss(dossier: Dossier): dossier is DossierAss {
  return dossier.type === TypeDossier.ASS;
}

export function isDossierPac(dossier: Dossier): dossier is DossierPac {
  return dossier.type === TypeDossier.PAC;
}

export function isDossierCipa(dossier: Dossier): dossier is DossierCipa {
  return dossier.type === TypeDossier.CIPA;
}

export function getDossiers({
  dossierEau,
  dossierAss,
  dossierCipa,
  dossierPac,
}: Chantier): Array<Dossier> {
  const dossiers: Array<Dossier> = [];
  if (dossierEau != null) dossiers.push(dossierEau);
  if (dossierAss != null) dossiers.push(dossierAss);
  if (dossierCipa != null) dossiers.push(dossierCipa);
  if (dossierPac != null) dossiers.push(dossierPac);
  return dossiers;
}
