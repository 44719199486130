import type { AuthenticationConfig } from "@sdeapps/react-core";

const APPLICATION_NAME = "Opale";
const DEFAULT_PAGE_TITLE = "Opale";

export type RouteDefinition = {
  path: string;
  getParameterPath: (...parameters: Array<string>) => string;
  title: string;
};
/**
 * Clés des routes déclarées / déclarables.
 * La syntaxe `.. as const` permet d'avoir un tableau immuable (enum like)
 */
const declaredRoutesKeys = [
  "home",
  "nouveauChantier",
  "chantier",
  "chantierDossier",
  "chantierDossierAdministratif",
  "chantierDossierTechnique",
  "chantierDossierCourriers",
  "adresses",
  "modifierAdresse",
  "avis",
  "any",
] as const;
/** Type d'union des clés de routes possibles afin de typer nos `routesConfig` */
type RoutesConfigKey = (typeof declaredRoutesKeys)[number];
type RoutesConfig = Record<RoutesConfigKey, RouteDefinition>;
const routesConfig: RoutesConfig = {
  home: { path: "/", getParameterPath: (_) => "/", title: DEFAULT_PAGE_TITLE },
  nouveauChantier: {
    path: "/nouveau-chantier",
    getParameterPath: (_) => "/nouveau-chantier",
    title: "Nouveau chantier",
  },
  chantier: {
    path: "/chantier/:idChantier/",
    getParameterPath: (idChantier: string) => `/chantier/${idChantier}`,
    title: "Chantier",
  },
  chantierDossier: {
    path: "/chantier/:idChantier/dossier/:idDossier/*",
    getParameterPath: (idChantier: string, idDossier: string | undefined) =>
      `/chantier/${idChantier}/dossier/${idDossier}`,
    title: "Dossier",
  },
  chantierDossierAdministratif: {
    path: "/chantier/:idChantier/dossier/:idDossier/administratif",
    getParameterPath: (idChantier: string, idDossier: string | undefined) =>
      `/chantier/${idChantier}/dossier/${idDossier}/administratif`,
    title: "Dossier",
  },
  chantierDossierTechnique: {
    path: "/chantier/:idChantier/dossier/:idDossier/technique",
    getParameterPath: (idChantier: string, idDossier: string | undefined) =>
      `/chantier/${idChantier}/dossier/${idDossier}/technique`,
    title: "Dossier",
  },
  chantierDossierCourriers: {
    path: "/chantier/:idChantier/dossier/:idDossier/courriers",
    getParameterPath: (idChantier: string, idDossier: string | undefined) =>
      `/chantier/${idChantier}/dossier/${idDossier}/courriers`,
    title: "Dossier",
  },
  adresses: {
    path: "/adresses",
    getParameterPath: (_) => "/adresses",
    title: "Liste d'adresses",
  },
  modifierAdresse: {
    path: "/adresse/:idAdresse",
    getParameterPath: (idAdresse: string) => `/adresse/${idAdresse}`,
    title: "Modification d'une adresse",
  },
  avis: {
    path: "avis",
    getParameterPath: (_) => "/avis",
    title: "Recherche d'avis",
  },
  any: { path: "*", getParameterPath: (_) => "*", title: DEFAULT_PAGE_TITLE },
};

export type RouteBaseObject = {
  path: string;
  element: string;
};
const routes: Array<RouteBaseObject> = Object.values(routesConfig).map(
  ({ path, title: element }) => {
    return { path, element };
  }
);

const config = {
  basename: import.meta.env.PUBLIC_PATH,
  isBrowser: typeof window !== "undefined",
  appName: APPLICATION_NAME,
  appVersion: import.meta.env.VITE_VERSION ?? "local",
};

const authenticationConfig: AuthenticationConfig = {
  msal: {
    auth: {
      clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
      authority: import.meta.env.VITE_AUTH_AUTHORITY,
      knownAuthorities: [import.meta.env.VITE_AUTH_AUTHORITY ?? ""],
      redirectUri: import.meta.env.VITE_APP_BASE_URL,
      postLogoutRedirectUri: import.meta.env.VITE_APP_BASE_URL,
    },
    cache: {
      cacheLocation: "localStorage",
    },
  },
  graph: {
    graphEndpoint: "https://graph.microsoft.com/v1.0",
    scopes: ["User.Read"],
  },
};

const apiConfig: {
  apimBaseUrl: string;
  apimSubscriptionKey: string;
  opaleApi: {
    scopes: Array<string>;
    apiName: string;
  };
  collectivitesApi: {
    scopes: Array<string>;
    apiName: string;
  };
  azureAd: {
    scopes: Array<string>;
    groupeTechniciens: string;
  };
} = {
  apimBaseUrl: import.meta.env.VITE_APIM_BASE_URL ?? "",
  apimSubscriptionKey: import.meta.env.VITE_APIM_SUBSCRIPTION_KEY ?? "",
  opaleApi: {
    scopes: import.meta.env?.VITE_OPALE_SCOPES?.split(" ") ?? [],
    apiName: import.meta.env.VITE_OPALE_API_NAME ?? "",
  },
  collectivitesApi: {
    scopes: import.meta.env?.VITE_COLLECTIVITES_SCOPES?.split(" ") ?? [],
    apiName: import.meta.env.VITE_COLLECTIVITES_API_NAME ?? "",
  },
  azureAd: {
    scopes: ["User.Read"],
    groupeTechniciens: import.meta.env.VITE_OPALE_GROUPE_TECHNICIENS ?? "",
  },
};

const appiConfig: { instrumentationKey: string } = {
  instrumentationKey: import.meta.env.VITE_APPI_INSTRUMENTATION_KEY ?? "",
};

const envConfig: { name: string } = {
  name: import.meta.env.VITE_ENV ?? "",
};

const opaleDriveSiteName = import.meta.env.VITE_OPALE_DRIVE_SITE_NAME ?? "";
const opaleDriveRootFolder = import.meta.env.VITE_OPALE_DRIVE_ROOT_FOLDER ?? "";
const opaleDriveConfig = {
  // ex: "POC Opale"
  driveSiteName: opaleDriveSiteName,
  // ex: "GENERAL-dev"
  driveRootFolder: opaleDriveRootFolder,
  // ex: "https://sdeaalsacemoselle.sharepoint.com/sites/POC Opale/Documents%20partages/GENERAL-dev"
  driveRootFolderUrl: `https://sdeaalsacemoselle.sharepoint.com/sites/${opaleDriveSiteName}/Documents%20partages/${opaleDriveRootFolder}`,
};

const graphConfig: { graphEndpoint: string } = {
  graphEndpoint: "https://graph.microsoft.com/v1.0",
};

const localStorageKeys = {
  navigationMenu: "isNavigationMenuOpen",
};

const COLLECTIVITES_APP_DOMAIN_NAME = import.meta.env.VITE_COLLECTIVITES_APP_DOMAIN_NAME ?? "";

export {
  config,
  DEFAULT_PAGE_TITLE,
  apiConfig,
  authenticationConfig,
  appiConfig,
  envConfig,
  routesConfig,
  routes,
  graphConfig,
  localStorageKeys,
  COLLECTIVITES_APP_DOMAIN_NAME,
  opaleDriveConfig,
};
