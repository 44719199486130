import {
  FormHelperText,
  IconButton,
  Skeleton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import type { ControlledInput } from "utils/rules";
import { useController } from "react-hook-form";
import type { FieldValues, FieldPath } from "react-hook-form";
import type { TypeDossier } from "models";
import { avisService } from "services";
import DemandeurSection from "./DemandeurSection";
import TypoTableCell from "./TypoTableCell";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import { useErrorHandler } from "utils/errorHandling";

function AvisTableRowDataCellsSkeleton(): ReactNode {
  return (
    <>
      <TypoTableCell>
        <Skeleton />
      </TypoTableCell>
      <TypoTableCell>
        <Skeleton />
      </TypoTableCell>
      <TypoTableCell>
        <Skeleton />
      </TypoTableCell>
      <TypoTableCell>
        <Skeleton />
      </TypoTableCell>
      <TypoTableCell>
        <Skeleton />
      </TypoTableCell>
      <TableCell padding="checkbox" />
    </>
  );
}

interface AvisTableRowProps<
  ChantierCreateData extends FieldValues,
  Name extends FieldPath<ChantierCreateData>
> extends ControlledInput<ChantierCreateData, Name> {
  label?: string;
  typeDemande: TypeDossier;
  communeError?: boolean;
  demandeur1Error?: boolean;
  demandeur2Error?: boolean;
}

function getErrorStyles(error: boolean): {
  color: string;
  fontWeight: string;
} {
  return {
    color: error ? "error" : "inherit",
    fontWeight: error ? "500" : "initial",
  };
}

function AvisTableRow<
  ChantierCreateData extends FieldValues,
  Name extends FieldPath<ChantierCreateData>
>({
  name,
  label = "",
  rules,
  typeDemande,
  communeError = false,
  demandeur1Error = false,
  demandeur2Error = false,
}: Readonly<AvisTableRowProps<ChantierCreateData, Name>>): ReactNode {
  const [errorText, setErrorText] = useState("Avis non trouvé");
  const [innerAvisId, setInnerAvisId] = useState("");

  const {
    field: { value, onChange },
  } = useController({ name, rules });

  const { catchErrors, isLoading, error, setOriginalError, resetError } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    default: () => {
      onChange(undefined);
    },
  });

  useEffect(() => {
    if (value != null && value.typeDemande !== typeDemande) {
      setErrorText(`Cet avis n'est pas du type ${typeDemande}`);
      setOriginalError(true);
    }
    if (value?.idDossier != null) {
      setErrorText(`Cet avis est déjà utilisé par un autre dossier`);
      setOriginalError(true);
    }
  }, [value, setOriginalError, typeDemande]);

  function getAvis(): void {
    if (innerAvisId != null && innerAvisId !== "" && value?.idAvis !== innerAvisId) {
      resetError();
      void catchErrors(async (): Promise<void> => {
        setErrorText(`Avis ${innerAvisId} non trouvé`);
        const avis = await avisService.getAvisById(innerAvisId);
        onChange(avis);
      });
    }
  }

  function getAvisOnEnter(ev: React.KeyboardEvent<HTMLDivElement>): void {
    if (ev.key === "Enter") {
      getAvis();
      ev.preventDefault();
    }
  }

  return (
    <TableRow
      sx={(theme) => {
        if (value != null && (error != null || communeError || demandeur1Error || demandeur2Error))
          return { background: theme.palette.error.light };
        if (value != null) return { background: theme.palette.info.light };
        return {};
      }}>
      <TableCell width="300px">
        <Grid container alignItems="center" spacing={1}>
          <Grid size="grow">
            <TextField
              value={innerAvisId}
              onChange={({ target: { value: inputValue } }) => {
                setInnerAvisId(inputValue);
              }}
              label={label}
              onKeyDown={getAvisOnEnter}
            />
          </Grid>
          <Grid size="auto">
            <IconButton onClick={getAvis}>
              <RefreshIcon />
            </IconButton>
          </Grid>
          <Grid size={12}>
            {error != null && <FormHelperText error>{errorText}</FormHelperText>}
          </Grid>
        </Grid>
      </TableCell>
      {isLoading ? (
        <AvisTableRowDataCellsSkeleton />
      ) : (
        <>
          <TypoTableCell>{typeDemande}</TypoTableCell>
          <TypoTableCell>{value?.dateDemande}</TypoTableCell>
          <TypoTableCell {...getErrorStyles(communeError)}>
            {value?.codeInseeCommune} {value?.nomCommune}
          </TypoTableCell>
          <TypoTableCell {...getErrorStyles(demandeur1Error)}>
            <DemandeurSection demandeur={value?.demandeur1} />
          </TypoTableCell>
          <TypoTableCell {...getErrorStyles(demandeur2Error)}>
            <DemandeurSection demandeur={value?.demandeur2} />
          </TypoTableCell>
          <TableCell padding="checkbox">
            <IconButton
              onClick={() => {
                resetError();
                onChange(undefined);
                setInnerAvisId("");
              }}>
              <DeleteIcon color="error" />
            </IconButton>
          </TableCell>
        </>
      )}
    </TableRow>
  );
}

export default AvisTableRow;
