import { Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { Grid2Props as GridProps } from "@mui/material";
import type { ReactNode } from "react";

interface SkeletonInputProps extends GridProps {
  height?: string | number;
}

export function SkeletonInput({
  height = 56,
  ...gridProps
}: Readonly<SkeletonInputProps>): ReactNode {
  return (
    <Grid {...gridProps}>
      <Skeleton height={height} sx={{ transform: "none" }} width="100%" />
    </Grid>
  );
}
