import { createSvgIcon } from "@mui/material";

export const EauPotableIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M 21.891 19.466 C 21.891 20.865 20.597 22 19 22 C 17.403 22 16.109 20.865 16.109 19.466 C 16.109 18.066 16.897 17.414 19 15 C 20.051 16.328 21.891 18.066 21.891 19.466 Z M 21.077 19.008 C 20.953 19.008 20.853 19.1 20.853 19.214 C 20.853 19.328 20.953 19.421 21.077 19.421 C 21.2 19.421 21.301 19.328 21.301 19.214 C 21.301 19.1 21.2 19.008 21.077 19.008 Z M 20.769 19.876 C 20.7 20.06 20.55 20.268 20.318 20.486 C 20.201 20.597 20.073 20.699 19.924 20.798 C 19.849 20.848 19.773 20.893 19.691 20.937 C 19.609 20.981 19.426 21.065 19.424 21.066 C 19.428 21.065 19.623 21.365 19.633 21.356 C 19.633 21.356 20.312 20.989 20.566 20.749 C 20.824 20.506 21.016 20.23 21.105 20.007 Z" />
    <path d="M21 13.8748C21 13.9048 20.9885 13.9329 20.9683 13.9533C20.9484 13.9741 20.9216 13.9855 20.8938 13.9855L17.0786 14C17.0469 14 17.0531 13.9741 17.0535 13.9397C17.0535 13.0594 16.6816 12.1419 16.1417 11.4598C15.6635 10.8557 14.9139 10.1219 13.9069 10.1209L13.1207 10.1178L3.74346 10.0806H3.74126C3.54515 10.0806 3.35653 10.0003 3.21771 9.85653C3.07844 9.71322 3 9.51865 3 9.31547V6.69542C3 6.49178 3.07844 6.29721 3.21771 6.1539C3.35653 6.01013 3.54515 5.92985 3.74126 5.92985H9.35315V3.53022H7.55288C7.35677 3.53022 7.16859 3.44994 7.02933 3.30663C6.89007 3.16331 6.81207 2.96829 6.81207 2.76511C6.81207 2.56193 6.89007 2.36691 7.02933 2.22359C7.16859 2.08028 7.35677 2 7.55288 2H12.6355C12.8316 2 13.0198 2.08028 13.159 2.22359C13.2983 2.36691 13.3763 2.56193 13.3763 2.76511C13.3763 2.96829 13.2983 3.16331 13.159 3.30663C13.0198 3.44994 12.8316 3.53022 12.6355 3.53022H10.8352V5.92985H13.8853C16.0249 5.92895 17.6238 6.99203 18.9419 8.46011C20.2649 9.93318 21 11.867 21 13.8748Z" />
  </svg>,
  "EauPotableIcon"
);
