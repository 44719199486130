import img404 from "./assets/illustration-404.svg";
import type { ReactNode } from "react";
import { ToastMessages } from "enums";
import { ErrorPage } from ".";

interface NotFoundErrorPageProps {
  message?: string;
  error?: Error;
}

export function NotFoundErrorPage({ message, error }: Readonly<NotFoundErrorPageProps>): ReactNode {
  return (
    <ErrorPage
      title="404"
      subtitle={ToastMessages.ERROR_NOT_FOUND}
      message={message}
      image={img404}
      error={error}
    />
  );
}
