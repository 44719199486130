import type { ReactNode } from "react";
import { adressesService } from "services";
import GenericDataGrid from "./components/GenericDataGrid";
import type { GridColDef } from "@mui/x-data-grid";
import type { Adresse } from "models";
import { PageLayout } from "components/Layout";
import Grid from "@mui/material/Grid2";

const columns: Array<GridColDef<Adresse>> = [
  { flex: 2, field: "libelleCommune", headerName: "Commune" },
  { flex: 1, field: "codePostal", headerName: "Code Postal" },
  { flex: 1, field: "adresseVoie", headerName: "Adresse" },
  { flex: 2, field: "lotissement", headerName: "Lotissement" },
  { flex: 1, field: "routeDepartementale", headerName: "RD" },
  { flex: 1, field: "parcelle", headerName: "Parcelle" },
  { flex: 1, field: "lot", headerName: "Lot" },
  { flex: 1, field: "section", headerName: "Section" },
];

function AdressesPage(): ReactNode {
  return (
    <PageLayout pageTitle="🚧 Toutes les adresses 🚧">
      <Grid size="grow">
        <GenericDataGrid fetchFunction={adressesService.getAllAdresses} columns={columns} />
      </Grid>
    </PageLayout>
  );
}

export default AdressesPage;
