import { useEffect } from "react";
import type { ReactNode } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  LinearProgress,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ControlledNumberField, ControlledBooleanRadio } from "components/Inputs";
import type { DossierPacModifyDTO } from "models";
import { useFormContext, useWatch } from "react-hook-form";
import { calculService } from "services";
import { useDebouncedCallback } from "use-debounce";
import { useSnackbarErrorHandler } from "utils/errorHandling";
import financeUtils from "utils/financeUtils";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { grey } from "@mui/material/colors";

function AspectFinancierPacForm(): ReactNode {
  const { setValue } = useFormContext<DossierPacModifyDTO>();
  const { aspectFinancier } = useWatch<DossierPacModifyDTO>();

  const { catchErrors: catchErrorsCalcul, isLoading: isLoadingCalcul } = useSnackbarErrorHandler();

  const debouncedCalcul = useDebouncedCallback(() => {
    void catchErrorsCalcul(async () => {
      if (aspectFinancier != null) {
        const {
          pacAFacturer,
          pacEstimeeAvantTravaux,
          pacMaximale,
          prixAnc,
          totalEquivalentLogementsDomestiques,
          totalEquivalentLogementsEnsemble,
          version,
          ...param
        } = aspectFinancier;

        if (param != null) {
          const res = await calculService.postCalculAspectFinancierPac(param);

          setValue(
            "aspectFinancier.totalEquivalentLogementsDomestiques",
            res.totalEquivalentLogementsDomestiques
          );
          setValue(
            "aspectFinancier.totalEquivalentLogementsEnsemble",
            res.totalEquivalentLogementsEnsemble
          );
          setValue("aspectFinancier.pacMaximale", res.pacMaximale);
          setValue("aspectFinancier.prixAnc", res.prixAnc);
          setValue("aspectFinancier.pacEstimeeAvantTravaux", res.pacEstimeeAvantTravaux);
          setValue("aspectFinancier.pacAFacturer", res.pacAFacturer);
        }
      }
    });
  }, 500);

  useEffect(debouncedCalcul, [
    debouncedCalcul,
    aspectFinancier?.pacLogementSimple,
    aspectFinancier?.pacLogementSupplementaire,
    aspectFinancier?.nombreLogementsDomestiques,
    aspectFinancier?.concerneLogementSupplementaire,
    aspectFinancier?.restaurateur,
    aspectFinancier?.detailMetiersBouche,
    aspectFinancier?.hotellerieSansRepas,
    aspectFinancier?.hotelleriePensionComplete,
    aspectFinancier?.etudiant,
    aspectFinancier?.retraite,
    aspectFinancier?.detailHorsBouche,
    aspectFinancier?.enseignement,
    aspectFinancier?.camping,
    aspectFinancier?.gensDuVoyage,
    aspectFinancier?.jeux,
    aspectFinancier?.cabinetMedical,
    aspectFinancier?.sallesSport,
    aspectFinancier?.salonsCoiffure,
    aspectFinancier?.pressingsLaveries,
    aspectFinancier?.autresActivites,
    aspectFinancier?.branchementEauHt,
  ]);

  return (
    <>
      <Grid sx={{ height: "4px" }} size={12}>
        {isLoadingCalcul && <LinearProgress />}
      </Grid>
      <Grid container alignItems="center" size={12}>
        <Grid container size={10}>
          <Typography>Nombre de logements domestiques</Typography>
        </Grid>
        <Grid container justifyContent="flex-end" size={2}>
          <ControlledNumberField
            name="aspectFinancier.nombreLogementsDomestiques"
            type="entier"
            textAlign="right"
            size="small"
          />
        </Grid>
      </Grid>
      <Grid size={12}>
        <ControlledBooleanRadio
          name="aspectFinancier.concerneLogementSupplementaire"
          label="Concerne un logement supplémentaire"
          rules={{
            validate: (value) => typeof value === "boolean" || "Ce champ est obligatoire",
          }}
          defaultValue={false}
        />
      </Grid>
      <Grid container size={12}>
        <Accordion
          sx={{
            width: "100%",
            flexDirection: "row-reverse",
            border: `1px solid ${grey[400]}`,
          }}
          elevation={0}>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            sx={{ flexDirection: "row-reverse" }}>
            <Grid container spacing={2} alignItems="center" width="100%">
              <Grid container size={10}>
                <Typography sx={{ pl: 2 }}>
                  Total en équivalent logements des activités assimilables au domestique
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end" sx={{ pr: 2 }} size={2}>
                <Typography>
                  {aspectFinancier?.totalEquivalentLogementsDomestiques?.toFixed(2) ?? 0}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} alignItems="center">
              <Grid container size={10}>
                <Typography>Restaurateur : capacité dégraisseur en repas/jour</Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.restaurateur"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>
                  Commerce de détail, métiers de bouche (transformation sur place) : par unité
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.detailMetiersBouche"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>
                  Hôtellerie/chambres d'hôtes (sans repas) : nombre de chambres
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.hotellerieSansRepas"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>
                  Hôtellerie/chambre d'hôtes (pension complète) : nombre de chambres
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.hotelleriePensionComplete"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>
                  Hébergement, congrégrations, résidences pour étudiants ou militaires : par
                  habitant
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.etudiant"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>
                  Maisons de retraite, maisons de soins, centres pénitentiaires : nombre de
                  résidents à pleine charge
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.retraite"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>
                  Commerce de détail (hors métiers de bouche avec transformation sur place) : par
                  unité
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.detailHorsBouche"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>Enseignement : nombre d'élèves</Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.enseignement"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>Camping : nombre d'emplacements</Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.camping"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>Aire de gens du voyage : nombre d'emplacements</Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.gensDuVoyage"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>
                  Etablissements de jeux, débits de boissons, culture, loisirs : par WC ou urinoir
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.jeux"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>Cabinet médical : unités</Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.cabinetMedical"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>Salles de sport : par 4 douches ou par 1 WC/urinoir</Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.sallesSport"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>Salons de coiffure : par point d'eau</Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.salonsCoiffure"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>
                  Pressings et laveries : par tranche de consommation prévue de 450 L/j
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.pressingsLaveries"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>

              <Grid container size={10}>
                <Typography>
                  Autres activités mentionnées à l'annexe 1 du 21/12/2007 - activités
                  administratives ou non polluantes : nombre cumulé de cabinets et d'urinoirs
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end" size={2}>
                <ControlledNumberField
                  name="aspectFinancier.autresActivites"
                  type="entier"
                  textAlign="right"
                  size="small"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid container alignItems="center" size={12}>
        <Grid container size={10}>
          <Typography>
            Total en équivalent logements de l'ensemble de l'operation (mini 1 et arrondi à l'unite)
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" sx={{ pr: 4 }} size={2}>
          <Typography>{aspectFinancier?.totalEquivalentLogementsEnsemble ?? 0}</Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" size={12}>
        <Grid container size={9}>
          <Typography>Coût total du branchement EU (HT)</Typography>
        </Grid>
        <Grid container justifyContent="flex-end" size={3}>
          <ControlledNumberField
            name="aspectFinancier.branchementEauHt"
            type="decimal"
            withEndEuro
            textAlign="right"
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" size={12}>
        <Grid container size={6}>
          <Typography>PAC maximale</Typography>
        </Grid>
        <Grid container justifyContent="flex-end" size={6}>
          <Typography>{financeUtils.toRoundedEuroString(aspectFinancier?.pacMaximale)}</Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" size={12}>
        <Grid container size={6}>
          <Typography>Prix ANC équivalent estimé</Typography>
        </Grid>
        <Grid container justifyContent="flex-end" size={6}>
          <Typography>{financeUtils.toRoundedEuroString(aspectFinancier?.prixAnc)}</Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" size={12}>
        <Grid container size={6}>
          <Typography color="info.main" fontWeight={500}>
            PAC estimée avant travaux
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" size={6}>
          <Typography color="info.main" fontWeight={500}>
            {financeUtils.toRoundedEuroString(aspectFinancier?.pacEstimeeAvantTravaux)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" size={12}>
        <Grid container size={6}>
          <Typography color="info.main" fontWeight={500}>
            PAC à facturer
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" size={6}>
          <Typography color="info.main" fontWeight={500}>
            {financeUtils.toRoundedEuroString(aspectFinancier?.pacAFacturer)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default AspectFinancierPacForm;
