import type { ReactNode } from "react";
import type { StatutDossier } from "models";
import { Stack, Typography } from "@mui/material";
import type { SxProps } from "@mui/material";
import { getStatutDossierColor, getStatutDossierIcon } from "utils/statutUtil";

interface StatutChipProps {
  statut: StatutDossier;
  sx?: SxProps;
}

function StatutChip({ statut, sx }: Readonly<StatutChipProps>): ReactNode {
  return (
    <Typography
      component="span"
      color={`${getStatutDossierColor(statut)}.main`}
      sx={{
        backgroundColor: `${getStatutDossierColor(statut)}.light`,
        paddingX: 1,
        paddingY: 0.5,
        fontWeight: 600,
        borderRadius: 1,
        display: "inline-block",
        alignItems: "center",
        fontSize: "0.9em",
        ...sx,
      }}>
      <Stack component="span" direction="row" spacing={1} alignItems="center">
        {getStatutDossierIcon(statut, "inherit")}
        <span>{statut}</span>
      </Stack>
    </Typography>
  );
}

export default StatutChip;
