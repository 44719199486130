import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Avatar } from "@mui/material";
import type { SxProps } from "@mui/material";
import { graphService } from "services";
import { useErrorHandler } from "utils/errorHandling";

interface TechnicienAvatarProps {
  technicien: { id?: string; name?: string };
  size?: number;
  sx?: SxProps;
}

function TechnicienAvatar({
  technicien: { id, name },
  size,
  sx,
}: Readonly<TechnicienAvatarProps>): ReactNode {
  const [technicienPicture, setTechnicienPicture] = useState<string>();

  const { catchErrors } = useErrorHandler({
    dontThrow: true,
    default: () => {
      setTechnicienPicture(undefined);
    },
  });

  useEffect(() => {
    async function getTechnicienPicture(): Promise<void> {
      const picture = await graphService.getUserPhoto(id ?? "");
      setTechnicienPicture(picture);
    }
    void catchErrors(getTechnicienPicture);
  }, [catchErrors, id]);

  return (
    <Avatar
      alt={name}
      src={technicienPicture}
      sx={size != null ? { width: size, height: size, ...sx } : sx}
    />
  );
}

export default TechnicienAvatar;
