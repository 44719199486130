import { useEffect } from "react";
import type { ReactNode } from "react";
import { MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DiametreBranchementEau, NatureConduiteEau } from "models";
import type { DossierEau, DossierEauTechniqueModifyDTO, PatchData } from "models";
import { FormSection } from "components/Layout";
import { SdeappsError, useSnackbarErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import { LoadingScreen } from "components/Loading";
import { grey } from "@mui/material/colors";
import { useDossier } from "providers";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import {
  ControlledBooleanRadio,
  ControlledTextField,
  ControlledNumberField,
} from "components/Inputs";
import { ToastMessages } from "enums";
import { enqueueSnackbar } from "notistack";
import { dossierService } from "services";
import { Compteurs } from "../Compteurs";
import patchUtils from "utils/patchUtils";
import AspectFinancierEauForm from "./AspectFinancierEauForm";
import DossierSections from "../DossierSections";
import { dossierEauSections } from "../routes/dossiersSectionsDefinitions";

const propertiesExcludedFromPatch: Array<keyof DossierEauTechniqueModifyDTO> = [
  "aspectFinancier",
  "branchementParticulier",
  "compteurs",
];

function getCompteursPatchData(
  dossierData: DossierEauTechniqueModifyDTO,
  dossier: DossierEau
): Array<PatchData> {
  const patches: Array<PatchData> = [];

  dossier?.compteurs?.forEach((_) => {
    patches.push({
      op: "remove",
      path: `/compteurs/-`,
    });
  });
  dossierData.compteurs.forEach((compteur) => {
    patches.push({
      op: "add",
      path: "/compteurs/-",
      value: {
        nombre: compteur.nombre,
        calibre: compteur.calibre,
        emplacement: compteur.emplacement,
      },
    });
  });

  return patches;
}

function getBranchementsPatchData(dossierData: DossierEauTechniqueModifyDTO): Array<PatchData> {
  if (dossierData.branchementParticulier?.percementDuMurCave == null) {
    dossierData.branchementParticulier.percementDuMurCave = undefined;
  }
  if (dossierData.branchementParticulier?.travauxDeTerrassement == null) {
    dossierData.branchementParticulier.travauxDeTerrassement = undefined;
  }

  if (Object.entries(dossierData.branchementParticulier).some(([_, value]) => value != null)) {
    return [
      {
        op: "replace",
        path: "/branchementParticulier",
        value: dossierData.branchementParticulier,
      },
    ];
  }

  return [];
}

function getCalculsPatchData(dossierData: DossierEauTechniqueModifyDTO): Array<PatchData> {
  if (dossierData.aspectFinancier?.montantDevis != null) {
    return [
      {
        op: "replace",
        path: "/aspectFinancier",
        value: dossierData.aspectFinancier,
      },
    ];
  }

  return [];
}

function getRiaPatchData(dossierData: DossierEauTechniqueModifyDTO): Array<PatchData> {
  return [
    {
      op: "replace",
      path: "/ria",
      value: dossierData.ria,
    },
    {
      op: "replace",
      path: "/calibreRia",
      value: dossierData.ria === true ? dossierData.calibreRia : undefined,
    },
  ];
}

function toPatchData(
  dossierData: DossierEauTechniqueModifyDTO,
  dossier: DossierEau
): Array<PatchData> {
  const patches = patchUtils.toPatchData(dossierData, propertiesExcludedFromPatch);

  return [
    ...patches,
    ...getCompteursPatchData(dossierData, dossier),
    ...getBranchementsPatchData(dossierData),
    ...getCalculsPatchData(dossierData),
    ...getRiaPatchData(dossierData),
  ];
}

function fromDossierToDossierEauTechniqueModifyDTO(
  dossier: DossierEau
): DossierEauTechniqueModifyDTO {
  const dossierEauTechnique: DossierEauTechniqueModifyDTO = {
    ria: dossier?.ria ?? null,
    servitudeDePose: dossier?.servitudeDePose ?? null,
    calibreRia: dossier?.calibreRia,
    descriptionTravaux: dossier?.descriptionTravaux,
    compteurs: dossier?.compteurs ?? [],
    branchementParticulier: {
      travauxDeTerrassement: dossier?.branchementParticulier?.travauxDeTerrassement ?? null,
      percementDuMurCave: dossier?.branchementParticulier?.percementDuMurCave ?? null,
      diametre: dossier?.branchementParticulier?.diametre,
      longueur: dossier?.branchementParticulier?.longueur,
      natureConduite: dossier?.branchementParticulier?.natureConduite,
      nombreDeRegards: dossier?.branchementParticulier?.nombreDeRegards,
      remarque: dossier?.branchementParticulier?.remarque,
    },
    aspectFinancier: dossier?.aspectFinancier,
  };

  return dossierEauTechnique;
}

function DossierEauFormTechnique(): ReactNode {
  const { dossier, isLoading: isDossierLoading = false, updateDossier } = useDossier<DossierEau>();

  const formMethods = useForm<DossierEauTechniqueModifyDTO>({
    shouldFocusError: false,
    defaultValues: { compteurs: [], branchementParticulier: {} },
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = formMethods;

  const ria = useWatch({ control, name: "ria" });

  const { catchErrors } = useSnackbarErrorHandler();

  useEffect(() => {
    if (dossier != null) {
      reset(fromDossierToDossierEauTechniqueModifyDTO(dossier));
    }
  }, [dossier, reset]);

  const sendData: SubmitHandler<DossierEauTechniqueModifyDTO> = async function (
    dossierData: DossierEauTechniqueModifyDTO
  ): Promise<void> {
    if (dossier == null) {
      throw new SdeappsError("DOSSIER NE PEUT PAS ETRE NUL !!!!");
    }
    if (!isValid) {
      console.warn("La méthode ne doit pas être appelée si le formulaire contient des erreurs.");
      return;
    }

    await catchErrors(async () => {
      const patchData = toPatchData(dossierData, dossier);
      await dossierService.patchDossier(dossier.idChantier, dossier.id, patchData);
      updateDossier();
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Grid
        container
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(sendData, () => {
          enqueueSnackbar({
            variant: "error",
            message: ToastMessages.ERROR_FORM_VALIDATION,
          });
        })}>
        <DossierSections isLoading={false} sections={dossierEauSections} />

        {dossier == null || isDossierLoading ? (
          <LoadingScreen />
        ) : (
          <Grid container spacing={2} sx={{ p: 2, background: grey[200] }} size={12}>
            <FormSection title="Caractéristiques">
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledBooleanRadio
                  name="servitudeDePose"
                  label="Servitude de pose"
                  defaultValue={null}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 9 }}>
                <ControlledTextField
                  name="descriptionTravaux"
                  label="Description des travaux"
                  multiline
                />
              </Grid>
            </FormSection>

            <FormSection title="Compteurs d'eau" size={{ xs: 12, lg: 8 }}>
              <Grid size={12}>
                <Compteurs name="compteurs" defaultValue={[]} />
              </Grid>
            </FormSection>

            <FormSection title="Robinet incendie armé *" size={{ xs: 12, lg: 4 }}>
              <Grid size={12}>
                <ControlledBooleanRadio
                  name="ria"
                  defaultValue={null}
                  rules={{
                    validate: (value) => typeof value === "boolean" || "Ce champ est obligatoire",
                  }}
                />
              </Grid>
              {(ria ?? false) && (
                <Grid size={12}>
                  <ControlledTextField name="calibreRia" label="Calibre RIA" />
                </Grid>
              )}
            </FormSection>

            <FormSection title="Branchement particulier">
              <Grid size={{ xs: 12, md: 6 }}>
                <ControlledBooleanRadio
                  name="branchementParticulier.travauxDeTerrassement"
                  label="Terrassement"
                  defaultValue={null}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <ControlledBooleanRadio
                  name="branchementParticulier.percementDuMurCave"
                  label="Percement du mur cave"
                  defaultValue={null}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledTextField
                  name="branchementParticulier.natureConduite"
                  select
                  label="Nature de la conduite">
                  {Object.entries(NatureConduiteEau).map(([key, natureConduite]) => (
                    <MenuItem key={key} value={natureConduite}>
                      {natureConduite}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledTextField
                  name="branchementParticulier.diametre"
                  select
                  label="Diamètre en mm">
                  {Object.entries(DiametreBranchementEau).map(([key, diametre]) => (
                    <MenuItem key={key} value={diametre}>
                      {key.substring(1)}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledNumberField
                  label="Longueur en mètres"
                  name="branchementParticulier.longueur"
                  type="entier"
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledNumberField
                  label="Nombre de regards"
                  name="branchementParticulier.nombreDeRegards"
                  type="entier"
                />
              </Grid>

              <Grid size={12}>
                <ControlledTextField
                  name="branchementParticulier.remarque"
                  label="Remarques notées sur la demande de travaux"
                  multiline
                />
              </Grid>
            </FormSection>
            <FormSection title="Aspect Financier" size={{ xs: 12, md: 9, lg: 6 }}>
              <AspectFinancierEauForm />
            </FormSection>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
}

export const DossierEauFormTechniqueWithErrorBoundary =
  withPageErrorBoundary(DossierEauFormTechnique);
