import { ToastMessages } from "enums";
import type { Dossier } from "models";
import { enqueueSnackbar } from "notistack";
import type { DossierContextData } from "providers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { dossierService } from "services";
import { useErrorHandler } from "utils/errorHandling";

interface UseGetDossierReturn {
  dossier: Dossier | undefined;
  isLoading: boolean;
  updateDossier: () => void;
}

export function useGetDossier(idChantier: string, idDossier: string): UseGetDossierReturn {
  const [dossier, setDossier] = useState<Dossier>();

  const { catchErrors, isLoading } = useErrorHandler({
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  const memoizedLoadDossier = useCallback(() => {
    async function loadDossier(): Promise<void> {
      const dossier = await dossierService.getDossier(idChantier, idDossier);
      setDossier(dossier);
    }

    void catchErrors(loadDossier);
  }, [catchErrors, idChantier, idDossier]);

  useEffect(() => {
    setDossier(undefined);
    memoizedLoadDossier();
  }, [memoizedLoadDossier]);

  const data: DossierContextData = useMemo(() => {
    return {
      dossier,
      isLoading,
      updateDossier: memoizedLoadDossier,
    };
  }, [dossier, isLoading, memoizedLoadDossier]);

  return data;
}
