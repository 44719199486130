import type { PropsWithChildren, ReactNode } from "react";
import { Link, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useDossierInformationsEssentielles } from "hooks";
import { grey } from "@mui/material/colors";
import { FormSection } from "components/Layout";
import TechnicienAvatar from "pages/Recherche/components/TechnicienAvatar";
import StatutChip from "pages/Recherche/components/StatutChip";
import type { StatutDossier } from "models";
import { COLLECTIVITES_APP_DOMAIN_NAME } from "config/app-config";

interface InformationEssentielleProps extends PropsWithChildren {
  libelle: string;
}
function InformationEssentielle({
  libelle,
  children,
}: Readonly<InformationEssentielleProps>): ReactNode {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography component="span" fontWeight={500}>
        {libelle}{" "}
      </Typography>
      <Typography component="span" color="primary.main">
        {children}
      </Typography>
    </Stack>
  );
}

export function InformationsEssentielles(): ReactNode {
  const { hasInformations, hasComplementInformations, informations, dossierColor } =
    useDossierInformationsEssentielles();

  if (informations == null || !hasInformations) {
    return undefined;
  }

  return (
    <Grid container spacing={2} sx={{ p: 2, background: grey[200] }} size={12}>
      <FormSection sx={{ zIndex: 2 }}>
        {(informations.referenceDossier != null || informations?.idAvis != null) && (
          <Grid size={{ xs: 12, md: "auto" }}>
            <Typography variant="h3" color={dossierColor} minWidth="100px">
              {informations.referenceDossier}
            </Typography>
            {informations?.idAvis != null && (
              <Typography variant="caption">Avis {informations.idAvis}</Typography>
            )}
          </Grid>
        )}
        <Grid container rowSpacing={0.5} size={{ xs: 12, md: "grow" }}>
          <Grid size={12}>
            <InformationEssentielle libelle="Périmètre SDEA">
              <Link
                target="_blank"
                href={`https://${COLLECTIVITES_APP_DOMAIN_NAME}/perimetre/${informations.perimetre?.id}`}>
                {informations.perimetre?.libelle}
              </Link>
            </InformationEssentielle>
          </Grid>
          <Grid size={12}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography fontWeight={500}>Suivi par </Typography>
              <TechnicienAvatar technicien={informations.technicien} size={25} />
              <Typography color="primary.main">{informations.technicien.name}</Typography>
            </Stack>
          </Grid>
          <Grid size={12}>
            <InformationEssentielle libelle="Statut">
              <StatutChip statut={informations.statutDemande as StatutDossier} />
            </InformationEssentielle>
          </Grid>
        </Grid>
        {hasComplementInformations === true && (
          <Grid container rowSpacing={0.5} size={{ xs: 12, lg: "grow" }}>
            <Grid size={12}>
              <InformationEssentielle libelle="Type d'intervention">
                {informations.typeIntervention}
              </InformationEssentielle>
            </Grid>
            <Grid size={12}>
              <InformationEssentielle libelle="Permission de voirie">
                {informations.permissionVoirie}
              </InformationEssentielle>
            </Grid>
            <Grid size={12}>
              <InformationEssentielle libelle="Fournisseur">
                {informations.libelleFournisseur}
              </InformationEssentielle>
            </Grid>
          </Grid>
        )}
      </FormSection>
    </Grid>
  );
}
