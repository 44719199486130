import { useEffect } from "react";
import type { ReactNode } from "react";
import { MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TypeDossier, TypeInterventionEau } from "models";
import type { DossierEauAdministratifModifyDTO, DossierEau } from "models";
import {
  ControlledDateTime,
  ControlledTextField,
  ControlledStatutDossier,
  ControlledBooleanRadio,
} from "components/Inputs";
import { FormSection } from "components/Layout";
import { dateUtil } from "@sdeapps/react-core";
import { Controller, FormProvider, useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import TechnicienAutoCompleteWithErrorBoundary from "../TechnicienAutoCompleteWithErrorBoundary";
import { dossierService } from "services";
import { SdeappsError, useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import { enqueueSnackbar } from "notistack";
import { ToastMessages } from "enums";
import FournisseurAutoComplete from "../FournisseurAutoComplete";
import { LoadingScreen } from "components/Loading";
import { grey } from "@mui/material/colors";
import { useDossier } from "providers";
import patchUtils from "utils/patchUtils";
import DossierSections from "../DossierSections";
import { dossierEauSections } from "../routes/dossiersSectionsDefinitions";

const dateFormat = "yyyy-MM-dd";

function formatDate(date: string | undefined): Date | undefined {
  if (date == null) return undefined;
  return dateUtil.fromFormatToDate(date, dateFormat);
}

function fromDossierToDossierEauAdministratifModifyDTO(
  dossier: DossierEau
): DossierEauAdministratifModifyDTO {
  const dossierEauAdmin: DossierEauAdministratifModifyDTO = {
    idTechnicien: dossier.technicien.id,
    nameTechnicien: dossier.technicien.name,
    statutDemande: dossier.statutDemande,
    dateDemande: dateUtil.fromFormatToDate(dossier.dateDemande, dateFormat),
    dateDossierComplet: formatDate(dossier.dateDossierComplet),
    dateEnvoiDevisClient: formatDate(dossier.dateEnvoiDevisClient),
    dateAcceptationTravaux: formatDate(dossier.dateAcceptationTravaux),
    dateDemandePiecesDossier: formatDate(dossier.dateDemandePiecesDossier),
    dateReceptionAcompte: formatDate(dossier.dateReceptionAcompte),
    dateDebutTravaux: formatDate(dossier.dateDebutTravaux),
    dateRealisationTravaux: formatDate(dossier.dateRealisationTravaux),
    dateSouscription: formatDate(dossier.dateSouscription),
    typeIntervention: dossier.typeIntervention,
    observations: dossier.observations,
    permissionVoirie: dossier.permissionVoirie ?? null,
    dateDemandeVoirie: formatDate(dossier.dateDemandeVoirie),
    dateAutorisationVoirie: formatDate(dossier.dateAutorisationVoirie),
    travauxRegie: dossier.travauxRegie ?? null,
    idFournisseur: dossier.idFournisseur,
    libelleFournisseur: dossier.libelleFournisseur,
    dateDemandeDevis: formatDate(dossier.dateDemandeDevis),
    dateRetourDevis: formatDate(dossier.dateRetourDevis),
    dateBonCommande: formatDate(dossier.dateBonCommande),
  };

  return dossierEauAdmin;
}

const validDateRule = {
  validate: (value: Date | string | undefined) =>
    dateUtil.isValid(value) || "Veuillez saisir une date valide",
};

function DossierEauFormAdministratif(): ReactNode {
  const { dossier, isLoading: isDossierLoading = false, updateDossier } = useDossier<DossierEau>();

  const formMethods = useForm<DossierEauAdministratifModifyDTO>({
    shouldFocusError: false,
  });
  const {
    setValue,
    handleSubmit,
    reset,
    formState: { isValid },
  } = formMethods;

  const { catchErrors, isLoading } = useErrorHandler({
    defaultIsLoading: false,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  useEffect(() => {
    if (dossier != null) {
      reset(fromDossierToDossierEauAdministratifModifyDTO(dossier));
    }
  }, [dossier, reset]);

  const sendData: SubmitHandler<DossierEauAdministratifModifyDTO> = async function (
    dossierData: DossierEauAdministratifModifyDTO
  ): Promise<void> {
    if (dossier == null) {
      throw new SdeappsError("DOSSIER NE PEUT PAS ETRE NUL !!!!");
    }
    if (!isValid) {
      console.warn("La méthode ne doit pas être appelée si le formulaire contient des erreurs.");
      return;
    }
    await catchErrors(async () => {
      const patchData = patchUtils.toPatchData(dossierData);
      await dossierService.patchDossier(dossier.idChantier, dossier.id, patchData);
      updateDossier();
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Grid
        container
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(sendData, () => {
          enqueueSnackbar({
            variant: "error",
            message: ToastMessages.ERROR_FORM_VALIDATION,
          });
        })}>
        <DossierSections isLoading={isLoading} sections={dossierEauSections} />

        {dossier == null || isLoading || isDossierLoading ? (
          <LoadingScreen />
        ) : (
          <Grid container spacing={2} sx={{ p: 2, background: grey[200] }} size={12}>
            <FormSection title="Gestion de la demande">
              <Grid size={{ xs: 12, md: 6 }}>
                <Controller
                  name="idTechnicien"
                  defaultValue={dossier.technicien.id}
                  rules={{ required: "Il est obligatoire de saisir un technicien." }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TechnicienAutoCompleteWithErrorBoundary
                      libelle="Suivi par *"
                      technicienId={value}
                      error={error}
                      onChange={(tech) => {
                        onChange(tech?.id);
                        setValue("nameTechnicien", tech?.displayName);
                        setValue("mailTechnicien", tech?.mail);
                        setValue("centreTechnicien", tech?.centre);
                        setValue("telephoneTechnicien", tech?.telephone);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <ControlledStatutDossier
                  name="statutDemande"
                  label="Statut *"
                  type={TypeDossier.EAU}
                />
              </Grid>
            </FormSection>

            <FormSection title="Suivi des dates">
              <Grid size={12}>
                <Typography variant="body1" fontWeight={600}>
                  Préparation de la demande
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledDateTime
                  label="Date de la demande *"
                  name="dateDemande"
                  rules={{ required: "Ce champ est obligatoire", ...validDateRule }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledDateTime
                  name="dateDossierComplet"
                  label="Dossier complet"
                  rules={validDateRule}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledDateTime
                  label="Envoi devis client"
                  name="dateEnvoiDevisClient"
                  rules={validDateRule}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledDateTime
                  name="dateAcceptationTravaux"
                  label="Acceptation des travaux"
                  rules={validDateRule}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledDateTime
                  name="dateDemandePiecesDossier"
                  label="Demande de pièces dossier"
                  rules={validDateRule}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledDateTime
                  name="dateReceptionAcompte"
                  label="Réception de l'acompte"
                  rules={validDateRule}
                />
              </Grid>
              <Grid size={12}>
                <Typography variant="body1" fontWeight={600}>
                  Suivi des travaux
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledDateTime
                  name="dateDebutTravaux"
                  label="Début travaux vu avec client"
                  rules={validDateRule}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledDateTime
                  name="dateRealisationTravaux"
                  label="Réalisation des travaux"
                  rules={validDateRule}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledDateTime
                  label="Souscription"
                  name="dateSouscription"
                  rules={validDateRule}
                />
              </Grid>
            </FormSection>

            <FormSection title="Traitement de la demande">
              <Grid size={{ xs: 12, md: 6 }}>
                <ControlledTextField
                  name="typeIntervention"
                  select
                  label="Type d'intervention *"
                  rules={{ required: "Le type d'intervention est obligatoire." }}>
                  {Object.values(TypeInterventionEau).map((_typeIntervention) => (
                    <MenuItem key={_typeIntervention} value={_typeIntervention}>
                      {_typeIntervention}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <ControlledTextField name="observations" label="Observations" multiline />
              </Grid>
            </FormSection>

            <FormSection title="Voirie">
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledBooleanRadio
                  name="permissionVoirie"
                  label="Permission Voirie :"
                  defaultValue={null}
                />
              </Grid>
              <Grid container spacing={2} size={12}>
                <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                  <ControlledDateTime
                    name="dateDemandeVoirie"
                    label="Demande de voirie"
                    rules={validDateRule}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                  <ControlledDateTime
                    name="dateAutorisationVoirie"
                    label="Date d'autorisation"
                    rules={validDateRule}
                  />
                </Grid>
              </Grid>
            </FormSection>

            <FormSection title="Travaux">
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <ControlledBooleanRadio
                  name="travauxRegie"
                  label="Travaux en régie :"
                  defaultValue={null}
                />
              </Grid>
              <Grid container spacing={2} size={12}>
                <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                  <Controller
                    name="idFournisseur"
                    defaultValue={dossier.idFournisseur}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <FournisseurAutoComplete
                        label="Fournisseur"
                        fournisseurId={value}
                        error={error}
                        onChange={(fournisseur) => {
                          onChange(fournisseur?.id);
                          setValue("libelleFournisseur", fournisseur?.libelle);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} size={12}>
                <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                  <ControlledDateTime label="Demande de devis" name="dateDemandeDevis" />
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                  <ControlledDateTime label="Retour de devis" name="dateRetourDevis" />
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                  <ControlledDateTime label="Bon de commande" name="dateBonCommande" />
                </Grid>
              </Grid>
            </FormSection>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
}

export const DossierEauFormAdministratifWithErrorBoundary = withPageErrorBoundary(
  DossierEauFormAdministratif
);
