import { opaleDriveConfig } from "config/app-config";
import type { Chantier, Dossier } from "models";

const COURRIERS_FOLDER_NAME = "COURRIERS";

function getChantierFolderUrl(chantier: Chantier): string {
  return `${opaleDriveConfig.driveRootFolderUrl}/${chantier.folderName}`;
}

function getDossierFolderUrl(dossier: Dossier, chantier: Chantier): string {
  return `${opaleDriveConfig.driveRootFolderUrl}/${chantier.folderName}/${dossier.folderName}`;
}

function getCourrierFolderPath(dossier: Dossier, chantier: Chantier): string {
  return `${opaleDriveConfig.driveRootFolder}/${chantier.folderName}/${dossier.folderName}/${COURRIERS_FOLDER_NAME}`;
}

function getCourrierUrl(dossier: Dossier, chantier: Chantier, courrierFilename: string): string {
  return `${getDossierFolderUrl(dossier, chantier)}/${COURRIERS_FOLDER_NAME}/${courrierFilename}`;
}

export const filesService = {
  getChantierFolderUrl,
  getCourrierUrl,
  getDossierFolderUrl,
  getCourrierFolderPath,
};
