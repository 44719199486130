import { Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { ReactNode } from "react";
import AdresseForm from "./components/AdresseForm";
import AvisForm from "./components/AvisForm";
import useChantierCreation from "./hooks/useChantierCreation";
import { useNavigate } from "react-router-dom";
import { FormSection, PageLayout } from "components/Layout";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { withPageErrorBoundary } from "utils/errorHandling";
import { FormProvider } from "react-hook-form";
import { routesConfig } from "config/app-config";
import LoadingButton from "components/LoadingButton";

const gridSpacing = 2;

function NouveauChantier(): ReactNode {
  const navigate = useNavigate();
  const { formMethods, onSubmit, isSending } = useChantierCreation({
    onCreationSucceed: (idChantier: string) => {
      navigate(routesConfig.chantier.getParameterPath(idChantier));
    },
  });

  return (
    <PageLayout pageTitle="Nouveau chantier">
      <FormProvider {...formMethods}>
        <Grid
          container
          spacing={gridSpacing}
          component="form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={onSubmit}
          alignItems="flex-end">
          <FormSection title="Ajouter des avis *">
            <Grid size={12}>
              <AvisForm />
            </Grid>
          </FormSection>

          <FormSection title="Adresse du chantier *">
            <Grid container alignItems="flex-end" spacing={gridSpacing} size={12}>
              <AdresseForm gridSpacing={gridSpacing} />
            </Grid>
          </FormSection>

          <FormSection>
            <Grid container spacing={2} justifyContent="flex-end" size={12}>
              <Grid size="auto">
                <Button
                  variant="text"
                  color="error"
                  onClick={() => {
                    navigate("/");
                  }}>
                  Annuler
                </Button>
              </Grid>
              <Grid size="auto">
                <LoadingButton
                  variant="outlined"
                  startIcon={<CreateNewFolderIcon />}
                  type="submit"
                  loading={isSending}>
                  Créer le chantier
                </LoadingButton>
              </Grid>
            </Grid>
          </FormSection>
        </Grid>
      </FormProvider>
    </PageLayout>
  );
}

export const NouveauChantierWithErrorBoundary = withPageErrorBoundary(NouveauChantier);
