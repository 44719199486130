import { useEffect, useMemo, useState } from "react";
import type { PropsWithChildren, ReactNode } from "react";
import { ChantiersSearchContext } from "../contexts/ChantiersSearchContext";
import type { ChantiersSearchData } from "../contexts/ChantiersSearchContext";
import { AllStatutDossier } from "models";
import type { Chantier, ChantiersSearchFiltre } from "models";
import { chantierService } from "services";
import { useErrorHandler } from "utils/errorHandling";
import { getDossiers } from "utils/dossierUtil";
import { useUser } from "@sdeapps/react-core";

export function ChantiersSearchProvider({ children }: Readonly<PropsWithChildren>): ReactNode {
  const { catchErrors, isLoading } = useErrorHandler();
  const [chantiers, setChantiers] = useState<Array<Chantier>>([]);
  const { user } = useUser();
  const defaultFiltres: ChantiersSearchFiltre = useMemo(() => {
    const _defaultFiltres: ChantiersSearchFiltre = {
      client: "",
      adresse: "",
      communeId: "",
      reference: "",
      type: undefined,
      dateDemandeAuIncluse: undefined,
      dateDemandeDuIncluse: undefined,
      technicienId: user.id,
      statuts: [
        AllStatutDossier.EN_COURS_TRAITEMENT,
        AllStatutDossier.INCOMPLET,
        AllStatutDossier.ATTENTE_REALISATION_TRAVAUX,
        AllStatutDossier.ATTENTE_DOCUMENTS,
        AllStatutDossier.TRAVAUX_REALISES_ATTENTE_FACTURE_FOURNISSEUR,
        AllStatutDossier.ANNULE,
        AllStatutDossier.ERREUR_DEMANDE,
        AllStatutDossier.AUTRE,
        AllStatutDossier.PROBLEME_TECHNIQUE,
      ],
    };
    return _defaultFiltres;
  }, [user.id]);
  const [filtres, setFiltres] = useState<ChantiersSearchFiltre>(defaultFiltres);

  useEffect(() => {
    async function getAllChantiers(): Promise<void> {
      const result = await chantierService.getAllChantiers(filtres);
      // On filtre pour retirer les chantiers de la recherche qui n'ont pas de dossiers
      const chantiersFiltres = result.map((chantier) => {
        if (getDossiers(chantier).length === 0) {
          return undefined;
        }
        return chantier;
      });
      setChantiers(chantiersFiltres.filter(Boolean) as Array<Chantier>);
    }

    void catchErrors(getAllChantiers);
  }, [catchErrors, filtres]);

  const data: ChantiersSearchData = useMemo(() => {
    return {
      isLoading,
      chantiers,
      filtres,
      defaultFiltres,
      setFiltres,
    };
  }, [isLoading, chantiers, filtres, defaultFiltres, setFiltres]);

  return <ChantiersSearchContext.Provider value={data}>{children}</ChantiersSearchContext.Provider>;
}
