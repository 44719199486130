import type { ReactNode } from "react";
import type { Demandeur } from "models";

interface DemandeurSectionProps {
  demandeur?: Demandeur;
}

function DemandeurSection({ demandeur }: Readonly<DemandeurSectionProps>): ReactNode {
  if (demandeur == null) {
    return null;
  }
  return (
    <>
      {demandeur.prenom} {demandeur.nom?.toLocaleUpperCase()} <br />
      {demandeur.adresseNumero}
      {demandeur.adresseRue} <br />
      {demandeur.adresseCodePostal} {demandeur.adresseCommune}
    </>
  );
}

export default DemandeurSection;
