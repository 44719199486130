import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import { TypeDossier } from "models";
import type { Dossier } from "models";

function getLabelByTypeDossier({ type }: Dossier): string | undefined {
  switch (type) {
    case TypeDossier.ASS:
      return "Assainissement";
    case TypeDossier.EAU:
      return "Eau Potable";
    case TypeDossier.CIPA:
      return "CIPA";
    case TypeDossier.PAC:
      return "PAC";
    default:
      return undefined;
  }
}

interface DossierTabLabelProps {
  dossier: Dossier;
}

function DossierTabLabel({ dossier }: Readonly<DossierTabLabelProps>): ReactNode {
  return (
    <Typography
      color="inherit"
      fontSize="inherit"
      fontWeight="inherit"
      lineHeight="20px"
      sx={{ ml: 2 }}>
      <Typography
        component="span"
        color="inherit"
        fontSize="1.2rem"
        fontWeight="600"
        lineHeight="20px">
        {dossier?.reference}
      </Typography>
      {dossier?.reference != null && <br />}
      {getLabelByTypeDossier(dossier)}
    </Typography>
  );
}

export default DossierTabLabel;
