import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { Grid2Props as GridProps } from "@mui/material";
import type { PropsWithChildren, ReactNode } from "react";

type FormSectionProps = GridProps &
  PropsWithChildren & {
    title?: string;
    noBackground?: boolean;
  };

export function FormSection({
  title,
  noBackground = false,
  children,
  ...gridProps
}: Readonly<FormSectionProps>): ReactNode {
  return (
    <Grid container alignContent="flex-start" size={12} {...gridProps}>
      <Grid
        container
        sx={{
          borderRadius: 1,
          backgroundColor: noBackground ? "none" : "white",
          p: 2,
        }}
        size={12}>
        <Grid container spacing={2} size="grow">
          {title != null && (
            <Typography component={Grid} size={12} color="primary" variant="body1" fontWeight={600}>
              {title}
            </Typography>
          )}
          <Grid container spacing={2} size={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
