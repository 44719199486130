import type { ReactNode } from "react";
import { TextField, MenuItem } from "@mui/material";
import type { TextFieldProps } from "@mui/material";
import { TypeDossier } from "models";
import DossierChip from "./DossierChip";

const allTypesDossier = Object.keys(TypeDossier)
  .map((t) => t as TypeDossier)
  .sort((a, b) => a.localeCompare(b));

type TypeDossierSelectProps = TextFieldProps & {
  value?: TypeDossier | "";
  options?: Array<TypeDossier | string>;
  label: string;
};

function TypeDossierSelect({
  value,
  options = allTypesDossier,
  label = "Type de demande",
  ...textFieldProps
}: Readonly<TypeDossierSelectProps>): ReactNode {
  return (
    <TextField select label="Type de demande" value={value ?? ""} {...textFieldProps}>
      <MenuItem value="">
        <em>Tous</em>
      </MenuItem>
      {options.map((key) => (
        <MenuItem key={key} value={key}>
          <DossierChip type={key as TypeDossier} iconSize="small" />
        </MenuItem>
      ))}
    </TextField>
  );
}

export default TypeDossierSelect;
