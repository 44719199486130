import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { SdeappsErrorNames } from "./SdeappsError";
import type { SdeappsError } from "./SdeappsError";
import errorsUtil from "./errorsUtil";
import { ErrorBoundary } from "react-error-boundary";
import type { FallbackProps } from "react-error-boundary";
import { useLocation } from "react-router-dom";
import ErrorComponent from "./components/ErrorComponent";
import { ErrorPage, ForbiddenErrorPage, NotFoundErrorPage, ServerErrorPage } from ".";

type Element<P> = (props: Readonly<P>) => ReactNode;

interface ErrorBoundaryOption {
  fallback: (error: SdeappsError) => ReactNode;
}

type ErrorBoundaryOptions = Partial<
  Record<"override" | "default" | SdeappsErrorNames, ErrorBoundaryOption>
>;

/** Options de `withErrorBoundary` par défaut pour la gestion des erreurs des **pages** */
const pageErrorDefaultBoundaryOptions: ErrorBoundaryOptions = {
  [SdeappsErrorNames.NotFound]: {
    fallback: (e) => <NotFoundErrorPage message={e.message} error={e} />,
  },
  [SdeappsErrorNames.Forbidden]: {
    fallback: (e) => <ForbiddenErrorPage message={e.message} error={e} />,
  },
  [SdeappsErrorNames.ServerError]: {
    fallback: (e) => <ServerErrorPage message={e.message} error={e} />,
  },
  default: {
    fallback: (e) => (
      <ErrorPage
        title={e?.status?.toString()}
        subtitle={e?.statusText}
        message={e?.description ?? e.message}
        error={e}
      />
    ),
  },
};

/** Options de `withErrorBoundary` par défaut pour la gestion des erreurs des **composants** */
const componentErrorDefaultBoundaryOptions: ErrorBoundaryOptions = {
  default: {
    fallback: (_) => <ErrorComponent />,
  },
};

/**
 * **Error Boundary générique** (HOC)
 *
 * Entoure `WrappedComponent` d'une **Error Boundary** qui catche les erreurs de son enfant,
 * les analyse, et remplace ou non `WrappedComponent` par un composant de fallback selon les
 * règles précisées par `errorBoundaryOptions`.
 *
 * Lire la [Documentation et Exemples](https://dev.azure.com/SDEA/SDEApps/_wiki/wikis/Ing%C3%A9nieur-DevOps/1891/Gestion-des-erreurs-en-React#).
 * @param WrappedComponent le composant à entourer de l'Error Boundary.
 * @param errorBoundaryOptions un objet décrivant les différents composants de fallback en
 * fonction du type d'erreur catchée.
 * @returns Le `WrappedComponent` entouré d'une ErrorBoundary, ou si une erreur a été catchée,
 * le composant de fallback correspondant.
 */
export function withErrorBoundary<P>(
  WrappedComponent: Element<P>,
  errorBoundaryOptions?: ErrorBoundaryOptions
): Element<P> {
  function Fallback({ error, resetErrorBoundary }: Readonly<FallbackProps>): ReactNode {
    const location = useLocation();
    const [navCount, setNavCount] = useState(0);
    const [sdeappsError, setSdeappsError] = useState<SdeappsError>(
      errorsUtil.getSdeappsErrorFromUnknown(error)
    );

    useEffect(() => {
      if (navCount !== 0) {
        console.warn("resetting error boundary because of NAVIGATION");
        resetErrorBoundary();
      }
      setNavCount((count) => count + 1);
      // navCount n'est pas une dépendance; risque de boucle infinie
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, resetErrorBoundary]);

    useEffect(() => {
      setSdeappsError(errorsUtil.getSdeappsErrorFromUnknown(error));
    }, [error]);

    if (errorBoundaryOptions?.override != null) {
      // eslint-disable-next-line no-console
      console.log("ERROR BOUNDARY override found", sdeappsError.originalError ?? sdeappsError);
      return errorBoundaryOptions.override.fallback(sdeappsError);
    } else if (errorBoundaryOptions?.[sdeappsError.name as keyof ErrorBoundaryOptions] != null) {
      // eslint-disable-next-line no-console
      console.log(
        "ERROR BOUNDARY corresponding error found",
        sdeappsError.originalError ?? sdeappsError
      );
      return errorBoundaryOptions?.[sdeappsError.name as keyof ErrorBoundaryOptions]?.fallback(
        sdeappsError
      );
    } else if (errorBoundaryOptions?.default != null) {
      // eslint-disable-next-line no-console
      console.log("ERROR BOUNDARY default found", sdeappsError.originalError ?? sdeappsError);
      return errorBoundaryOptions.default.fallback(sdeappsError);
    }

    return (
      <div>
        <p>ERREUR</p>
        <button onClick={resetErrorBoundary}>Réessayer</button>
      </div>
    );
  }

  return function WrappedComponentWithErrorBoundary(props: Readonly<P>): ReactNode {
    return (
      <ErrorBoundary FallbackComponent={Fallback}>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };
}

/**
 * **Error Boundary spécifique aux Pages** (HOC)
 *
 * Entoure `WrappedPage` d'une **Error Boundary** qui catche les erreurs de son enfant,
 * les analyse, et remplace ou non `WrappedPage` par une page de fallback selon les
 * règles par défaut de gestion des erreurs de page, surchagées par `pageErrorBoundaryOptions`.
 *
 * Lire la [Documentation et Exemples](https://dev.azure.com/SDEA/SDEApps/_wiki/wikis/Ing%C3%A9nieur-DevOps/1891/Gestion-des-erreurs-en-React#).
 *
 * _Utilise {@link withErrorBoundary} avec des paramètres par défaut supplémentaires_
 * @param WrappedPage La page à entourer de l'Error Boundary.
 * @param pageErrorBoundaryOptions un objet décrivant les différents composants de fallback en
 * fonction du type d'erreur catchée, qui override les valeurs par défaut de la gestion des
 * erreurs de page.
 * @returns La `WrappedPage` entouré d'une ErrorBoundary, ou si une erreur a été catchée,
 * la page de fallback correspondant.
 */
export function withPageErrorBoundary<P>(
  WrappedPage: Element<P>,
  pageErrorBoundaryOptions?: ErrorBoundaryOptions
): Element<P> {
  return withErrorBoundary(WrappedPage, {
    ...pageErrorDefaultBoundaryOptions,
    ...pageErrorBoundaryOptions,
  });
}

/**
 * **Error Boundary spécifique aux Composants internes des pages** (HOC)
 *
 * Entoure `WrappedComponent` d'une **Error Boundary** qui catche les erreurs de son enfant,
 * les analyse, et remplace ou non `WrappedComponent` par un composant de fallback selon les
 * règles par défaut de gestion des erreurs de composant, surchagées par `componentErrorBoundaryOptions`.
 *
 * Lire la [Documentation et Exemples](https://dev.azure.com/SDEA/SDEApps/_wiki/wikis/Ing%C3%A9nieur-DevOps/1891/Gestion-des-erreurs-en-React#).
 *
 * _Utilise {@link withErrorBoundary} avec des paramètres par défaut supplémentaires_
 * @param WrappedComponent le composant à entourer de l'Error Boundary.
 * @param componentErrorBoundaryOptions un objet décrivant les différents composants de fallback en
 * fonction du type d'erreur catchée, qui override les valeurs par défaut de la gestion des
 * erreurs de composants.
 * @returns Le `WrappedComponent` entouré d'une ErrorBoundary, ou si une erreur a été catchée,
 * le composant de fallback correspondant.
 */
export function withComponentErrorBoundary<P>(
  WrappedComponent: Element<P>,
  componentErrorBoundaryOptions?: ErrorBoundaryOptions
): Element<P> {
  return withErrorBoundary(WrappedComponent, {
    ...componentErrorDefaultBoundaryOptions,
    ...componentErrorBoundaryOptions,
  });
}
