import { createSvgIcon } from "@mui/material";

export const AssainissementIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M9.55417 8.07044V7.23034H11.2344V11.4309H9.55417V10.5908H5.35365C4.90803 10.5908 4.48066 10.4137 4.16556 10.0986C3.85046 9.78353 3.67344 9.35617 3.67344 8.91055V6.18021H2.83333V4.5H7.03385V6.18021H6.19375V8.07044H9.55417Z" />
    <path d="M14.1679 10.6958V11.5359H12.4877L12.4877 7.33537L14.1679 7.33537V8.17548L18.3684 8.17548C18.8141 8.17548 19.2414 8.3525 19.5565 8.6676C19.8716 8.9827 20.0486 9.41007 20.0486 9.85569V12.586H20.8888V14.2662L16.6882 14.2662V12.586L17.5283 12.586V10.6958L14.1679 10.6958Z" />
    <path d="M 14.204 15.855 C 14.204 15.959 14.112 16.044 13.999 16.044 C 13.886 16.044 13.794 15.959 13.794 15.855 C 13.794 15.751 13.886 15.666 13.999 15.666 C 14.112 15.666 14.204 15.751 14.204 15.855 Z M 14.025 16.58 C 13.944 16.784 13.768 17.037 13.532 17.259 C 13.299 17.479 12.679 17.814 12.679 17.814 C 12.669 17.822 12.491 17.548 12.487 17.549 C 12.489 17.548 12.657 17.472 12.732 17.431 C 12.807 17.39 12.875 17.349 12.944 17.304 C 13.081 17.213 13.198 17.119 13.305 17.019 C 13.517 16.819 13.655 16.628 13.717 16.461 Z M 12 12 C 10.076 14.208 9.355 14.804 9.355 16.085 C 9.355 17.365 10.539 18.403 12 18.403 C 13.461 18.403 14.645 17.365 14.645 16.085 C 14.645 14.804 12.962 13.214 12 12 Z" />
  </svg>,
  "AssainissementIcon"
);
