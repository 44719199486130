import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { graphService } from "services";
import { SkeletonInput } from "components/Loading";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import type { FieldError } from "react-hook-form";
import type { Technicien } from "models";

function getInitials(name: string): string {
  return name
    .trim()
    .split(" ")
    .map((n) => n[0])
    .join("");
}

interface TechnicienAutoCompleteProps {
  libelle?: string;
  technicienId?: string;
  onChange: (technicien?: Technicien) => void;
  error?: FieldError;
}
function TechnicienAutoComplete({
  libelle,
  technicienId,
  onChange,
  error,
}: Readonly<TechnicienAutoCompleteProps>): ReactNode {
  const [techniciens, setTechniciens] = useState<Array<Technicien>>([]);
  const [selectedTechnicien, setSelectedTechnicien] = useState<Technicien | undefined>();
  const [avatars, setAvatars] = useState<Map<string, string>>(new Map());

  const { catchErrors, isLoading } = useErrorHandler();
  const { catchErrors: catchAvatarErrors } = useErrorHandler({
    dontThrow: true,
  });

  useEffect(() => {
    void catchErrors(async () => {
      const availableTechniciens = await graphService.getAllTechniciens();
      setTechniciens(availableTechniciens ?? []);
    });
  }, [catchErrors]);

  useEffect(() => {
    async function getAvatars(): Promise<void> {
      const settledPromises = await Promise.allSettled(
        techniciens.map(async (tech) => [tech.id, await graphService.getUserPhoto(tech.id)])
      );

      const availableAvatars = settledPromises.reduce<Map<string, string>>(
        (acc, settledPromise) => {
          if (settledPromise.status === "fulfilled") {
            const [id, image] = settledPromise.value;
            acc.set(id, image);
          }
          return acc;
        },
        new Map()
      );

      setAvatars(availableAvatars);
    }

    void catchAvatarErrors(getAvatars);
  }, [catchAvatarErrors, techniciens]);

  useEffect(() => {
    setSelectedTechnicien(techniciens.find((tech) => tech.id === technicienId));
  }, [techniciens, technicienId]);

  if (isLoading) {
    return <SkeletonInput size={12} />;
  }

  return (
    <FormControl fullWidth>
      <Autocomplete
        disablePortal
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={libelle ?? "Technicien"}
            placeholder={libelle ?? "Technicien"}
          />
        )}
        value={selectedTechnicien ?? null}
        onChange={(_, newValue) => {
          const selectedTechnicien = newValue ?? undefined;
          setSelectedTechnicien(selectedTechnicien);
          onChange(selectedTechnicien);
        }}
        options={techniciens}
        renderOption={(props, option: Technicien) => (
          <Box component="li" {...props} key={option.id}>
            <Grid container spacing={2} alignItems="center">
              <Grid size="auto">
                <Avatar alt={option.displayName} src={avatars.get(option.id)}>
                  {getInitials(option.displayName)}
                </Avatar>
              </Grid>
              <Grid size="grow">
                <Typography>{option.displayName}</Typography>
                {option.jobTitle != null && (
                  <Typography variant="caption">{option.jobTitle}</Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
        getOptionLabel={(option: Technicien) => option.displayName}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
      <FormHelperText error={error != null}>{error?.message}</FormHelperText>
    </FormControl>
  );
}

const TechnicienAutoCompleteWithErrorBoundary = withComponentErrorBoundary(TechnicienAutoComplete);

export default TechnicienAutoCompleteWithErrorBoundary;
