import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import type { Adresse } from "models";

interface ComplementsDAdresseProps {
  adresse: Adresse;
}

function ComplementsDAdresse({
  adresse: { lotissement, routeDepartementale, lot, parcelle, section },
}: Readonly<ComplementsDAdresseProps>): ReactNode {
  const [lotissementAndRouteDepartementale, setLotissementAndRouteDepartementale] =
    useState<string>("");
  const [lotParcelleSection, setLotParcelleSection] = useState<string>("");

  useEffect(() => {
    const lotiroutdep: Array<string> = [];
    const lotparcsect: Array<string> = [];

    if (lotissement != null && lotissement !== "") {
      lotiroutdep.push(`Lotissement ${lotissement}`);
    }
    if (routeDepartementale != null && routeDepartementale !== "") {
      lotiroutdep.push(`Route départementale ${routeDepartementale}`);
    }
    setLotissementAndRouteDepartementale(lotiroutdep.join(" - "));

    if (lot != null && lot !== "") {
      lotparcsect.push(`Lot ${lot}`);
    }
    if (parcelle != null && parcelle !== "") {
      lotparcsect.push(`Parcelle ${parcelle}`);
    }
    if (section != null && section !== "") {
      lotparcsect.push(`Section ${section}`);
    }
    setLotParcelleSection(lotparcsect.join(" - "));
  }, [lotissement, routeDepartementale, lot, parcelle, section]);

  if (lotissementAndRouteDepartementale.length === 0 && lotParcelleSection.length === 0)
    return null;

  return (
    <>
      <Typography>{lotissementAndRouteDepartementale}</Typography>
      <Typography>{lotParcelleSection}</Typography>
    </>
  );
}

export default ComplementsDAdresse;
