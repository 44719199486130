import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { ReactNode } from "react";

export function LoadingScreen(): ReactNode {
  return (
    <Grid container sx={{ flexGrow: 1 }} justifyContent="center">
      <Grid sx={{ marginY: "20vh" }}>
        <CircularProgress size="15vh" />
      </Grid>
    </Grid>
  );
}
