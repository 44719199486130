import { Endpoints } from "enums";
import type {
  CalculAspectFinancierAss,
  CalculAspectFinancierEau,
  CalculAspectFinancierPac,
} from "models";
import { opaleApiClient } from "./apiClients";

async function postCalculAspectFinancierEauPotable(
  data: CalculAspectFinancierEau
): Promise<CalculAspectFinancierEau> {
  const url = `/${Endpoints.CALCUL_ASPECT_FINANCIER_EAU_POTABLE}`;
  return opaleApiClient.post<CalculAspectFinancierEau, CalculAspectFinancierEau>(url, data);
}

async function postCalculAspectFinancierAssainissement(
  data: CalculAspectFinancierAss
): Promise<CalculAspectFinancierAss> {
  const url = `/${Endpoints.CALCUL_ASPECT_FINANCIER_ASSAINISSEMENT}`;
  return opaleApiClient.post<CalculAspectFinancierEau, CalculAspectFinancierAss>(url, data);
}

async function postCalculAspectFinancierPac(
  data: CalculAspectFinancierPac
): Promise<CalculAspectFinancierPac> {
  const url = `/${Endpoints.CALCUL_ASPECT_FINANCIER_PAC}`;
  return opaleApiClient.post<CalculAspectFinancierEau, CalculAspectFinancierPac>(url, data);
}

export const calculService = {
  postCalculAspectFinancierAssainissement,
  postCalculAspectFinancierEauPotable,
  postCalculAspectFinancierPac,
};
