import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { fournisseurs } from "data/fournisseurs";
import type { Fournisseur } from "models";
import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import type { FieldError } from "react-hook-form";
import { withComponentErrorBoundary } from "utils/errorHandling";

interface FournisseurAutoCompleteProps {
  fournisseurId: string | undefined;
  onChange: (fournisseur?: Fournisseur) => void;
  label: string;
  error?: FieldError;
}

function FournisseurAutoComplete({
  fournisseurId,
  onChange,
  label,
  error,
}: Readonly<FournisseurAutoCompleteProps>): ReactNode {
  const [selectedFournisseur, setSelectedFournisseur] = useState<Fournisseur | undefined>();

  useEffect(() => {
    setSelectedFournisseur(fournisseurs.find((fournisseur) => fournisseur.id === fournisseurId));
  }, [fournisseurId]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        disablePortal
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ?? "Fournisseur"}
            placeholder={label ?? "Fournisseur"}
          />
        )}
        value={selectedFournisseur ?? null}
        onChange={(_, newValue) => {
          const selectedFournisseur = newValue ?? undefined;
          setSelectedFournisseur(selectedFournisseur);
          onChange(selectedFournisseur);
        }}
        options={fournisseurs}
        renderOption={(props, option: Fournisseur) => (
          <Box component="li" {...props} key={option.id}>
            <Typography>{option.libelle}</Typography>
          </Box>
        )}
        getOptionLabel={(option) => option.libelle}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        autoHighlight
        openOnFocus
        selectOnFocus
      />
      <FormHelperText error={error != null}>{error?.message}</FormHelperText>
    </FormControl>
  );
}

const FournisseurAutoCompleteWithErrorBoundary =
  withComponentErrorBoundary(FournisseurAutoComplete);

export default FournisseurAutoCompleteWithErrorBoundary;
