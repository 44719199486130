import type { ReactNode } from "react";
import type { Demandeur } from "models";
import { Link, Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const SEPARATEUR = " ● ";

interface DemandeurLineProps {
  demandeur?: Demandeur;
}

function DemandeurLine({ demandeur }: Readonly<DemandeurLineProps>): ReactNode {
  if (demandeur == null) return null;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <PersonIcon sx={{ display: "inline-block" }} />
      <Typography>
        <Typography color="primary" component="span">
          {demandeur.nom?.toLocaleUpperCase()} {demandeur.prenom}
        </Typography>
        {SEPARATEUR}Id SAP : {demandeur.codeSap}
        {demandeur.telephone != null && (
          <>
            {SEPARATEUR} <Link href={`tel:${demandeur.telephone}`}>{demandeur.telephone}</Link>
          </>
        )}
        {demandeur.email != null && (
          <>
            {SEPARATEUR} <Link href={`mailto:${demandeur.email}`}>{demandeur.email}</Link>
          </>
        )}
      </Typography>
    </Stack>
  );
}

export default DemandeurLine;
