import { Button, Collapse, Container, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { SxProps } from "@mui/material";
import { useState } from "react";
import type { ReactNode } from "react";
import { ToastMessages } from "enums";
import type { ErrorPageProps } from ".";

const marginTopSx: SxProps = { marginTop: { xs: 4, md: 8 } };

function goToHome(): void {
  window.location.href = window.location.origin;
}

interface BaseErrorPageProps extends ErrorPageProps {
  reset?: VoidFunction;
}

/**
 * Composant d'erreur générique à utiliser en fallback lorsqu'une page entière est en erreur
 * (ie. la ressource principale de la page est inaccessible et la totalité de la page est inutilisable).
 */
export function BaseErrorPage({
  title,
  subtitle,
  message,
  image,
  error,
  reset,
}: Readonly<BaseErrorPageProps>): ReactNode {
  const [isStackIn, setIsStackIn] = useState(false);

  return (
    <Container maxWidth={false} sx={{ background: "white" }}>
      <Grid
        container
        columns={{ xs: 4, md: 8, xl: 12 }}
        spacing={3}
        direction="row-reverse"
        alignItems="flex-start"
        justifyContent="center">
        {image != null && (
          <Grid size={{ xs: 4, md: 5, xl: 6 }}>
            <img
              style={{ objectFit: "contain", width: "100%" }}
              alt="error illustration"
              src={image}
            />
          </Grid>
        )}
        <Grid container spacing={3} size={image != null ? { xs: 10, md: 3, xl: 4 } : { xs: 12 }}>
          <Grid size={12}>
            <Typography variant="h1" sx={marginTopSx}>
              {title ?? ":'("}
            </Typography>
            <Typography sx={marginTopSx}>{subtitle ?? ToastMessages.ERROR}</Typography>
          </Grid>
          <Grid size={12}>
            <Typography>{message}</Typography>
          </Grid>
          <Grid sx={marginTopSx} size={12}>
            <Button variant="contained" onClick={goToHome}>
              Retour à l’accueil
            </Button>
          </Grid>
          {reset != null && (
            <Grid sx={marginTopSx} size={12}>
              <Button variant="outlined" onClick={reset}>
                Réessayer
              </Button>
            </Grid>
          )}
          {error != null && (
            <Grid size={12}>
              <Button
                variant="outlined"
                onClick={() => {
                  setIsStackIn(!isStackIn);
                }}>
                En savoir {isStackIn ? "moins" : "plus"}
              </Button>
            </Grid>
          )}
        </Grid>
        {error != null && (
          <Grid size={12}>
            <Collapse in={isStackIn}>
              <Grid container spacing={2}>
                <Grid size={12}>
                  <Typography>
                    Si vous pensez que ce comportement n'est pas normal et qu'il s'agit d'un bug,
                    merci de bien vouloir copier les informations ci-dessous dans votre ticket
                    Gestsup :
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <Divider />
                </Grid>
                <Grid size={12}>
                  <Typography variant="caption">Page: {window.location.href}</Typography>
                </Grid>
                <Grid size={12}>
                  <Typography variant="caption">
                    {error?.name}
                    <br />
                    {error?.originalError?.name} {error?.status} {error?.statusText}
                    <br />
                    {error?.description}
                    <br />
                    {message}
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <Typography variant="caption">{error?.stack}</Typography>
                </Grid>
                <Grid size={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
