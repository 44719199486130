import type { Commune, Perimetre, Transfert } from "models";
import { Endpoints } from "enums";
import { collectivitesApiClient } from "./apiClients";

/**
 * Récupère toutes les communes et les retourne triées par nom
 * @returns Array<Commune>
 */
async function getAll(): Promise<Array<Commune>> {
  const url = `/${Endpoints.COMMUNES}`;
  const data = await collectivitesApiClient<Array<Commune>, Array<Commune>>(url);
  return data
    .map<Commune>(({ id, libelle }) => ({ id, libelle }))
    .sort((a, b) => {
      return a.libelle.localeCompare(b.libelle);
    });
}

/**
 * Récupère tous les transferts d'une commune et les retournes tels que l'API répond.
 * @param codeInsee Code INSEE de la commune
 * @returns Array<Transfert>
 */
async function getAllTransfertsByCommune(codeInsee: string): Promise<Array<Transfert>> {
  const url = `/${Endpoints.COMMUNES}/${codeInsee}/transferts`;
  return collectivitesApiClient<Array<Transfert>, Array<Transfert>>(url);
}

/**
 * Récupère tous les périmètres d'une commune et les retournes tels que l'API répond.
 * @param codeInsee Code INSEE de la commune
 * @returns Array<Perimetre>
 */
async function getAllPerimetresByCommune(codeInsee: string): Promise<Array<Perimetre>> {
  const url = `/${Endpoints.COMMUNES}/${codeInsee}/perimetres`;
  return collectivitesApiClient<Array<Perimetre>, Array<Perimetre>>(url);
}

export const communesService = {
  getAll,
  getAllTransfertsByCommune,
  getAllPerimetresByCommune,
};
