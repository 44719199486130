import { type ReactNode } from "react";
import Grid from "@mui/material/Grid2";
import type { Chantier, CourrierMetadata, DossierAss, DossierCipa, DossierEau } from "models";
import { FormSection } from "components/Layout";
import { LoadingScreen } from "components/Loading";
import { grey } from "@mui/material/colors";
import { Link, TextField } from "@mui/material";
import { useDestinataireCopie } from "pages/Chantier/hooks/useDestinataireCopie";
import { filesService } from "services";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { DocumentGenerationButton } from "./DocumentGenerationButton";

interface DossierCourriersFormProps {
  chantier?: Chantier;
  dossier?: DossierAss | DossierEau | DossierCipa;
  courriersMetadata?: Array<CourrierMetadata>;
  isLoading: boolean;
}

export function DossierCourriersForm({
  chantier,
  dossier,
  courriersMetadata,
  isLoading,
}: Readonly<DossierCourriersFormProps>): ReactNode {
  const { destinataireCopie, patchDestinataireCopie, onDestinataireCopieChange } =
    useDestinataireCopie(chantier, dossier);

  if (dossier == null || chantier == null || isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Grid container spacing={2} sx={{ p: 2, background: grey[200] }} size={12}>
      <FormSection title="Générer un courrier" size={{ xs: 12, lg: 6 }} container>
        <Grid size={12}>
          <TextField
            label="Copie à"
            value={destinataireCopie ?? ""}
            onChange={onDestinataireCopieChange}
            fullWidth
          />
        </Grid>
        <Grid container spacing={0} size={12}>
          {courriersMetadata?.map((courrierMetaData) => (
            <Grid key={courrierMetaData.type} size={12}>
              <DocumentGenerationButton
                courrierMetaData={courrierMetaData}
                chantier={chantier}
                dossier={dossier}
                beforeGeneration={patchDestinataireCopie}
              />
            </Grid>
          ))}
        </Grid>
      </FormSection>

      <FormSection title="Sharepoint" size={{ xs: 12, lg: 6 }} container>
        <Grid container size={12}>
          <Link href={filesService.getChantierFolderUrl(chantier)} target="_blank">
            Ouvrir le répertoire <b>CHANTIER-{chantier.reference}</b> dans Sharepoint
            <OpenInNewIcon sx={{ fontSize: 10, ml: 0.5, verticalAlign: "top" }} />
          </Link>
        </Grid>
        <Grid container size={12}>
          <Link href={filesService.getDossierFolderUrl(dossier, chantier)} target="_blank">
            Ouvrir le répertoire <b>{dossier.folderName}</b> dans Sharepoint
            <OpenInNewIcon sx={{ fontSize: 10, ml: 0.5, verticalAlign: "top" }} />
          </Link>
        </Grid>
      </FormSection>
    </Grid>
  );
}
