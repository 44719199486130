import type { ReactNode } from "react";
import { TypeDossier } from "models";
import { AssainissementIcon, EauPotableIcon, CipaIcon } from "icons";
import type { SvgIconProps } from "@mui/material";
import EuroIcon from "@mui/icons-material/Euro";

interface TypeDossierIconProps {
  type: TypeDossier;
  size?: SvgIconProps["fontSize"];
}

export function TypeDossierIcon({
  type,
  size = "medium",
}: Readonly<TypeDossierIconProps>): ReactNode {
  switch (type) {
    case TypeDossier.ASS:
      return <AssainissementIcon fontSize={size} />;
    case TypeDossier.EAU:
      return <EauPotableIcon fontSize={size} />;
    case TypeDossier.CIPA:
      return <CipaIcon fontSize={size} />;
    case TypeDossier.PAC:
      return <EuroIcon fontSize={size} />;
    default:
      return undefined;
  }
}
