import { createContext } from "react";
import type { Chantier, ChantiersSearchFiltre } from "models";

export type ChantiersSearchData = {
  isLoading: boolean;
  chantiers: Array<Chantier>;
  filtres: ChantiersSearchFiltre;
  defaultFiltres: ChantiersSearchFiltre;
  setFiltres: React.Dispatch<React.SetStateAction<ChantiersSearchFiltre>>;
};

export const ChantiersSearchContext = createContext<ChantiersSearchData>({
  isLoading: true,
  chantiers: [],
  filtres: { statuts: [] },
  defaultFiltres: { statuts: [] },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFiltres: () => {},
});
