/**
 * Permet d'ouvrir le lien dans l'application Word desktop en modification
 *
 * `ms-word:` open in Microsoft Word - `ofe|u|` edit
 *
 * https://learn.microsoft.com/en-us/office/client-developer/office-uri-schemes#edit-document
 * https://learn.microsoft.com/en-us/office/troubleshoot/word/force-word-document-to-open-in-edit-mode
 */
const wordDesktopAppPrefix = "ms-word:ofe|u|";

function openSharepointFileInBrowser(fileUrl: string): void {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.target = "_blank";
  link.click();
  link.remove();
}

function openSharepointFileInDesktopApp(fileUrl: string): void {
  const link = document.createElement("a");
  link.href = getOpenSharepointFileInDesktopAppLink(fileUrl);
  link.click();
  link.remove();
}

function getOpenSharepointFileInDesktopAppLink(fileUrl: string): string {
  return `${wordDesktopAppPrefix}${fileUrl}`;
}

export const sharepointService = {
  openSharepointFileInBrowser,
  openSharepointFileInDesktopApp,
  getOpenSharepointFileInDesktopAppLink,
};
