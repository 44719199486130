import { createContext, useContext, useMemo } from "react";
import type { PropsWithChildren, ReactNode } from "react";
import type { Dossier } from "models";

export interface DossierContextData<T = Dossier> {
  dossier: T | undefined;
  isLoading: boolean;
  updateDossier: () => void;
}

const data: DossierContextData = {
  dossier: undefined,
  isLoading: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateDossier: () => {},
};

const DossierContext = createContext(data);

type DossierProviderProps<T = Dossier> = DossierContextData<T> & PropsWithChildren;

export function DossierProvider<T extends Dossier>({
  dossier,
  isLoading = false,
  updateDossier,
  children,
}: Readonly<DossierProviderProps<T>>): ReactNode {
  const data: DossierContextData<T> = useMemo(() => {
    return {
      dossier,
      isLoading,
      updateDossier,
    };
  }, [dossier, isLoading, updateDossier]);

  return <DossierContext.Provider value={data}>{children}</DossierContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useDossier<T = Dossier>(): DossierContextData<T> {
  const context = useContext(DossierContext);

  if (context == null) {
    throw new Error("useDossier must be used within a DossierProvider");
  }

  return context as DossierContextData<T>;
}
