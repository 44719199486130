import type { ReactNode } from "react";
import type { SdeappsError } from "utils/errorHandling/SdeappsError";
import { useErrorBoundary } from "react-error-boundary";
import { BaseErrorPage } from ".";

export interface ErrorPageProps {
  title?: string;
  subtitle?: string;
  message?: string;
  image?: string;
  error?: SdeappsError;
}

/**
 * Composant d'erreur générique à utiliser en fallback lorsqu'une page entière est en erreur
 * (ie. la ressource principale de la page est inaccessible et la totalité de la page est inutilisable).
 *
 * A utiliser en fallback dans un `withErrorBoundary()` ou entouré d'une `<ErrorBoundary />` sinon
 * il crashe, car on apelle `useErrorBoundary()` qui a besoin d'être entouré d'une `<ErrorBoundary />`.
 * Dans le cas contraire, veuillez utiliser plutôt **`<BaseErrorPage />`**
 *
 * Utilisé par défaut par `withPageErrorBoundary()`. voir la [Documentation de notre lib de Gestion des Erreurs](https://dev.azure.com/SDEA/SDEApps/_wiki/wikis/Ing%C3%A9nieur-DevOps/1891/Gestion-des-erreurs-en-React#).
 */
export function ErrorPage(props: Readonly<ErrorPageProps>): ReactNode {
  const { resetBoundary } = useErrorBoundary();

  return <BaseErrorPage {...props} reset={resetBoundary} />;
}
