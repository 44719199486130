import { opaleApiClient } from "./apiClients";
import { apiConfig } from "config/app-config";
import { authenticationService } from "@sdeapps/react-core";
import type { Chantier, CourrierType, Dossier } from "models";
import type { AxiosRequestConfig } from "axios";
import { filesService } from "./filesService";

interface CourrierGenerationPayload {
  template: string;
  documentType: string;
  dossierId: string;
  saveDirectory: string;
  generatedFileName: string;
}

/**
 * Génère un courrier.
 * @param documentType Type de courrier à générer
 * @param template Nom du fichier de template de courrier à utiliser
 * @param chantierReference Référence du chantier pour stocker le fichier généré
 * @param dossier Dossier utilisé pour la génération du courrier
 * @returns un objet contenant l'url qui permet d'ouvrir le fichier généré dans sharepoint, ainsi que le nom du fichier généré
 */
async function genererCourrier(
  documentType: CourrierType,
  template: string,
  chantier: Chantier,
  dossier: Dossier
): Promise<{ url: string; generatedFileName: string }> {
  const url = `/documents/generate`;

  const payload: CourrierGenerationPayload = {
    template,
    documentType,
    dossierId: dossier.id,
    saveDirectory: filesService.getCourrierFolderPath(dossier, chantier),
    generatedFileName: `${dossier.reference}_${template}`,
  };

  const accessToken = await authenticationService.getAccessToken(apiConfig.azureAd.scopes);
  const headers: AxiosRequestConfig<CourrierGenerationPayload> = {
    headers: {
      "x-ms-token-aad-id-token": `Bearer ${accessToken}`,
    },
  };
  const { url: sharePointUrl } = await opaleApiClient.post<
    CourrierGenerationPayload,
    { url: string }
  >(url, payload, headers);
  return {
    url: sharePointUrl,
    generatedFileName: payload.generatedFileName,
  };
}

export const courriersService = {
  genererCourrier,
};
