import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { Grid2Props as GridProps } from "@mui/material";
import type { PropsWithChildren, ReactNode } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type AccordionFormSectionProps = GridProps &
  PropsWithChildren & {
    title?: string;
    noBackground?: boolean;
    expanded?: boolean;
    defaultExpanded?: boolean;
  };

export function AccordionFormSection({
  title,
  noBackground = false,
  children,
  expanded,
  defaultExpanded,
  ...gridProps
}: Readonly<AccordionFormSectionProps>): ReactNode {
  return (
    <Grid container alignContent="flex-start" size={12} {...gridProps}>
      <Accordion
        sx={{
          borderRadius: 1,
          backgroundColor: noBackground ? "none" : "white",
          width: "100%",
        }}
        expanded={expanded}
        defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} component={Grid} container>
          {title != null && (
            <Typography color="primary" variant="body1" fontWeight={600}>
              {title}
            </Typography>
          )}
        </AccordionSummary>
        <Grid component={AccordionDetails} container>
          <Grid container spacing={2} size={12}>
            {children}
          </Grid>
        </Grid>
      </Accordion>
    </Grid>
  );
}
