import type { ReactNode } from "react";
import {
  Typography,
  Divider,
  Link,
  useMediaQuery,
  useTheme,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import SaveIcon from "@mui/icons-material/Save";
import type { RouteDefinition } from "config/app-config";
import { useCurrentPathRoute } from "hooks";
import { useDossier } from "providers";
import { InformationsEssentielles } from "./InformationsEssentielles";
import LoadingButton from "components/LoadingButton";

interface DossierSectionsProps {
  isLoading: boolean;
  sections: Array<RouteDefinition>;
  noInformationsEssentielles?: boolean;
  noSaveButton?: boolean;
}

function DossierSections({
  isLoading,
  sections,
  noInformationsEssentielles = false,
  noSaveButton = false,
}: Readonly<DossierSectionsProps>): ReactNode {
  const currentPath = useCurrentPathRoute();
  const { dossier } = useDossier();
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Grid
        container
        columnSpacing={2}
        alignItems="center"
        sx={{
          p: 2,
          background: "white",
          position: "sticky",
          top: 0,
          zIndex: 10,
          borderBottom: "2px solid",
          borderColor: `${dossier?.type?.toLowerCase() ?? "primary"}.main`,
        }}
        size={12}>
        <Grid container size="grow">
          <Grid size={12}>
            <Typography color="grey">SECTIONS</Typography>
          </Grid>
          <Grid container columnSpacing={4} size={12}>
            {sections.map(({ title, path, getParameterPath }) => {
              const isCurrentPath = currentPath === path;
              const linkTitle = `${isCurrentPath ? "> " : ""}${title}`;
              return (
                <Grid key={title} size="auto">
                  <Link
                    color={isCurrentPath ? "info.main" : "grey"}
                    sx={isCurrentPath ? { fontWeight: 700 } : undefined}
                    href={getParameterPath(dossier?.idChantier ?? "", dossier?.id ?? "")}>
                    {linkTitle}
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {!noSaveButton && <Divider orientation="vertical" flexItem />}
        <Grid size="auto">
          {upSm && !noSaveButton && (
            <LoadingButton
              type="submit"
              loading={isLoading}
              color="info"
              variant="outlined"
              startIcon={<SaveIcon />}>
              Enregistrer
            </LoadingButton>
          )}
          {!upSm &&
            !noSaveButton &&
            (isLoading ? (
              <CircularProgress color="primary" size={43} />
            ) : (
              <IconButton type="submit" color="info" size="medium">
                <SaveIcon fontSize="inherit" />
              </IconButton>
            ))}
        </Grid>
      </Grid>
      {!noInformationsEssentielles && <InformationsEssentielles />}
    </>
  );
}

export default DossierSections;
