import { localStorageKeys } from "config/app-config";
import { useEffect, useState } from "react";

export function usePersistentNavigationMenu(): {
  isNavMenuOpen: boolean;
  setIsNavMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
} {
  const [isNavMenuOpen, setIsNavMenuOpen] = useState<boolean>(
    localStorage.getItem(localStorageKeys.navigationMenu) == null ||
      localStorage.getItem(localStorageKeys.navigationMenu) === "true"
  );

  useEffect(() => {
    localStorage.setItem(localStorageKeys.navigationMenu, `${isNavMenuOpen}`);
  }, [isNavMenuOpen]);

  return { isNavMenuOpen, setIsNavMenuOpen };
}
