import type { Fournisseur } from "models";

export const fournisseurs: Array<Fournisseur> = [
  {
    id: "0000103585",
    libelle: "ACE BTP utiliser 107161 Assistance Conseil Expertise",
  },
  {
    id: "0000105866",
    libelle: "ACIER POSE SERVICE voir PROMETAL même n° de TVA que PROMETAL",
  },
  {
    id: "0000107456",
    libelle: "ACROMAX",
  },
  {
    id: "0000108674",
    libelle: "ACTEMIUM",
  },
  {
    id: "0000108857",
    libelle: "ACTION BATIMENT 67",
  },
  {
    id: "0000100004",
    libelle: "ADAM TP",
  },
  {
    id: "0000601145",
    libelle: "ADE SAS",
  },
  {
    id: "0000106505",
    libelle: "ADEQUASOL",
  },
  {
    id: "0000108588",
    libelle: "AFC CARRELAGE",
  },
  {
    id: "0000108553",
    libelle: "AKPRO",
  },
  {
    id: "0000107640",
    libelle: "AKSU SARL",
  },
  {
    id: "0000107243",
    libelle: "ALGOTRA",
  },
  {
    id: "0000107740",
    libelle: "ALSA TS",
  },
  {
    id: "0000107344",
    libelle: "ALSACE APPLICATION",
  },
  {
    id: "0000103965",
    libelle: "ALSACE ARMATURES SAS",
  },
  {
    id: "0000107039",
    libelle: "ALSACE ARROSAGE SYSTEMES",
  },
  {
    id: "0000107013",
    libelle: "ALSACE AUTOMATISMES",
  },
  {
    id: "0000105612",
    libelle: "ALSACE CLEAN SARL",
  },
  {
    id: "0000108603",
    libelle: "ALSACE NACELLES SERVICES",
  },
  {
    id: "0000107936",
    libelle: "ALSACE PAVES EST",
  },
  {
    id: "0000104838",
    libelle: "ALSACE PLAFONDS",
  },
  {
    id: "0000107000",
    libelle: "ALSAPLAST TEAM",
  },
  {
    id: "0000103023",
    libelle: "ALSAVERT SAS",
  },
  {
    id: "0000108601",
    libelle: "ALURHIN",
  },
  {
    id: "0000108622",
    libelle: "AMC",
  },
  {
    id: "0000106982",
    libelle: "AMIANTEKO",
  },
  {
    id: "0000601060",
    libelle: "AMORIN ET CRUZ LDA",
  },
  {
    id: "0000105261",
    libelle: "ANA",
  },
  {
    id: "0000106491",
    libelle: "ANDLAUER",
  },
  {
    id: "0000506546",
    libelle: "ANGERS SOHNE utiliser 200365",
  },
  {
    id: "0000108916",
    libelle: "ANVOLIA 67",
  },
  {
    id: "0000505603",
    libelle: "AQUA AUTOMATION SARL",
  },
  {
    id: "0000103895",
    libelle: "AQUALTER CONSTRUCTION SAS",
  },
  {
    id: "0000503560",
    libelle: "AQUATEC",
  },
  {
    id: "0000107816",
    libelle: "AQUATIC BEZANCON",
  },
  {
    id: "0000103680",
    libelle: "ARCADIS ESG",
  },
  {
    id: "0000105991",
    libelle: "ARCHES DEMOLITION",
  },
  {
    id: "0000106921",
    libelle: "ARECO",
  },
  {
    id: "0000103230",
    libelle: "ARTELIA  SAINT OUEN     SIEGE",
  },
  {
    id: "0000104555",
    libelle: "ARTERE CONSTRUCTION ET TP",
  },
  {
    id: "0000107070",
    libelle: "ARTERE CONSTRUCTION TP SUD",
  },
  {
    id: "0000107237",
    libelle: "ARTI SABRI SARL",
  },
  {
    id: "0000107651",
    libelle: "ASIA ET COMPAGNIE",
  },
  {
    id: "0000105252",
    libelle: "AS-INDUS Environnement",
  },
  {
    id: "0000108308",
    libelle: "ATELIER BONNE",
  },
  {
    id: "0000100040",
    libelle: "ATIC SA SIEGE SOCIAL HERRLISHEIM",
  },
  {
    id: "0000102220",
    libelle: "ATOUT SERVICES",
  },
  {
    id: "0000108866",
    libelle: "AU FIL DES SAISONS",
  },
  {
    id: "0000105922",
    libelle: "AU PORT UNES SCOP SART",
  },
  {
    id: "0000601085",
    libelle: "AVIPUR ALTITUDE",
  },
  {
    id: "0000505230",
    libelle: "AWS BETON",
  },
  {
    id: "0000300751",
    libelle: "AXEO TP   HOERDT",
  },
  {
    id: "0000200155",
    libelle: "AXEO TP  GENNEVILLIERS",
  },
  {
    id: "0000106713",
    libelle: "AXEST",
  },
  {
    id: "0000106962",
    libelle: "BARI BTP",
  },
  {
    id: "0000100053",
    libelle: "BARTHEL",
  },
  {
    id: "0000105775",
    libelle: "BARTOLO ET FILS",
  },
  {
    id: "0000107570",
    libelle: "BARUCH ET FISCH",
  },
  {
    id: "0000107101",
    libelle: "BATI ELSASS ENVIRONNEMENT",
  },
  {
    id: "0000503791",
    libelle: "BATIMENT SERVICE",
  },
  {
    id: "0000100055",
    libelle: "BAUMERT CM BCM",
  },
  {
    id: "0000106576",
    libelle: "BECK ANTOINE",
  },
  {
    id: "0000100907",
    libelle: "BEREST",
  },
  {
    id: "0000108438",
    libelle: "BERGHEIMER",
  },
  {
    id: "0000506940",
    libelle: "BERSAM",
  },
  {
    id: "0000108304",
    libelle: "BESB",
  },
  {
    id: "0000106947",
    libelle: "BET FAVIER VERNE ET ASSOCIES",
  },
  {
    id: "0000100070",
    libelle: "BETON DU RIED",
  },
  {
    id: "0000108345",
    libelle: "BETON SCIAGE",
  },
  {
    id: "0000106105",
    libelle: "BETON TECH",
  },
  {
    id: "0000100590",
    libelle: "BEYER",
  },
  {
    id: "0000500315",
    libelle: "BEYER MENUISERIE",
  },
  {
    id: "0000102630",
    libelle: "BIEBER  PORTES & FENETRES",
  },
  {
    id: "0000200337",
    libelle: "BIOG GMBH",
  },
  {
    id: "0000106120",
    libelle: "BL INCENDIE",
  },
  {
    id: "0000108962",
    libelle: "BMCR",
  },
  {
    id: "0000100736",
    libelle: "BN FRANCE 2000",
  },
  {
    id: "0000106130",
    libelle: "BOLIDUM NOEL EURL",
  },
  {
    id: "0000503770",
    libelle: "BONNE ATELIER",
  },
  {
    id: "0000100278",
    libelle: "BOUYGUES BATIMENT NORD EST",
  },
  {
    id: "0000600850",
    libelle: "BOUYGUES ENERGIES SERVICES",
  },
  {
    id: "0000108386",
    libelle: "BRAND FRANCE",
  },
  {
    id: "0000601751",
    libelle: "BREIT FER METALLERIE",
  },
  {
    id: "0000504495",
    libelle: "BRG GMBH",
  },
  {
    id: "0000103570",
    libelle: "BRUYNZEEL RANGEMENTS",
  },
  {
    id: "0000103322",
    libelle: "BTP STEGER",
  },
  {
    id: "0000106681",
    libelle: "BTP WEREY SARL",
  },
  {
    id: "0000300680",
    libelle: "BURGER Agence de SCREG EST",
  },
  {
    id: "0000100089",
    libelle: "BURGER AGENCE SCREG EST",
  },
  {
    id: "0000100917",
    libelle: "C D R E COMPTOIR DES REVETEMENTS DE L EST",
  },
  {
    id: "0000108630",
    libelle: "C STRUCTURE",
  },
  {
    id: "0000108724",
    libelle: "CANGI CONFORT HABITAT",
  },
  {
    id: "0000103260",
    libelle: "CAPTAGE NEUTRALISATION",
  },
  {
    id: "0000105864",
    libelle: "CARDEM",
  },
  {
    id: "0000105682",
    libelle: "CBA CONCEPT GROS OEUVRE BATISSEUR ASSEMBLEU",
  },
  {
    id: "0000108817",
    libelle: "CBS",
  },
  {
    id: "0000503735",
    libelle: "CDF",
  },
  {
    id: "0000105761",
    libelle: "CEDER",
  },
  {
    id: "0000105891",
    libelle: "CEGELEC ALSACE LORRAINE   SIEGE",
  },
  {
    id: "0000105890",
    libelle: "CELIK",
  },
  {
    id: "0000108014",
    libelle: "CENTRE ALSACE NETTOYAGE",
  },
  {
    id: "0000103880",
    libelle: "CERILEC Electricité Gale et Industrielle",
  },
  {
    id: "0000105777",
    libelle: "CESCUTTI ET FILS CHARPENTES",
  },
  {
    id: "0000506493",
    libelle: "CHAMBRE AGRICULTURE MOSELLE AGRICULTURES ET TERRITOIRES",
  },
  {
    id: "0000108917",
    libelle: "CHANZY PARDOUX",
  },
  {
    id: "0000107647",
    libelle: "CHARPENTE NEU",
  },
  {
    id: "0000108293",
    libelle: "CHARPENTES PETER",
  },
  {
    id: "0000105855",
    libelle: "CHAUDRONNERIE DU RIED",
  },
  {
    id: "0000106075",
    libelle: "CHM INTERNATIONAL",
  },
  {
    id: "0000106086",
    libelle: "CHUBB FRANCE",
  },
  {
    id: "0000106954",
    libelle: "CIDES",
  },
  {
    id: "0000108965",
    libelle: "CIVIS INGENIERIE",
  },
  {
    id: "0000107343",
    libelle: "CJ TRAVAUX",
  },
  {
    id: "0000106025",
    libelle: "CJL FERMETURES",
  },
  {
    id: "0000104971",
    libelle: "CLEMESSY SA",
  },
  {
    id: "0000108913",
    libelle: "CLESTRA NEW SAS",
  },
  {
    id: "0000105861",
    libelle: "CLIMAFROID",
  },
  {
    id: "0000107076",
    libelle: "CLK",
  },
  {
    id: "0000105538",
    libelle: "C&M CARRELAGE",
  },
  {
    id: "0000107285",
    libelle: "CM HUSS",
  },
  {
    id: "0000104251",
    libelle: "CMI EUROPE ENVIRONNEMENT",
  },
  {
    id: "0000108247",
    libelle: "COLAS FRANCE Siège",
  },
  {
    id: "0000301150",
    libelle: "COLAS NORD EST   OSTWALD",
  },
  {
    id: "0000108282",
    libelle: "COLLECTIVITE EUROPEENNE D ALSACE CEA",
  },
  {
    id: "0000601707",
    libelle: "COLOM BISBAL GREGORY",
  },
  {
    id: "0000506928",
    libelle: "COMMUNE DE LEMBERG",
  },
  {
    id: "0000106215",
    libelle: "COMO TUYAUTERIE INDUSTRIELLE",
  },
  {
    id: "0000105562",
    libelle: "COMPETENCE GEOTECHNIQUE GRAND EST",
  },
  {
    id: "0000107805",
    libelle: "CONCEPTION ET METHODE YVES SCHAEFFER",
  },
  {
    id: "0000501520",
    libelle: "CONSEIL DEPARTE utiliser le 100652 CDG 67",
  },
  {
    id: "0000504491",
    libelle: "CONSEIL DEPARTEMENT utiliser 100652 PARC DEPARTEMENTAL ERSTEIN",
  },
  {
    id: "0000108172",
    libelle: "CONTROLE CONSEIL TRAVAUX PUBLICS",
  },
  {
    id: "0000100800",
    libelle: "COREBAT Couverture Zinguerie",
  },
  {
    id: "0000108589",
    libelle: "CORON THIBAUD",
  },
  {
    id: "0000108224",
    libelle: "COUVRACIER EST",
  },
  {
    id: "0000102585",
    libelle: "COUVREST",
  },
  {
    id: "0000504630",
    libelle: "CREPI DECOR MACONNERIE",
  },
  {
    id: "0000105402",
    libelle: "CREPI STYLE SARL",
  },
  {
    id: "0000106291",
    libelle: "CREPISSAGE DE L OUTRE FORET",
  },
  {
    id: "0000601045",
    libelle: "CRUZ",
  },
  {
    id: "0000105291",
    libelle: "CSI",
  },
  {
    id: "0000108353",
    libelle: "CTE",
  },
  {
    id: "0000106403",
    libelle: "CTE",
  },
  {
    id: "0000107445",
    libelle: "CTE LYON",
  },
  {
    id: "0000102050",
    libelle: "CTI",
  },
  {
    id: "0000108235",
    libelle: "CTI",
  },
  {
    id: "0000108503",
    libelle: "C2T",
  },
  {
    id: "0000106310",
    libelle: "DAGARD",
  },
  {
    id: "0000108375",
    libelle: "DALEKO",
  },
  {
    id: "0000107935",
    libelle: "DATOO",
  },
  {
    id: "0000105730",
    libelle: "DAVID CHRISTIAN",
  },
  {
    id: "0000105203",
    libelle: "DB SILENCE EURO SOUND PROJECT",
  },
  {
    id: "0000102337",
    libelle: "DECK HUBERT & FILS",
  },
  {
    id: "0000106385",
    libelle: "DECO DESIGN",
  },
  {
    id: "0000107208",
    libelle: "DECONTAIR",
  },
  {
    id: "0000106356",
    libelle: "DECOPEINT",
  },
  {
    id: "0000100618",
    libelle: "DEGREMONT",
  },
  {
    id: "0000104921",
    libelle: "DEGREMONT FA SIEGE GROUPE SUEZ",
  },
  {
    id: "0000106351",
    libelle: "DEHLINGER",
  },
  {
    id: "0000102305",
    libelle: "DEKRA CONSEIL HSE",
  },
  {
    id: "0000105881",
    libelle: "DELAGRAVE     SIEGE",
  },
  {
    id: "0000100129",
    libelle: "DELTA SERVICE LOCATION SIEGE",
  },
  {
    id: "0000108260",
    libelle: "DEMATHIEU BARD",
  },
  {
    id: "0000107520",
    libelle: "DEMATHIEU BARD AGENCE LORRAINE",
  },
  {
    id: "0000503790",
    libelle: "DENNI CARRELAGE ET SALLE DE BAINS",
  },
  {
    id: "0000100600",
    libelle: "DENNI LEGOLL",
  },
  {
    id: "0000100652",
    libelle: "DEPARTEMENT DU BAS RHIN",
  },
  {
    id: "0000102331",
    libelle: "DESENFUM EST",
  },
  {
    id: "0000104180",
    libelle: "DEST",
  },
  {
    id: "0000100711",
    libelle: "DHD DIAMANT",
  },
  {
    id: "0000108364",
    libelle: "DIAMCOUPE",
  },
  {
    id: "0000102275",
    libelle: "DICKER SA",
  },
  {
    id: "0000105873",
    libelle: "DIEBOLD CARRELAGE",
  },
  {
    id: "0000100133",
    libelle: "DIEBOLT TP",
  },
  {
    id: "0000100728",
    libelle: "DIPOL SA",
  },
  {
    id: "0000101435",
    libelle: "DISS",
  },
  {
    id: "0000107075",
    libelle: "DMR BTP",
  },
  {
    id: "0000107800",
    libelle: "DOCTOR HOUSE SARL",
  },
  {
    id: "0000106961",
    libelle: "DOMOSECURITY",
  },
  {
    id: "0000107737",
    libelle: "DOUVIER CHRISTIAN TRVAUX PUBLICS",
  },
  {
    id: "0000107648",
    libelle: "DRESCH PROJECTISIM EURL",
  },
  {
    id: "0000104936",
    libelle: "DRILLEXPERT",
  },
  {
    id: "0000103455",
    libelle: "DUMEZ ANSTETT",
  },
  {
    id: "0000105446",
    libelle: "DURMEYER",
  },
  {
    id: "0000504506",
    libelle: "E S T S",
  },
  {
    id: "0000107739",
    libelle: "EARL RISCH",
  },
  {
    id: "0000107426",
    libelle: "ECA",
  },
  {
    id: "0000105907",
    libelle: "ECEL",
  },
  {
    id: "0000106270",
    libelle: "ECO PEINTURE",
  },
  {
    id: "0000108394",
    libelle: "EFPI",
  },
  {
    id: "0000105932",
    libelle: "EG SIGNALISATION",
  },
  {
    id: "0000505113",
    libelle: "EGC ECHAFAUDAGE",
  },
  {
    id: "0000105836",
    libelle: "EGC GALOPIN",
  },
  {
    id: "0000108777",
    libelle: "EGIL",
  },
  {
    id: "0000106125",
    libelle: "EGIR",
  },
  {
    id: "0000600905",
    libelle: "EGTS Entreprise Générale de travaux spéc",
  },
  {
    id: "0000100145",
    libelle: "EGW MAINTENANCE",
  },
  {
    id: "0000101995",
    libelle: "EHRHART",
  },
  {
    id: "0000103906",
    libelle: "EIFFAGE",
  },
  {
    id: "0000105026",
    libelle: "EIFFAGE ENERGIE SYSTEMES ALSACE - FRANCHE-COMTE",
  },
  {
    id: "0000107257",
    libelle: "EIFFAGE ROUTE NORD EST SIEGE",
  },
  {
    id: "0000505610",
    libelle: "EIMI ALSACE",
  },
  {
    id: "0000108946",
    libelle: "ELSASS LOCATIONS",
  },
  {
    id: "0000101725",
    libelle: "EMO EQUIPEMENTS ET MACHINES DE L'OUEST",
  },
  {
    id: "0000107722",
    libelle: "EMT CONTROLE",
  },
  {
    id: "0000108011",
    libelle: "EMTS",
  },
  {
    id: "0000105801",
    libelle: "ENNESSER ET FILS PEINTURE",
  },
  {
    id: "0000106839",
    libelle: "ENTRAIDE EMPLOI anciennement SAVEICO",
  },
  {
    id: "0000107195",
    libelle: "ENTREPOSE ECHAFAUDAGES SIEGE",
  },
  {
    id: "0000105970",
    libelle: "ES ENERGIES STRASBOURG",
  },
  {
    id: "0000106050",
    libelle: "ES PARQUET ANDLAUER",
  },
  {
    id: "0000106663",
    libelle: "ESER ETANCHEITE",
  },
  {
    id: "0000107297",
    libelle: "ESPACE DECOR",
  },
  {
    id: "0000104906",
    libelle: "EST OUVRAGES",
  },
  {
    id: "0000106443",
    libelle: "EST PAYSAGES D ALSACE",
  },
  {
    id: "0000108219",
    libelle: "EST SIGNALISATION",
  },
  {
    id: "0000108286",
    libelle: "ESTS",
  },
  {
    id: "0000101735",
    libelle: "ETANDEX        SIEGE",
  },
  {
    id: "0000100146",
    libelle: "ETM",
  },
  {
    id: "0000104225",
    libelle: "ETS BONNET GERARD",
  },
  {
    id: "0000108359",
    libelle: "EURO CONSTRUCTION MODERNE",
  },
  {
    id: "0000105956",
    libelle: "EUROMETAL",
  },
  {
    id: "0000107267",
    libelle: "EUROPAVE",
  },
  {
    id: "0000106060",
    libelle: "EUROPEENE D'ELECTRO-TECHNIQUE",
  },
  {
    id: "0000106062",
    libelle: "EUROPELEC",
  },
  {
    id: "0000100169",
    libelle: "EUROSYSTEMES",
  },
  {
    id: "0000100775",
    libelle: "EUROVIA AFC  NEW UTILISER 107086",
  },
  {
    id: "0000100171",
    libelle: "EUROVIA Agence MOLSHEIM",
  },
  {
    id: "0000107086",
    libelle: "EUROVIA ALSACE LORRAINE SIEGE",
  },
  {
    id: "0000100172",
    libelle: "EVAC EAU",
  },
  {
    id: "0000108592",
    libelle: "EXPLOITATION FORESTIERE HOERNEL",
  },
  {
    id: "0000100616",
    libelle: "FAURE EQUIPEMENTS",
  },
  {
    id: "0000502897",
    libelle: "FENNINGER PAYSAGE",
  },
  {
    id: "0000106493",
    libelle: "FERALU",
  },
  {
    id: "0000108036",
    libelle: "FIBRAXESS",
  },
  {
    id: "0000601005",
    libelle: "FIEG DENIS PAYSAGISTE",
  },
  {
    id: "0000105971",
    libelle: "FILIPOVIC",
  },
  {
    id: "0000108133",
    libelle: "FIMOGNARI",
  },
  {
    id: "0000102535",
    libelle: "FLAMENT CALORIFUGE ISOLATION",
  },
  {
    id: "0000506090",
    libelle: "FOR SCI TECH LUX",
  },
  {
    id: "0000103477",
    libelle: "FORAGE VINGT ET UN",
  },
  {
    id: "0000107325",
    libelle: "FORAGES ET CHAUFFAGES DE L EST",
  },
  {
    id: "0000102945",
    libelle: "FORALEST MAURUTTO SA",
  },
  {
    id: "0000103065",
    libelle: "FORNES",
  },
  {
    id: "0000108147",
    libelle: "FP BATI",
  },
  {
    id: "0000108015",
    libelle: "FRANCE CLOTURE ENVIRONNEMENT",
  },
  {
    id: "0000502841",
    libelle: "FRAU SALVATORE",
  },
  {
    id: "0000101631",
    libelle: "FREGONESE",
  },
  {
    id: "0000105539",
    libelle: "FREYSSINET",
  },
  {
    id: "0000101630",
    libelle: "FRITZ  ELECTRICITE",
  },
  {
    id: "0000100195",
    libelle: "FUCHS J M",
  },
  {
    id: "0000105381",
    libelle: "FV INDUSTRIES",
  },
  {
    id: "0000106330",
    libelle: "GALA BAUSTAHLARMIERUNG GMBH",
  },
  {
    id: "0000105535",
    libelle: "GANTZER TP",
  },
  {
    id: "0000200275",
    libelle: "GARATEC",
  },
  {
    id: "0000100201",
    libelle: "GARTISER",
  },
  {
    id: "0000107028",
    libelle: "GASHI",
  },
  {
    id: "0000106390",
    libelle: "GCM DEMOLITION",
  },
  {
    id: "0000101230",
    libelle: "GCM ENVIRONNEMENT SASU",
  },
  {
    id: "0000100196",
    libelle: "GCM SAS",
  },
  {
    id: "0000108914",
    libelle: "GEISTEL ROBERT SAS",
  },
  {
    id: "0000108738",
    libelle: "GENDRY SERVICE LOCATION",
  },
  {
    id: "0000106053",
    libelle: "GENERSYS SERVICES",
  },
  {
    id: "0000100207",
    libelle: "GENIE CLIMATIQUE DE L EST",
  },
  {
    id: "0000106654",
    libelle: "GEO RESINE DE L EST",
  },
  {
    id: "0000108950",
    libelle: "GEODECRION",
  },
  {
    id: "0000600730",
    libelle: "GEOTEC SIEGE",
  },
  {
    id: "0000108291",
    libelle: "GERARD BONNET SAS",
  },
  {
    id: "0000107891",
    libelle: "GERE",
  },
  {
    id: "0000108408",
    libelle: "GERFA GRAND EST",
  },
  {
    id: "0000107062",
    libelle: "GIAMBERINI GUY",
  },
  {
    id: "0000100593",
    libelle: "GINGER BURGEAP SIEGE",
  },
  {
    id: "0000106492",
    libelle: "GIROLT",
  },
  {
    id: "0000108493",
    libelle: "GLAAS DEGRIFF",
  },
  {
    id: "0000107090",
    libelle: "GOECO",
  },
  {
    id: "0000108380",
    libelle: "GRAF SERVICES PLUS",
  },
  {
    id: "0000100214",
    libelle: "GRAVIERES ALSACE LORRAINE SIEGE",
  },
  {
    id: "0000106405",
    libelle: "GREBIL RENE ET CIE",
  },
  {
    id: "0000106660",
    libelle: "GROLLEMUND LABOROUTES GRAND EST",
  },
  {
    id: "0000102226",
    libelle: "GROSS ET FILS FLORENT",
  },
  {
    id: "0000107485",
    libelle: "GROUPE MARAIS",
  },
  {
    id: "0000108712",
    libelle: "GTM TP EST",
  },
  {
    id: "0000100224",
    libelle: "GUILLET SA",
  },
  {
    id: "0000107108",
    libelle: "GUINAMIC GSC",
  },
  {
    id: "0000200295",
    libelle: "GUNER GMBH",
  },
  {
    id: "0000105874",
    libelle: "GUTERMANN",
  },
  {
    id: "0000200365",
    libelle: "H ANGERS SOHNE",
  },
  {
    id: "0000105842",
    libelle: "HAGENMULLER",
  },
  {
    id: "0000106350",
    libelle: "HANAU DECONSTRUCTION DESAMIANTAGE",
  },
  {
    id: "0000106266",
    libelle: "HANAU PLATRERIE",
  },
  {
    id: "0000107326",
    libelle: "H&E CONCEPT",
  },
  {
    id: "0000108409",
    libelle: "HEIM",
  },
  {
    id: "0000108757",
    libelle: "HEINRICH SCHMID",
  },
  {
    id: "0000105848",
    libelle: "HEMMERLE RENE ENTREPRISE DE CONSTRUCTION",
  },
  {
    id: "0000107122",
    libelle: "HENO DESCHANG CLIMATISATION SARL",
  },
  {
    id: "0000100236",
    libelle: "HERLI FRANCE",
  },
  {
    id: "0000100629",
    libelle: "HERRMANN T P",
  },
  {
    id: "0000105950",
    libelle: "HIPOJET",
  },
  {
    id: "0000106087",
    libelle: "HK CALORIFUGE",
  },
  {
    id: "0000503081",
    libelle: "HOLLAENDER",
  },
  {
    id: "0000107376",
    libelle: "HOLTZINGER",
  },
  {
    id: "0000106935",
    libelle: "HSOLS FRANCE",
  },
  {
    id: "0000102830",
    libelle: "HSOLS INDUSTRIELS",
  },
  {
    id: "0000101710",
    libelle: "HUBER TECHNOLOGY",
  },
  {
    id: "0000108923",
    libelle: "HUNSINGER",
  },
  {
    id: "0000107846",
    libelle: "HUSSOR ERECTA",
  },
  {
    id: "0000108795",
    libelle: "HYDR'EAU SERVICES",
  },
  {
    id: "0000106345",
    libelle: "HYDRO ET VIDEO",
  },
  {
    id: "0000107802",
    libelle: "HYDRO SYSTEMS",
  },
  {
    id: "0000105972",
    libelle: "H23 SYTEMS",
  },
  {
    id: "0000105785",
    libelle: "ID VERDE",
  },
  {
    id: "0000106281",
    libelle: "INDUSTRIE SERVICE ECHAFAUDAGES",
  },
  {
    id: "0000108756",
    libelle: "INDUSTRIE SERVICE ECHAUFAUDAGE",
  },
  {
    id: "0000106582",
    libelle: "INEO INDUSTRIE ET TERTIAIRE EST Siè",
  },
  {
    id: "0000106235",
    libelle: "INERA GRAND EST",
  },
  {
    id: "0000107795",
    libelle: "INTERSOL       SIEGE",
  },
  {
    id: "0000101080",
    libelle: "IREAL ENVIRONNEMENT SAS",
  },
  {
    id: "0000100250",
    libelle: "IRH INGENIEUR CONSEIL  SIEGE",
  },
  {
    id: "0000100256",
    libelle: "IRION",
  },
  {
    id: "0000108623",
    libelle: "IS TERRASSEMENT",
  },
  {
    id: "0000105895",
    libelle: "ITELYS",
  },
  {
    id: "0000100259",
    libelle: "ITREC",
  },
  {
    id: "0000106994",
    libelle: "J. ROQUE",
  },
  {
    id: "0000101157",
    libelle: "JARDINS GOTTRI REMY",
  },
  {
    id: "0000100633",
    libelle: "JEAN LEFEBVRE ALSACE",
  },
  {
    id: "0000100634",
    libelle: "JEHL SARL GERARD",
  },
  {
    id: "0000106190",
    libelle: "JET CLOTURE SARL",
  },
  {
    id: "0000106525",
    libelle: "JMH ING",
  },
  {
    id: "0000108943",
    libelle: "JOSE NEVES SAS",
  },
  {
    id: "0000106916",
    libelle: "J2G CONSTRUCTION",
  },
  {
    id: "0000104801",
    libelle: "KAEFER WANNER",
  },
  {
    id: "0000100274",
    libelle: "KARCHER",
  },
  {
    id: "0000100822",
    libelle: "KAROTSCH PEINTURE",
  },
  {
    id: "0000105690",
    libelle: "KB2P SAS",
  },
  {
    id: "0000107190",
    libelle: "KELLER FONDATIONS SPECIALES",
  },
  {
    id: "0000104762",
    libelle: "KLEARIOS SASU",
  },
  {
    id: "0000100638",
    libelle: "KLEIN JEAN CHARLES",
  },
  {
    id: "0000106490",
    libelle: "KLEIN MENUISERIE ARTISANALE",
  },
  {
    id: "0000107925",
    libelle: "KLEIN TP",
  },
  {
    id: "0000108830",
    libelle: "KLIPFEL ET FILS",
  },
  {
    id: "0000502870",
    libelle: "KLIPFEL ET FILS",
  },
  {
    id: "0000108517",
    libelle: "KR BAT",
  },
  {
    id: "0000101417",
    libelle: "KRETZ ENTREPRISE",
  },
  {
    id: "0000106662",
    libelle: "KSB ETANCHEITE",
  },
  {
    id: "0000108915",
    libelle: "K3E",
  },
  {
    id: "0000100380",
    libelle: "LA PAYSAGERIE",
  },
  {
    id: "0000107315",
    libelle: "LAEUFFER",
  },
  {
    id: "0000106382",
    libelle: "LANG PASCAL",
  },
  {
    id: "0000108516",
    libelle: "LANGER FORAGE",
  },
  {
    id: "0000103480",
    libelle: "LEFEBVRE JEAN",
  },
  {
    id: "0000107140",
    libelle: "LEON CONSTRUCTION ET TP",
  },
  {
    id: "0000107246",
    libelle: "LEON NOEL SIEGE",
  },
  {
    id: "0000106855",
    libelle: "LES ATELIERS DE LA GESSE",
  },
  {
    id: "0000108052",
    libelle: "LES CONSTRUCTEURS VOSGIENS",
  },
  {
    id: "0000106051",
    libelle: "LEVIEUX PATRICK ENTREPRISE",
  },
  {
    id: "0000107693",
    libelle: "LI-DER",
  },
  {
    id: "0000108918",
    libelle: "LINGENHELD SARL",
  },
  {
    id: "0000105865",
    libelle: "LINGENHELD SAS",
  },
  {
    id: "0000100866",
    libelle: "LINGENHELD TRAVAUX PUBLICS",
  },
  {
    id: "0000507359",
    libelle: "LIPP GMBH",
  },
  {
    id: "0000502896",
    libelle: "LIPS ET FILS",
  },
  {
    id: "0000108673",
    libelle: "LOC EST SAS",
  },
  {
    id: "0000601155",
    libelle: "LOCA FRANCE",
  },
  {
    id: "0000100314",
    libelle: "LOEBER GEORGES",
  },
  {
    id: "0000106240",
    libelle: "LORALCO",
  },
  {
    id: "0000103560",
    libelle: "LORRAINE EAU ASSISTANCE TECHNIQUE LOREAT",
  },
  {
    id: "0000600185",
    libelle: "LOUX ET FILS",
  },
  {
    id: "0000106030",
    libelle: "MALEZIEUX",
  },
  {
    id: "0000107155",
    libelle: "MARCEL SCHREIBER",
  },
  {
    id: "0000104065",
    libelle: "MARIO TASSONE ELECTROTECHNIQUE ILLK",
  },
  {
    id: "0000105776",
    libelle: "MARQUES ACM FRERES",
  },
  {
    id: "0000601006",
    libelle: "MAS",
  },
  {
    id: "0000108446",
    libelle: "MASCI",
  },
  {
    id: "0000505180",
    libelle: "MAX FRUH",
  },
  {
    id: "0000107298",
    libelle: "MAYART",
  },
  {
    id: "0000104015",
    libelle: "MBH",
  },
  {
    id: "0000100337",
    libelle: "MEAZZA MARBRERIE TAILLE PIERRE",
  },
  {
    id: "0000100339",
    libelle: "MEDIACO ALSACE",
  },
  {
    id: "0000505350",
    libelle: "MEGABLOC GmbH",
  },
  {
    id: "0000106420",
    libelle: "MENARD",
  },
  {
    id: "0000107296",
    libelle: "MENUISERIE ANDRES",
  },
  {
    id: "0000107291",
    libelle: "METAL MORPHOSE",
  },
  {
    id: "0000505202",
    libelle: "METAL SERVICES FORSTER",
  },
  {
    id: "0000106561",
    libelle: "MEYER CHARPENTE",
  },
  {
    id: "0000108602",
    libelle: "MEYER DAL'ALU - MBDAL",
  },
  {
    id: "0000105723",
    libelle: "MEYER ETIENNE TERRASSEMENT SARL",
  },
  {
    id: "0000107841",
    libelle: "MG CLOTURES",
  },
  {
    id: "0000102581",
    libelle: "MHD ENTREPRISE GALE DE PEINTURE",
  },
  {
    id: "0000107860",
    libelle: "MICR EAU",
  },
  {
    id: "0000100876",
    libelle: "MIROIVER SARL",
  },
  {
    id: "0000503475",
    libelle: "MITSCHLER ARNAUD",
  },
  {
    id: "0000600635",
    libelle: "MITSCHLER ARNAUD PEINTURES",
  },
  {
    id: "0000108136",
    libelle: "MITSCHLER PEINTURES",
  },
  {
    id: "0000107165",
    libelle: "MK BATIMENT",
  },
  {
    id: "0000107395",
    libelle: "MK BATTAGE",
  },
  {
    id: "0000106361",
    libelle: "MOOG CHARPENTE",
  },
  {
    id: "0000105863",
    libelle: "MOREL ESCALIER",
  },
  {
    id: "0000105461",
    libelle: "MORREALE DOMINIQUE",
  },
  {
    id: "0000104235",
    libelle: "MSE  AGENCE EST",
  },
  {
    id: "0000100646",
    libelle: "MSU",
  },
  {
    id: "0000107020",
    libelle: "MULLER DENIS ENTREPRISE",
  },
  {
    id: "0000100354",
    libelle: "MULLER THA prendre 100484 Ets de SOGEA EST BTP",
  },
  {
    id: "0000106646",
    libelle: "MULLER TP SIEGE SOCIAL",
  },
  {
    id: "0000107341",
    libelle: "MUNICH FLORIAN GEOMETRE",
  },
  {
    id: "0000104839",
    libelle: "MUSEODIRECT",
  },
  {
    id: "0000107513",
    libelle: "M3R",
  },
  {
    id: "0000105540",
    libelle: "NATURE ET TECHNIQUES",
  },
  {
    id: "0000105656",
    libelle: "NAUTILIA",
  },
  {
    id: "0000100364",
    libelle: "NONNENMACHER ANDRE & FILS",
  },
  {
    id: "0000107207",
    libelle: "NUWA ENVIRONNEMENT",
  },
  {
    id: "0000108642",
    libelle: "OASURE",
  },
  {
    id: "0000103010",
    libelle: "OFFICE NATIONAL DES FORETS SIEGE",
  },
  {
    id: "0000106285",
    libelle: "OLLAND SAS",
  },
  {
    id: "0000105445",
    libelle: "OLRY CLOISONS",
  },
  {
    id: "0000103685",
    libelle: "OLRY ERNEST ET CIE ARKEDIA ARKEDIA",
  },
  {
    id: "0000105556",
    libelle: "OPURE",
  },
  {
    id: "0000108961",
    libelle: "OREA INDUSTRIE SARL",
  },
  {
    id: "0000106494",
    libelle: "OSTERMANN",
  },
  {
    id: "0000100367",
    libelle: "OTE INGENIERIE  ILLKIRCH siège OMNIUM TECHNIQUE EUROPEEN",
  },
  {
    id: "0000107052",
    libelle: "OZDEM",
  },
  {
    id: "0000105537",
    libelle: "OZE ARCHITECTURE",
  },
  {
    id: "0000108944",
    libelle: "PAROLINI GUY TRAVAUX PUBLICS",
  },
  {
    id: "0000107041",
    libelle: "PAVES STRASS",
  },
  {
    id: "0000107765",
    libelle: "PAVES 67",
  },
  {
    id: "0000101736",
    libelle: "PEDUZZI BATIMENT",
  },
  {
    id: "0000106936",
    libelle: "PEINTURE HEINRICH SCHMID",
  },
  {
    id: "0000107198",
    libelle: "PEINTURE HITTIER ET FILS",
  },
  {
    id: "0000108787",
    libelle: "PEINTURES PETTOELLO",
  },
  {
    id: "0000105905",
    libelle: "PEINTURES REUNIES LES SIEGE",
  },
  {
    id: "0000107547",
    libelle: "PEINTURES SAUMIER",
  },
  {
    id: "0000100298",
    libelle: "PEPINIERES LEDERMANN MUTSCHLER",
  },
  {
    id: "0000107638",
    libelle: "PHALSBOURG CARRELAGE SARL",
  },
  {
    id: "0000106495",
    libelle: "PIASENTIN",
  },
  {
    id: "0000107512",
    libelle: "PK CONCEPT",
  },
  {
    id: "0000106438",
    libelle: "PLACEO SA",
  },
  {
    id: "0000601652",
    libelle: "PLANETE CLOTURE",
  },
  {
    id: "0000101380",
    libelle: "PLASTICA",
  },
  {
    id: "0000107475",
    libelle: "PMA  NE PLUS UTILISER voir 108977",
  },
  {
    id: "0000108415",
    libelle: "POLYCHAPE",
  },
  {
    id: "0000102470",
    libelle: "POLYMER TRADING",
  },
  {
    id: "0000106520",
    libelle: "POLYTHERM",
  },
  {
    id: "0000102910",
    libelle: "PONTIGGIA  HORBOURG WIHR   SIEGE",
  },
  {
    id: "0000103610",
    libelle: "PRESENTS Parc des Tanneries",
  },
  {
    id: "0000108641",
    libelle: "PRESTA-LAB",
  },
  {
    id: "0000103701",
    libelle: "PRO GRILLAGE",
  },
  {
    id: "0000106530",
    libelle: "PROBAT BTP SIEGE",
  },
  {
    id: "0000108310",
    libelle: "PRODEVAL",
  },
  {
    id: "0000108221",
    libelle: "PROMETAL SA",
  },
  {
    id: "0000100394",
    libelle: "PROTEC BETON",
  },
  {
    id: "0000105937",
    libelle: "PROTECT EST SARL",
  },
  {
    id: "0000101270",
    libelle: "PROTIBAT SA",
  },
  {
    id: "0000108924",
    libelle: "PROXISOL",
  },
  {
    id: "0000600705",
    libelle: "R GDS    GAZ DE STRASBOURG",
  },
  {
    id: "0000103155",
    libelle: "RAESER MENUISERIE SAS",
  },
  {
    id: "0000108455",
    libelle: "RAK AMIANTE",
  },
  {
    id: "0000100406",
    libelle: "RAUSCHER",
  },
  {
    id: "0000504057",
    libelle: "RB SECURELEC",
  },
  {
    id: "0000106902",
    libelle: "REHA ASSAINISSEMENT",
  },
  {
    id: "0000601030",
    libelle: "REICHERT",
  },
  {
    id: "0000503472",
    libelle: "REISER JACKY",
  },
  {
    id: "0000108549",
    libelle: "RENAUD ET FILS",
  },
  {
    id: "0000101277",
    libelle: "RENNER PHILIPPE & FILS",
  },
  {
    id: "0000100411",
    libelle: "RESINA SA",
  },
  {
    id: "0000601465",
    libelle: "RH TOITURE",
  },
  {
    id: "0000106101",
    libelle: "RICHERT PLATRERIE",
  },
  {
    id: "0000101771",
    libelle: "RIED ETANCHE",
  },
  {
    id: "0000101306",
    libelle: "RITLENG Drainage Travx trancheuses",
  },
  {
    id: "0000108192",
    libelle: "RM ISOL",
  },
  {
    id: "0000100418",
    libelle: "ROESSEL",
  },
  {
    id: "0000100421",
    libelle: "ROTT TRAVAUX PUBLICS",
  },
  {
    id: "0000101190",
    libelle: "ROWASTORES STORES ET DESIGN",
  },
  {
    id: "0000107604",
    libelle: "RSTB",
  },
  {
    id: "0000503080",
    libelle: "RUCH PAUL ET FILS PLATRERIE",
  },
  {
    id: "0000108578",
    libelle: "RUGOTECH",
  },
  {
    id: "0000505411",
    libelle: "RUHL GMBH & CO",
  },
  {
    id: "0000105616",
    libelle: "SADAMEC SERVICES",
  },
  {
    id: "0000102180",
    libelle: "SADE PARIS",
  },
  {
    id: "0000102210",
    libelle: "SAED",
  },
  {
    id: "0000104276",
    libelle: "SAERT SAS",
  },
  {
    id: "0000100433",
    libelle: "SAETTLER ET CIE",
  },
  {
    id: "0000106630",
    libelle: "SAG VIGILEC",
  },
  {
    id: "0000105849",
    libelle: "SANICHAUF",
  },
  {
    id: "0000104824",
    libelle: "SANTERNE ALSACE",
  },
  {
    id: "0000503425",
    libelle: "SAP2i",
  },
  {
    id: "0000107650",
    libelle: "SARL HORNBERGER ET FILS",
  },
  {
    id: "0000107649",
    libelle: "SARL MALRIAT",
  },
  {
    id: "0000107145",
    libelle: "SARL POIROT JARDIN ET PAYSAGE",
  },
  {
    id: "0000100115",
    libelle: "SARM COLAS utiliser 108247",
  },
  {
    id: "0000107430",
    libelle: "SAS PAVAGE BAS RHIN",
  },
  {
    id: "0000100444",
    libelle: "SATER TRAVAUX PUBLICS",
  },
  {
    id: "0000101003",
    libelle: "SATM",
  },
  {
    id: "0000108561",
    libelle: "SCAL",
  },
  {
    id: "0000107027",
    libelle: "SCE CARRELAGE EREN",
  },
  {
    id: "0000106036",
    libelle: "SCHEIBEL METALLERIE",
  },
  {
    id: "0000107594",
    libelle: "SCHERTZ GRILLAGES",
  },
  {
    id: "0000505466",
    libelle: "SCHICK UND BIEBER GBR.",
  },
  {
    id: "0000106052",
    libelle: "SCHINDLER SA",
  },
  {
    id: "0000200290",
    libelle: "SCHLEITH GMBH",
  },
  {
    id: "0000106260",
    libelle: "SCHMIDT PEINTURES",
  },
  {
    id: "0000106005",
    libelle: "SCHOENLAUB",
  },
  {
    id: "0000108938",
    libelle: "SCHOTT ELAGAGE",
  },
  {
    id: "0000108945",
    libelle: "SCHRAMM CONSTRUCTIONS",
  },
  {
    id: "0000106690",
    libelle: "SCHWEBEL ET FILS",
  },
  {
    id: "0000106664",
    libelle: "SCHWEITZER JEAN LOUIS",
  },
  {
    id: "0000108585",
    libelle: "SCIAMAN ELAGAGE",
  },
  {
    id: "0000107721",
    libelle: "SCICADIAM",
  },
  {
    id: "0000106279",
    libelle: "SCICARDEMOL",
  },
  {
    id: "0000106195",
    libelle: "SCRE SAS",
  },
  {
    id: "0000107154",
    libelle: "SEIRS",
  },
  {
    id: "0000107006",
    libelle: "SEPPIC",
  },
  {
    id: "0000107872",
    libelle: "SERBET",
  },
  {
    id: "0000106951",
    libelle: "SERRURERIE SIGWALD",
  },
  {
    id: "0000107422",
    libelle: "SERVICES ACRO ALSACE",
  },
  {
    id: "0000107898",
    libelle: "SESAME",
  },
  {
    id: "0000108149",
    libelle: "SETHY        Siège",
  },
  {
    id: "0000106305",
    libelle: "SI NET",
  },
  {
    id: "0000108793",
    libelle: "SIALIS",
  },
  {
    id: "0000105199",
    libelle: "SIB ETUDES SARL",
  },
  {
    id: "0000100471",
    libelle: "SIEFFERT ATELIER",
  },
  {
    id: "0000100473",
    libelle: "SIEMENS         SIEGE",
  },
  {
    id: "0000105596",
    libelle: "SIGNATURE",
  },
  {
    id: "0000103195",
    libelle: "SIMEC",
  },
  {
    id: "0000106055",
    libelle: "SINBIO utiliser 107926",
  },
  {
    id: "0000102495",
    libelle: "SIRCO TRAVAUX SPECIAUX",
  },
  {
    id: "0000108186",
    libelle: "SIS ANGOT ET FILS",
  },
  {
    id: "0000108414",
    libelle: "SKS CARRELAGE",
  },
  {
    id: "0000105184",
    libelle: "SLD TP",
  },
  {
    id: "0000103157",
    libelle: "SMAC",
  },
  {
    id: "0000103476",
    libelle: "SMCE FORAGE",
  },
  {
    id: "0000106250",
    libelle: "SMCE REHA SAS",
  },
  {
    id: "0000107680",
    libelle: "SN GEO",
  },
  {
    id: "0000104030",
    libelle: "SNEE SARL",
  },
  {
    id: "0000100950",
    libelle: "SOBECA",
  },
  {
    id: "0000106515",
    libelle: "SOBECAMAT",
  },
  {
    id: "0000106590",
    libelle: "SOBRA",
  },
  {
    id: "0000105630",
    libelle: "SOC",
  },
  {
    id: "0000100524",
    libelle: "SODIMATE",
  },
  {
    id: "0000100484",
    libelle: "SOGEA EST BTP",
  },
  {
    id: "0000103845",
    libelle: "SOLIBAT",
  },
  {
    id: "0000107091",
    libelle: "SOLLER",
  },
  {
    id: "0000100489",
    libelle: "SOPREMA ENTREPRISES",
  },
  {
    id: "0000108649",
    libelle: "SORHEVAL",
  },
  {
    id: "0000105856",
    libelle: "SOS COUVERTURE",
  },
  {
    id: "0000100491",
    libelle: "SOTRAVEST",
  },
  {
    id: "0000107933",
    libelle: "SOURCES  Siège",
  },
  {
    id: "0000105591",
    libelle: "SOVEC",
  },
  {
    id: "0000106445",
    libelle: "SOVOTEC",
  },
  {
    id: "0000100494",
    libelle: "SPEYSER LUCIEN ET CIE",
  },
  {
    id: "0000104773",
    libelle: "SPID EMILE EURL",
  },
  {
    id: "0000105711",
    libelle: "SPIE BATIGNOLLES EST SIEGE",
  },
  {
    id: "0000107102",
    libelle: "SPIE CITYNETWORKS SIEGE",
  },
  {
    id: "0000100496",
    libelle: "SPIESS SA",
  },
  {
    id: "0000108590",
    libelle: "SPIKEROPE ALSACE",
  },
  {
    id: "0000504678",
    libelle: "SPITZER",
  },
  {
    id: "0000108100",
    libelle: "SPITZER",
  },
  {
    id: "0000108305",
    libelle: "ST FORAGE",
  },
  {
    id: "0000106653",
    libelle: "ST FORAGE MICRO TUNNELAGES TP",
  },
  {
    id: "0000104425",
    libelle: "STARCK",
  },
  {
    id: "0000106621",
    libelle: "STCL",
  },
  {
    id: "0000108979",
    libelle: "STEIMER",
  },
  {
    id: "0000105975",
    libelle: "STEINLE LOUIS",
  },
  {
    id: "0000105860",
    libelle: "STIHLE FRERES 67",
  },
  {
    id: "0000100430",
    libelle: "STM",
  },
  {
    id: "0000105060",
    libelle: "STP ALSACE SARL",
  },
  {
    id: "0000108378",
    libelle: "STRATEC",
  },
  {
    id: "0000105466",
    libelle: "STRATEGE TRAVAUX PUBLIC",
  },
  {
    id: "0000505854",
    libelle: "STRAUB GALA GROUPEMENT",
  },
  {
    id: "0000102746",
    libelle: "STRELEC",
  },
  {
    id: "0000105935",
    libelle: "STT FRANCOMAT",
  },
  {
    id: "0000105992",
    libelle: "STUTZMANN AGENCEMENT",
  },
  {
    id: "0000101010",
    libelle: "SUEZ  EAU FRANCE LYONNAISE DES EAUX FRANCE",
  },
  {
    id: "0000502882",
    libelle: "SUEZ EAU INDUSTRIELLE SIEGE",
  },
  {
    id: "0000102380",
    libelle: "SUEZ SERVICES FRANCE SIEGE",
  },
  {
    id: "0000100571",
    libelle: "SUPPRIME",
  },
  {
    id: "0000102855",
    libelle: "SUPPRIME",
  },
  {
    id: "0000108711",
    libelle: "SUSS ALBERT SOCIETE D'EXPLOITATION",
  },
  {
    id: "0000108048",
    libelle: "SW ENVIRONNEMENT",
  },
  {
    id: "0000103890",
    libelle: "SYSTEME WOLF",
  },
  {
    id: "0000107005",
    libelle: "S2EI",
  },
  {
    id: "0000107196",
    libelle: "TBI",
  },
  {
    id: "0000505243",
    libelle: "TECHFINA",
  },
  {
    id: "0000103495",
    libelle: "TECHNIC JARDINS",
  },
  {
    id: "0000106917",
    libelle: "TECHNIPLAST",
  },
  {
    id: "0000100512",
    libelle: "TECHNIQUE POMPAGE FONTAINES",
  },
  {
    id: "0000104690",
    libelle: "TECHNOLOGIE SOLUTIONS TRAVAUX LS-TST",
  },
  {
    id: "0000108028",
    libelle: "TEKTO INGENIERIE",
  },
  {
    id: "0000105936",
    libelle: "TELEREP ALSACE",
  },
  {
    id: "0000100514",
    libelle: "TELEREP EST",
  },
  {
    id: "0000106900",
    libelle: "TENN GLASZ        SIEGE",
  },
  {
    id: "0000107700",
    libelle: "TEOS SAS",
  },
  {
    id: "0000107742",
    libelle: "TERIDEAL",
  },
  {
    id: "0000108134",
    libelle: "TERRASSEMENT JUNG",
  },
  {
    id: "0000102395",
    libelle: "TERTIA SOLUTIONS",
  },
  {
    id: "0000108894",
    libelle: "TES TECHNIFOR",
  },
  {
    id: "0000100614",
    libelle: "THIERRY MULLER",
  },
  {
    id: "0000100517",
    libelle: "THIRION SARL TRAVAUX PUBLICS",
  },
  {
    id: "0000108251",
    libelle: "THP",
  },
  {
    id: "0000100667",
    libelle: "TISCO",
  },
  {
    id: "0000103555",
    libelle: "TIXIT SA",
  },
  {
    id: "0000108733",
    libelle: "TNG CANALISATION",
  },
  {
    id: "0000106797",
    libelle: "TP ET TRANSPORT SCHMITT",
  },
  {
    id: "0000107624",
    libelle: "TP KLEIN SARL UTILISER LE 107925",
  },
  {
    id: "0000107685",
    libelle: "TPHM",
  },
  {
    id: "0000108178",
    libelle: "TP2A ANDLAUER TPA",
  },
  {
    id: "0000100522",
    libelle: "TRABET",
  },
  {
    id: "0000100525",
    libelle: "TRANSROUTE ENROBES EIFFAGE ROUTE NORD EST",
  },
  {
    id: "0000108579",
    libelle: "TRICOLOR",
  },
  {
    id: "0000106910",
    libelle: "TWINTEC",
  },
  {
    id: "0000103545",
    libelle: "UFT FRANCE",
  },
  {
    id: "0000107494",
    libelle: "UME",
  },
  {
    id: "0000106135",
    libelle: "URBAN DUMEZ",
  },
  {
    id: "0000108171",
    libelle: "V MAT CONSTRUCTION",
  },
  {
    id: "0000107515",
    libelle: "VA BTP    Siège",
  },
  {
    id: "0000101211",
    libelle: "VAUTHRIN FORAGES",
  },
  {
    id: "0000106368",
    libelle: "VEGETOIT",
  },
  {
    id: "0000104761",
    libelle: "VEITH PEINTURES DECORS SARL",
  },
  {
    id: "0000106996",
    libelle: "VEOLIA WATER TECHNOLOGIE SIEGE anciennement OPALIUM",
  },
  {
    id: "0000106580",
    libelle: "VIDEO INJECTION INSITUFORM",
  },
  {
    id: "0000107941",
    libelle: "VINCI CONSTRUCTION TERRASSEMENT",
  },
  {
    id: "0000108127",
    libelle: "VINIRE GEOTECHNIQUE Siège",
  },
  {
    id: "0000107755",
    libelle: "VINSEN",
  },
  {
    id: "0000108299",
    libelle: "VISCONTI CHARLES",
  },
  {
    id: "0000100675",
    libelle: "VOGEL TP",
  },
  {
    id: "0000108462",
    libelle: "VOILLAUME DESAMIANTAGE SARL",
  },
  {
    id: "0000106521",
    libelle: "VONTHRON",
  },
  {
    id: "0000106506",
    libelle: "VRBI",
  },
  {
    id: "0000105741",
    libelle: "WAHL ISOLATION",
  },
  {
    id: "0000105843",
    libelle: "WALTER ET SCHIMPF ARCHITECTES",
  },
  {
    id: "0000105742",
    libelle: "WEBER METALLERIE",
  },
  {
    id: "0000100548",
    libelle: "WEHR SGGS NORD EST",
  },
  {
    id: "0000105230",
    libelle: "WEINSTEIN FERMETURES",
  },
  {
    id: "0000106369",
    libelle: "WEISS APPETITO",
  },
  {
    id: "0000100553",
    libelle: "WENDLING TP SAS",
  },
  {
    id: "0000600565",
    libelle: "WENDLING TRANSPORTS",
  },
  {
    id: "0000100556",
    libelle: "WICKER TP",
  },
  {
    id: "0000105862",
    libelle: "WIEDEMANN ET FILS SARL",
  },
  {
    id: "0000503885",
    libelle: "WILLEM METALLERIE",
  },
  {
    id: "0000105925",
    libelle: "WILLEM ROUTES ET TRAVAUX PUBLICS",
  },
  {
    id: "0000106165",
    libelle: "WINLING CHARPENTES SARL",
  },
  {
    id: "0000100382",
    libelle: "WOLFF RENE ET FILS ESPACES VERTS",
  },
  {
    id: "0000107695",
    libelle: "WP",
  },
  {
    id: "0000107280",
    libelle: "WTG ENVIRONNEMENT",
  },
  {
    id: "0000104840",
    libelle: "WUNSCHEL GRILLAGES",
  },
  {
    id: "0000106439",
    libelle: "X SCHREIBER ET CIE SA",
  },
  {
    id: "0000108185",
    libelle: "ZAEGEL LOIC",
  },
  {
    id: "0000107862",
    libelle: "2R APPLICATIONS SAS",
  },
  {
    id: "0000108021",
    libelle: "2S CONSTRUCTIONS",
  },
  {
    id: "0000106100",
    libelle: "3D        siège",
  },
  {
    id: "0000600625",
    libelle: "6 TEM",
  },
  {
    id: "0000106355",
    libelle: "67 ANTENNES",
  },
];
