import { useCallback, useEffect, useState } from "react";
import type { ReactNode } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import useChantiersSearch from "../hooks/useChantiersSearch";
import { AllStatutDossier } from "models";
import type { TypeDossier, ChantiersSearchFiltre, StatutDossier } from "models";
import { CommuneAutoCompleteWithErrorBoundary } from "components/Inputs";
import TuneIcon from "@mui/icons-material/Tune";
import { useMenu } from "hooks";
import { AccordionFormSection } from "components/Layout";
import { grey } from "@mui/material/colors";
import TechnicienAutoCompleteWithErrorBoundary from "pages/Chantier/components/TechnicienAutoCompleteWithErrorBoundary";
import StatutChip from "./StatutChip";
import { dateUtil } from "@sdeapps/react-core";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloseIcon from "@mui/icons-material/Close";
import TypeDossierSelect from "./TypeDossierSelect";

const dateFormat = "yyyy-MM-dd";

function RechercheFiltres(): ReactNode {
  const { setFiltres, defaultFiltres } = useChantiersSearch();
  const [filtresLocaux, setFiltresLocaux] = useState<ChantiersSearchFiltre>(defaultFiltres);
  const setFiltresWithDebounce = useDebouncedCallback((newFiltres: ChantiersSearchFiltre) => {
    setFiltres(newFiltres);
  }, 500);
  const { isOpen, handleClose, handleOpen } = useMenu();
  const resetFiltresLocaux = useCallback(() => {
    setFiltresLocaux(defaultFiltres);
  }, [defaultFiltres]);
  useEffect(() => {
    setFiltresWithDebounce(filtresLocaux);
  }, [filtresLocaux, setFiltresWithDebounce]);
  const updateFiltres = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { value, name } = e.target as HTMLInputElement;
      setFiltresLocaux({
        ...filtresLocaux,
        [name]: value,
      });
    },
    [filtresLocaux]
  );

  return (
    <Grid container spacing={2} sx={{ mb: 2 }} size={12}>
      <Typography component={Grid} size={12} color="primary" variant="body1" fontWeight={600}>
        Filtres
      </Typography>
      <Grid size={{ xs: 12, md: 6, lg: 2 }}>
        <TextField
          fullWidth
          size="small"
          name="client"
          label="Client"
          onInput={updateFiltres}
          value={filtresLocaux.client}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 2 }}>
        <TextField
          fullWidth
          size="small"
          name="adresse"
          label="Adresse chantier"
          onInput={updateFiltres}
          value={filtresLocaux.adresse}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 2 }}>
        <CommuneAutoCompleteWithErrorBoundary
          size="small"
          communeId={filtresLocaux.communeId}
          onChange={(commune) => {
            setFiltresLocaux({
              ...filtresLocaux,
              communeId: commune?.id,
            });
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 2 }}>
        <TextField
          fullWidth
          size="small"
          name="reference"
          label="Numéro de dossier"
          onInput={updateFiltres}
          value={filtresLocaux.reference}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 2 }}>
        <TypeDossierSelect
          fullWidth
          size="small"
          label="Type de demande"
          name="type"
          value={filtresLocaux.type ?? ""}
          onChange={(e) => {
            setFiltresLocaux({
              ...filtresLocaux,
              type: e.target.value as TypeDossier,
            });
          }}
        />
      </Grid>
      <Grid container spacing={1} size={{ xs: 12, md: 6, lg: 2 }}>
        <Grid container justifyContent="center" size={{ xs: 12, sm: 6, md: 12 }}>
          <Button variant="outlined" onClick={handleOpen} startIcon={<TuneIcon />} size="small">
            Filtres avancés
          </Button>
        </Grid>
        <Grid container justifyContent="center" size={{ xs: 12, sm: 6, md: 12 }}>
          <Button onClick={resetFiltresLocaux} color="error" size="small">
            Rétablir les filtres par défaut
          </Button>
        </Grid>
      </Grid>
      <Drawer
        open={isOpen}
        onClose={handleClose}
        keepMounted
        anchor="right"
        sx={{ ".MuiDrawer-paper ": { background: grey[100] } }}>
        <Box sx={{ maxWidth: 500, p: 2 }}>
          <Grid container spacing={2} size={12}>
            <Grid container spacing={0} alignItems="center" size={12}>
              <Grid size="grow">
                <Stack direction="row" spacing={1}>
                  <TuneIcon color="primary" />
                  <Typography variant="h3">Filtres avancés</Typography>
                </Stack>
              </Grid>
              <Grid size="auto">
                <IconButton onClick={handleClose}>
                  <CloseIcon color="error" />
                </IconButton>
              </Grid>
            </Grid>
            <AccordionFormSection title="Date de demande" size={12} container defaultExpanded>
              <Grid size={{ xs: 12, sm: 6 }}>
                <DatePicker
                  sx={{ width: "100%" }}
                  format="dd/MM/yyyy"
                  label="Du"
                  name="dateDemandeDuIncluse"
                  value={
                    filtresLocaux?.dateDemandeDuIncluse != null
                      ? dateUtil.fromFormatToDate(filtresLocaux.dateDemandeDuIncluse, dateFormat)
                      : null
                  }
                  onChange={(date: Date | null | undefined) => {
                    setFiltresLocaux({
                      ...filtresLocaux,
                      dateDemandeDuIncluse:
                        date != null && dateUtil.isValid(date)
                          ? dateUtil.format(date, dateFormat)
                          : undefined,
                    });
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <DatePicker
                  sx={{ width: "100%" }}
                  format="dd/MM/yyyy"
                  label="Au"
                  name="dateDemandeAuIncluse"
                  value={
                    filtresLocaux?.dateDemandeAuIncluse != null
                      ? dateUtil.fromFormatToDate(filtresLocaux.dateDemandeAuIncluse, dateFormat)
                      : null
                  }
                  onChange={(date: Date | null | undefined) => {
                    setFiltresLocaux({
                      ...filtresLocaux,
                      dateDemandeAuIncluse:
                        date != null && dateUtil.isValid(date)
                          ? dateUtil.format(date, dateFormat)
                          : undefined,
                    });
                  }}
                />
              </Grid>
            </AccordionFormSection>
            <AccordionFormSection title="Technicien" size={12} container defaultExpanded>
              <Grid size={12}>
                <TechnicienAutoCompleteWithErrorBoundary
                  technicienId={filtresLocaux.technicienId}
                  onChange={(t) => {
                    setFiltresLocaux({
                      ...filtresLocaux,
                      technicienId: t?.id,
                    });
                  }}
                />
              </Grid>
            </AccordionFormSection>
            <AccordionFormSection title="Statut du dossier" size={12} container defaultExpanded>
              <Grid container spacing={0} size={12}>
                {Object.values(AllStatutDossier).map((s) => (
                  <Grid key={s} size={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filtresLocaux.statuts?.find((f) => f === s) != null}
                          onClick={() => {
                            if (filtresLocaux.statuts?.find((f) => f === s) != null) {
                              setFiltresLocaux({
                                ...filtresLocaux,
                                statuts: filtresLocaux.statuts.filter((statut) => statut !== s),
                              });
                            } else {
                              setFiltresLocaux({
                                ...filtresLocaux,
                                statuts: [...(filtresLocaux.statuts ?? []), s],
                              });
                            }
                          }}
                        />
                      }
                      label={<StatutChip statut={s as unknown as StatutDossier} />}
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionFormSection>
            <Grid container size={12}>
              <Grid>
                <Button
                  onClick={resetFiltresLocaux}
                  sx={{ background: "white" }}
                  color="error"
                  variant="outlined">
                  Rétablir les filtres par défaut
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </Grid>
  );
}
export default RechercheFiltres;
