import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { ReactNode, PropsWithChildren } from "react";
import BackButton from "components/BackButton";

interface PageLayoutProps extends PropsWithChildren {
  pageTitle: string;
  pageIcon?: ReactNode;
}
export function PageLayout({
  pageIcon,
  pageTitle,
  children,
}: Readonly<PageLayoutProps>): ReactNode {
  return (
    <Grid container size={12}>
      <Grid container alignItems="center" columnSpacing={1} sx={{ py: 1 }} size={12}>
        <Grid size="auto">
          <BackButton fontSize="large" />
        </Grid>
        {pageIcon != null && <Grid size="auto">{pageIcon}</Grid>}
        <Grid size="grow">
          <Typography variant="h1">{pageTitle}</Typography>
        </Grid>
      </Grid>
      <Grid container size={12}>
        {children}
      </Grid>
    </Grid>
  );
}
