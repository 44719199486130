import type { ReactNode } from "react";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import WarningIcon from "@mui/icons-material/Warning";
import { useErrorBoundary } from "react-error-boundary";

interface InformationsErrorProps {
  title?: string;
  caption?: string;
}

const DEFAULT_TITLE = "Une erreur est survenue.";
const DEFAULT_CAPTION =
  "Vous pouvez continuer à utiliser l'application, mais certaines fonctionnalités peuvent être temporairement dégradées.";

/**
 * Composant d'erreur générique à utiliser en fallback lorsqu'un composant est en erreur.
 *
 * Utilisé par défaut par `withComponentErrorBoundary()`. voir la [Documentation de notre lib de Gestion des Erreurs](https://dev.azure.com/SDEA/SDEApps/_wiki/wikis/Ing%C3%A9nieur-DevOps/1891/Gestion-des-erreurs-en-React#).
 *
 * @param title ?:string - Titre de l'erreur, par défaut "Une erreur est survenue."
 * @param caption ?:string - Texte de l'erreur, par défaut "Vous pouvez continuer à utiliser l'application, mais certaines fonctionnalités peuvent être temporairement dégradées."
 */
function ErrorComponent({
  title = DEFAULT_TITLE,
  caption = DEFAULT_CAPTION,
}: Readonly<InformationsErrorProps>): ReactNode {
  const { resetBoundary } = useErrorBoundary();

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{ paddingLeft: 2 }}
      size={12}>
      <Grid size="auto">
        <WarningIcon color="warning" />
      </Grid>
      <Grid container size="grow">
        <Grid container alignItems="center" spacing={2} size={12}>
          <Grid>
            <Typography fontWeight="500">{title}</Typography>
          </Grid>
          <Grid>
            <Button variant="text" size="small" onClick={resetBoundary}>
              Réessayer
            </Button>
          </Grid>
        </Grid>
        <Grid size={12}>
          <Typography variant="caption" fontStyle="italic">
            {caption}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ErrorComponent;
