import type { ReactNode } from "react";
import { withPageErrorBoundary } from "utils/errorHandling";
import { PageLayout } from "components/Layout";
import RechercheFiltres from "./components/RechercheFiltres";
import { ChantiersSearchProvider } from "./providers/ChantiersSearchProvider";
import ChantiersTable from "./components/ChantiersTable";

function RecherchePage(): ReactNode {
  return (
    <PageLayout pageTitle="Recherche">
      <ChantiersSearchProvider>
        <RechercheFiltres />
        <ChantiersTable />
      </ChantiersSearchProvider>
    </PageLayout>
  );
}

export const RecherchePageWithErrorBoundary = withPageErrorBoundary(RecherchePage);
