import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import type { Adresse } from "models";

interface AdressePrincipaleProps {
  adresse: Adresse;
}

function AdressePrincipale({ adresse }: Readonly<AdressePrincipaleProps>): ReactNode {
  return (
    <>
      <Typography>{adresse?.adresseVoie}</Typography>
      <Typography>
        {adresse?.codePostal} {adresse.libelleCommune}
      </Typography>
    </>
  );
}

export default AdressePrincipale;
