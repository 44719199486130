import type { AdresseCreateDTO, Adresse } from "models";
import { Endpoints } from "enums";
import { opaleApiClient } from "./apiClients";
import type { Operation } from "fast-json-patch";

/**
 * Crée une adresse d'une nouvelle demande
 * @returns L'identifiant de l'adresse créée
 */
async function postAdresse(data: AdresseCreateDTO): Promise<string> {
  const url = `/${Endpoints.ADRESSES}`;
  return opaleApiClient.post<string, string>(url, data);
}

/**
 * Retourne une liste d'adresses
 * @returns Tableau d'adresses
 */
async function getAdresse(id: string): Promise<Adresse> {
  const url = `/${Endpoints.ADRESSES}/${id}`;
  return opaleApiClient.get<Adresse, Adresse>(url);
}

/**
 * Retourne une liste d'adresses
 * @returns Tableau d'adresses
 */
async function getAllAdresses(
  libelleCommune?: string,
  codeInsee?: string
): Promise<Array<Adresse>> {
  const params = new URLSearchParams();
  if (libelleCommune != null && libelleCommune !== "") {
    params.append("libelleCommune", libelleCommune);
  }
  if (codeInsee != null && codeInsee !== "") {
    params.append("codeInsee", codeInsee);
  }
  const queryString = params.size > 0 ? `?${params.toString()}` : "";
  const url = `/${Endpoints.ADRESSES}${queryString}`;
  return opaleApiClient.get<Array<Adresse>, Array<Adresse>>(url);
}

/**
 * Modifie les données d'une adresse.
 * @param adresseId L'identifiant de l'adresse
 * @param patchData la liste des propriétés à modifier
 */
async function patch(adresseId: string, patchData: Array<Operation>): Promise<Adresse | undefined> {
  const url = `/${Endpoints.ADRESSES}/${adresseId}`;
  return opaleApiClient.patch<Adresse, Adresse, Array<Operation>>(url, patchData);
}

export const adressesService = {
  postAdresse,
  getAdresse,
  getAllAdresses,
  patch,
};
