import "App.css";
import type { ReactNode } from "react";
import { appiConfig, authenticationConfig, config } from "config/app-config";
import { AuthenticationProvider, AppInsightsProvider } from "@sdeapps/react-core";
import { authenticatedRouter, unAuthenticatedRouter } from "routes";
import { RouterProvider } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import { SnackbarProvider } from "notistack";
import { SDEAppsThemeProvider } from "providers";
import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { BaseErrorPage } from "utils/errorHandling";

function App(): ReactNode {
  return (
    <div className="App">
      <SDEAppsThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
          <AppInsightsProvider
            onError={BaseErrorPage}
            instrumentationKey={appiConfig.instrumentationKey}
            appVersion={config.appVersion}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              preventDuplicate>
              <AuthenticationProvider
                unauthorizedPage={<RouterProvider router={unAuthenticatedRouter} />}
                config={authenticationConfig}>
                <RouterProvider router={authenticatedRouter} />
              </AuthenticationProvider>
            </SnackbarProvider>
          </AppInsightsProvider>
        </LocalizationProvider>
        <Typography variant="body2" sx={{ color: grey[500], textAlign: "center" }}>
          Version de build: {config.appVersion ?? "local"}
        </Typography>
      </SDEAppsThemeProvider>
    </div>
  );
}

export default App;
