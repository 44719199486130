import { useMemo } from "react";
import type { ReactNode } from "react";
import { Drawer, useTheme } from "@mui/material";
import type { DrawerProps } from "@mui/material";

const drawerWidth = 240;

interface NavigationDrawerProps extends DrawerProps {
  open?: boolean;
}

function NavigationDrawer({
  open = false,
  ...drawerProps
}: Readonly<NavigationDrawerProps>): ReactNode {
  const theme = useTheme();

  const openStyle = useMemo(() => {
    return {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    };
  }, [theme]);

  const closeStyle = useMemo(() => {
    return {
      width: `calc(${theme.spacing(7)} + 1px)`,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
      },
    };
  }, [theme]);

  return (
    <Drawer
      variant="permanent"
      className={open ? "open" : undefined}
      {...drawerProps}
      sx={{
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open ? openStyle : closeStyle),
        "& .MuiDrawer-paper": {
          overflowX: "hidden",
          ...(open ? openStyle : closeStyle),
        },
      }}
    />
  );
}

export default NavigationDrawer;
