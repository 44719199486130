export type CourrierType =
  | "AcceptationTravauxEau"
  | "AchevementRegardExistantPoseCompteur"
  | "AutorisationRaccordement"
  | "BranchementEau"
  | "BranchementEauAvecAssUnitaire"
  | "ManquePiecesDossierEau";

export type CourrierMetadata = {
  /**
   * Type de courrier qui correspond aux valeurs de l'enum de l'API Opale.
   * Seuls les courriers implémentés sont déclarés.
   */
  type: CourrierType;
  libelle: string;
  template: string;
};

export const CourriersDossierEauDefinitions: Array<CourrierMetadata> = [
  {
    type: "AcceptationTravauxEau",
    libelle: "Acceptation de travaux",
    template: "EXE_R011_Formulaire_AcceptationTravauxEAU.docx",
  },
  {
    type: "BranchementEau",
    libelle: "Branchement EAU",
    template: "Branchement EAU seul.docx",
  },
  {
    type: "BranchementEauAvecAssUnitaire",
    libelle: "Branchement EAU avec ASS unitaire",
    template: "Branchement EAU avec EU unitaire.docx",
  },
  {
    type: "AchevementRegardExistantPoseCompteur",
    libelle: "Achèvement regard existant (pose compteur)",
    template: "ACHEVEMENT DANS REGARD EXISTANT.docx",
  },
  {
    type: "ManquePiecesDossierEau",
    libelle: "Manque pièces dossier EAU",
    template: "MANQUE PIECES EAU.docx",
  },
];

export const CourriersDossierCipaDefinitions: Array<CourrierMetadata> = [
  {
    type: "AutorisationRaccordement",
    libelle: "Autorisation de raccordement",
    template: "auto racc.docx",
  },
];
