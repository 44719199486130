import { DataGrid } from "@mui/x-data-grid";
import type { GridValidRowModel, GridColDef } from "@mui/x-data-grid";
import { ToastMessages } from "enums";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { useErrorHandler } from "utils/errorHandling/useErrorHandler";

interface GenericDataGridProps<T extends GridValidRowModel> {
  /** Définition des colonnes pour le composant `<DataGrid />` utilisé en _interne_. */
  columns: ReadonlyArray<GridColDef<T>>;
  /** Fonction retournant le jeu de données à afficher dans la page. */
  fetchFunction: () => Promise<Array<T>>;
}

/**
 * Composant générique _temporaire_ permettant d'afficher une page avec une liste d'objets
 * retournés par un appel à l'API.
 */
function GenericDataGrid<T extends GridValidRowModel>({
  columns,
  fetchFunction,
}: Readonly<GenericDataGridProps<T>>): ReactNode {
  const { catchErrors, isLoading } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: true,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR,
      });
    },
  });
  const [rows, setRows] = useState<Array<T>>([]);
  useEffect(() => {
    async function fetchData(): Promise<void> {
      const data = await fetchFunction();
      setRows(data);
    }

    void catchErrors(fetchData);
  }, [catchErrors, fetchFunction]);

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      loading={isLoading}
      initialState={{
        pagination: {
          paginationModel: {
            page: 0,
            pageSize: 50,
          },
        },
      }}
      disableRowSelectionOnClick
      autoHeight
    />
  );
}

export default GenericDataGrid;
